import React from 'react';
import Chart from 'react-apexcharts';

const AreaChart = ({ colors, series, options, ...passThroughProps }) => {
  // Hack to work around the shade property not working
  // Leaving this here for now until I know if the transparent chart actually needs to be there
  const colorReplaced = colors.map((color) => (color === 'transparent' ? '#FFFFFF' : color));

  const areaProps = {
    chart: {
      height: 100,
      width: 100,
      type: 'area',
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: 'straight',
      colors,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      colors: colorReplaced,
      type: 'gradient',
      gradient: {
        type: 'vertical',
        gradientToColors: colorReplaced,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 100],
      },
    },
    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        format: 'dd/MM/yy',
      },
      marker: {
        show: false,
      },
    },
    legend: {
      markers: {
        fillColors: colors,
      },
    },
    markers: {
      colors,
    },
    xaxis: {
      type: 'date',
      categories: [
        'FEB',
        'MAR',
        'APR',
        'MAY',
        'JUN',
        'JUL',
        'AUG',
        'SEPT',
        'OCT',
        'NOV',
        'DEC',
        'JAN',
        'FEB',
      ],
    },
    ...options,
  };

  return <Chart options={areaProps} series={series} type="area" {...passThroughProps} />;
};

export default AreaChart;
