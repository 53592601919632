import { action } from 'easy-peasy';

const preferences = {
  theme: 'light',

  // actions
  toggle: action((state) => {
    state.theme = state.theme === 'light' ? 'dark' : 'light';
  }),
};

export default preferences;
