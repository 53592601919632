import isEmpty from 'lodash/isEmpty';

import { ResizeProvider } from '@webfx/web-context';
import Spinner from '../Spinner/Spinner';
import EventList from './EventList';

import './Timeline.css';

const Timeline = ({ className, data = null, height = 200 }) => {
  return (
    <div className={`timeline ${className}`}>
      <ResizeProvider>
        {!data ? <Spinner animation="border" size="sm" className="rv-spinner" /> : null}
        {!isEmpty(data?.events) ? (
          <EventList events={data.events} height={height} sid={data.sid} />
        ) : null}
      </ResizeProvider>
    </div>
  );
};

export default Timeline;
