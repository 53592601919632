import React, { useRef, useEffect, useState } from 'react';
import { Button } from '@webfx/core-web';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Overlay, Tooltip } from 'react-bootstrap';

import styles from './ScrollToTopButton.module.css';

const ScrollToTopButton = () => {
  const scrollButtonRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [eventTarget, setEventTarget] = useState(null);

  const handler = () => {
    if (eventTarget) {
      eventTarget.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    document.body.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    document.documentElement.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (scrollButtonRef?.current) {
      window.addEventListener(
        'scroll',
        (event) => {
          setEventTarget(event.target);

          if (scrollButtonRef?.current?.classList) {
            if (event.target.scrollTop > 20) {
              scrollButtonRef.current.classList.add(styles.showButton);
              scrollButtonRef.current.classList.remove(styles.hideButton);
            } else {
              scrollButtonRef.current.classList.add(styles.hideButton);
              scrollButtonRef.current.classList.remove(styles.showButton);
            }
          }
        },
        true
      );
    }

    return null;
  }, [scrollButtonRef?.current]);

  return (
    <>
      <Button
        ref={scrollButtonRef}
        size={'md'}
        variant={'primary'}
        text={''}
        uppercase
        icon={'keyboard_double_arrow_up'}
        outline={false}
        iconTrailing={false}
        iconPosition={'left'}
        iconOutlined={false}
        centerContent={true}
        onClick={handler}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        className={classnames(styles.button, styles.hideButton)}
      />
      <Overlay target={scrollButtonRef.current} show={showTooltip} placement="top">
        {(props) => (
          <Tooltip id="scroll-to-top-tooltip" {...props}>
            Scroll to top
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default ScrollToTopButton;
