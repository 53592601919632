import { thunk, action, computed, actionOn } from 'easy-peasy';

// We need to hook these vaules to the filters
const pageable = (idKey = 'id', total = 0, skip = 0, limit = 20) => ({
  total,
  skip,
  limit,
  current: {
    total: 0,
    skip: 0,
    limit: 0,
  },

  idsByPage: {},

  setLimit: action((state, payload) => {
    state.limit = payload;
  }),

  setSkip: action((state, payload) => {
    state.skip = payload;
  }),

  setCurrentPaginationOnFetched: actionOn(
    (actions) => actions.fetched,
    (state, target) => {
      const { total, skip, limit, data } = target.payload;
      const page = skip === 0 ? 1 : skip / limit + 1;
      state.current = { total, skip, limit };
      state.idsByPage[page] = data.map((r) => r[idKey]);
    }
  ),

  resetPaginationOnReset: actionOn(
    (actions) => actions.reset,
    (state, target) => {
      state.idsByPage = {};
      state.current = {
        total: 0,
        skip: 0,
        limit: 0,
      };
      state.total = total;
      state.skip = skip;
      state.limit = limit;
    }
  ),
});

export default pageable;
