import { action, thunk, computed } from 'easy-peasy';

import { countries as CountryService } from '../services';

const countries = {
  countries: [],
  countriesOptions: computed((state) =>
    state.countries.map(({ name }) => ({
      value: name,
      label: name,
    }))
  ),

  // actions
  setCountries: action((state, payload) => {
    state.countries = payload;
  }),

  // thunks
  getCountries: thunk(async (actions, payload, { getStoreActions }) => {
    const { common } = getStoreActions();
    try {
      common.showLoader({ key: 'getCountries' });
      const res = await CountryService.find();
      actions.setCountries(res);
    } catch (error) {
      common.showError(error);
    }
    common.hideLoader({ key: 'getCountries' });
  }),
};

export default countries;
