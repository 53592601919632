import React from 'react';
import get from 'lodash/get';

export function dataAsOptions(data, resolverProps, includeOrigData = false) {
  const dataAsOptions = (() => {
    if (!data?.data) {
      return { dataAsOptions: [] };
    }

    return data.data
      .filter((i) => !!get(i, resolverProps.value, null) && !!get(i, resolverProps.label, null))
      .map((i) => ({
        value: get(i, resolverProps.value, null),
        label: get(i, resolverProps.label, null),
        ...(includeOrigData ? { data: i } : {}),
      }));
  })();

  return { dataAsOptions };
}
