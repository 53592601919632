import React from 'react';
import styles from './SelectInputGroup.module.css';

// need for remove divider in SelectInput
// eslint-disable-next-line react/jsx-filename-extension
export const IndicatorSeparator = () => <span className={styles.indicatorSeparatorStyle} />;

// match the size of Inputs
export const changeTheme = (theme) => ({
  ...theme,
  borderRadius: 2,
  spacing: { ...theme.spacing, controlHeight: 60, baseUnit: 8 },
});

// override default border styles.
// Need override in two places because this is how this package works
export const customStyles = {
  control: (base, { isFocused }) => ({
    ...base,
    minHeight: '48px',
    height: '48px',
    borderColor: isFocused ? '#4c74b9' : '#a9b4bc',
    boxShadow: 'none',
    '&:hover': {
      borderColor: isFocused ? '#4c74b9' : '#a9b4bc',
    },
  }),
  menu: (base) => ({
    ...base,
    zIndex: 10,
  }),
};
