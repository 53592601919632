import isPlainObject from 'lodash/isPlainObject';
import { extractTypeFromKey } from '../utils';

export function invalidateKey(key, queryClient) {
  const [typeExtractedKey] = extractTypeFromKey(key);
  const paramsAbstractedKey = typeExtractedKey.filter((i) => !isPlainObject(i));

  return () => {
    queryClient.invalidateQueries(paramsAbstractedKey);
  };
}
