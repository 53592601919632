import classnames from 'classnames';
import { useField } from 'formik';

import Textarea from '../Textarea/Textarea';
import InputLabel from '../Input/InputLabel/InputLabel';
import InputLabelInfo from '../Input/InputLabelInfo/InputLabelInfo';
import InputErrorText from '../Input/InputErrorText/InputErrorText';

const TextareaGroup = ({
  field,
  field: { name },
  label,
  labelInfo,
  placeholder,
  disabled,
  readOnly,
  className,
  labelClassName,
  inputClassName,
  bottomGutter,
  rows,
  form: { errors, touched },
}) => {
  const error = errors[name];
  const isTouched = touched[name];

  return (
    <div className={classnames({ bottomGutter }, className)}>
      <div className={'inputGroupLabel'}>
        {label && (
          <div className={classnames({ sideMargin: labelInfo })}>
            <InputLabel
              text={label}
              htmlFor={name}
              className={classnames(['form-label', labelClassName])}
            />
          </div>
        )}
        {labelInfo && (
          <div>
            <InputLabelInfo text={labelInfo} />
          </div>
        )}
      </div>
      <Textarea
        field={field}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        className={inputClassName}
        error={!!error && isTouched}
        rows={rows}
      />
      {error && isTouched && <InputErrorText text={error} />}
    </div>
  );
};

export default TextareaGroup;

export const FormikTextarea = ({ name, field: formikField, onChange, ...passThroughProps }) => {
  const fieldName = name || formikField.name;
  const [field, meta] = useField(fieldName);

  return (
    <TextareaGroup
      field={field}
      {...meta}
      {...passThroughProps}
      onChange={() => {
        if (field.onChange) {
          field.onChange(event);
        }
        if (onChange) {
          onChange(event);
        }
      }}
    />
  );
};
