import jwtDecode from 'jwt-decode';

import { actions } from '@webfx/web-state';

import * as localStorage from '../utils/localStorage';
import feathers from './feathersClient';

export const authenticate = async (
  strategy = 'jwt',
  payload,
  authOptions = { storeUser: true }
) => {
  let options;

  if (strategy === 'local') {
    const { email, password } = payload;

    options = {
      strategy: 'local',
      email,
      password,
    };
  } else if (strategy === 'apikey') {
    options = {
      strategy,
      token: payload.token,
    };
  } else {
    options = {
      strategy: 'jwt',
      accessToken: getAccessToken(),
    };
  }

  try {
    const res = await feathers().authenticate(options);
    const user = res && res.user;

    // Wen using apikey strategy we don't want to set the user implicitly. (This may change)
    if (user && authOptions.storeUser) {
      // set user object info to localStorage and easy-peasy
      setUser(user);
      actions.auth.user(user);
    }

    return res;
  } catch (err) {
    console.warn(err);
    throw err;
  }
};

export const logout = () => {
  feathers().logout();
  // remove user property from localStorage
  deleteUser();
  deleteTokens();
};

export const getAccessToken = () => localStorage.getItem('accessToken');

export const setAccessToken = (token) => {
  try {
    localStorage.setItem('accessToken', token);
  } catch (error) {
    if (error.message.indexOf('quota has been exceeded') !== -1) {
      console.error(error);
    }
  }
};

export const setCookieAccess = () => {
  getAccessToken();
};

export const deleteTokens = () => {
  localStorage.removeItem('accessToken');
};

export const extractTokenInfo = () => {
  const token = getAccessToken();
  if (!token) {
    return null;
  }
  return jwtDecode(token);
};
export const setUser = (user) => {
  try {
    localStorage.setItem('user', JSON.stringify(user));
  } catch (error) {
    if (error.message.indexOf('quota has been exceeded') === -1) {
      console.error(error);
    }
  }
};

export const getUser = () => {
  JSON.parse(localStorage.getItem('user'));
};

export const deleteUser = () => localStorage.removeItem('user');

export const isAccessTokenAlive = () => {
  const token = getAccessToken();
  if (!token) {
    return false;
  }

  const currentTime = Math.floor(new Date().getTime() / 1000);
  return currentTime < extractTokenInfo(token).exp;
};

// Time in sec.
export const accessTokenRemainingLifeTimeSec = () => {
  const token = getAccessToken();
  if (!token) {
    return -1;
  }
  const currentTime = Math.floor(new Date().getTime() / 1000);
  return extractTokenInfo().exp - currentTime;
};

export const createPasswordReset = (payload) =>
  feathers().service('reset-password').create(payload);
