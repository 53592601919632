import { action, computed, thunkOn } from 'easy-peasy';

import { setClient } from '../services/feathersClient';

const config = {
  values: {
    api: '',
    apiUpload: '/uploads',
  },
  // generate a url to use within views by name
  config: computed((state) => (key) => state.values[key]),

  setConfig: action((state, payload) => {
    Object.keys(payload).forEach((k) => {
      state.values[k] = payload[k];
    });
  }),

  onApiChange: thunkOn(
    (actions) => actions.setConfig,
    (state, target) => {
      if (target.payload.api) {
        setClient(target.payload.api);
      }
    }
  ),
};

export default config;
