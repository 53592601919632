// This function is used for catching and logging errors
const request = async (action) => {
  try {
    return await action();
  } catch (err) {
    console.warn(err);
    throw err;
  }
};

export default request;
