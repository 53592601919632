import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import { cropText } from '../../../utils';
import styles from './CardSubTitle.module.css';

const CardSubTitle = ({ text, className }) => {
  const { isMultiplyLines, firstPart } = cropText(text.trim(), 35);

  if (!isMultiplyLines) {
    return (
      <p className={classNames(styles.subTitle, className)} data-fx-name={'jobTitle'}>
        {text}
      </p>
    );
  }

  return (
    <OverlayTrigger placement="top" overlay={<Tooltip>{text}</Tooltip>}>
      <p className={classNames(styles.subTitle, className)}>{firstPart}...</p>
    </OverlayTrigger>
  );
};

export default CardSubTitle;
