import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';

import Icon from '../Icon/Icon';

import styles from './Rating.module.css';

const Rating = ({
  maxRating = 10,
  readOnly = false,
  rating = 0,
  stepSize = 0.5,
  baseColor = '#ffc107',
  mainColor = '#ffc107',
  mainColorHover = '#ffc107',
  icon = 'star',
  iconOutline = 'star_border',
  style = {},
  iconStyle = {},
  className,
  onSelect,
}) => {
  const [value, setValue] = useState(null);
  const [visibleWidth, setVisibleWidth] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const [raiseSelectLayer, setRaiseSelectLayer] = useState(null);

  const divRef = useRef(null);

  useEffect(() => {
    setValue(rating);
  }, [rating]);

  const getPosition = function (el) {
    const rect = el.getBoundingClientRect();
    const position = {
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX,
    };
    return position;
  };

  const onClick = function (ev) {
    if (!readOnly) {
      const x = ev.pageX - getPosition(ev.currentTarget).left;
      const selectedWidth = toWidth(toValue(x, true));
      updateValue(toValue(selectedWidth));
      setRaiseSelectLayer(true);
    }
  };

  const onMouseMove = function (ev) {
    if (!readOnly && typeof onSelect !== 'undefined') {
      setIsHovering(true);
      const x = ev.pageX - getPosition(ev.currentTarget).left;
      const val = toValue(x, true);

      if (val != value) {
        setRaiseSelectLayer(false);
      }

      if (!raiseSelectLayer) {
        setVisibleWidth(toWidth(val));
      }
    }
  };

  const onMouseLeave = function () {
    setIsHovering(false);
    setVisibleWidth(0);
  };

  const toWidth = function (val) {
    return (val / maxRating) * 100;
  };

  const updateValue = function (value) {
    if (!readOnly && typeof onSelect !== 'undefined') {
      if (value < 0) {
        value = 0;
      } else if (value > maxRating) {
        value = maxRating;
      }

      setVisibleWidth(toWidth(value));
      setValue(value);
      onSelect(value);
    }
  };

  const toValue = function (width, inPixels) {
    let val;
    if (inPixels) {
      val = (width / divRef.current.offsetWidth) * maxRating;
    } else {
      val = (width / 100) * maxRating;
    }

    // Make sure the division doesn't cause some small numbers added by
    // comparing to a small arbitrary number.
    const temp = val / stepSize;
    if (temp - Math.floor(temp) < 0.00005) {
      val = Math.round(val / stepSize) * stepSize;
    }
    val = Math.ceil(val / stepSize) * stepSize;
    val = val > maxRating ? maxRating : val;
    return val;
  };

  return (
    <div style={style} className={classNames(className, styles.component)}>
      <div
        className={classNames([styles.container, { [styles.readOnly]: readOnly }])}
        onClick={(event) => onClick(event)}
        onMouseMove={(event) => onMouseMove(event)}
        onMouseLeave={() => onMouseLeave(null)}
        ref={divRef}
      >
        <Layer
          name="base"
          maxRating={maxRating}
          width={100}
          icon={iconOutline}
          visible={true}
          color={baseColor}
          iconStyle={iconStyle}
        />
        <Layer
          name="select"
          maxRating={maxRating}
          width={toWidth(value)}
          icon={icon}
          visible={!isHovering}
          color={mainColor}
          iconStyle={iconStyle}
        />
        <Layer
          name="hover"
          maxRating={maxRating}
          width={visibleWidth}
          icon={icon}
          visible={isHovering}
          color={mainColorHover}
          iconStyle={iconStyle}
        />
      </div>
    </div>
  );
};

const Layer = ({ name, maxRating, width, icon, visible, color, iconStyle }) => (
  <span
    className={styles.base}
    style={{
      width: `${width}%`,
      overflow: 'hidden',
      position: name === 'base' ? 'relative' : 'absolute',
      top: 0,
      left: 0,
      verticalAlign: 'top',
      display: visible ? 'inline-block' : 'none',
      whiteSpace: 'nowrap',
      color,
    }}
  >
    {new Array(maxRating).fill(true).map((v, index) => {
      return (
        <Icon style={iconStyle} key={`layer-${index}`} className={styles.icon}>
          {icon}
        </Icon>
      );
    })}
  </span>
);

export default Rating;
