import isPlainObject from 'lodash/isPlainObject';

/**
 * Deterministic Hashing strategy imported from react-query
 * https://github.com/tannerlinsley/react-query/blob/4083c1ef8a8734d2680a4e5f031c7958c18ee176/src/core/utils.ts
 */

/**
 * Hashes the value into a stable hash.
 */
export function stableValueHash(value) {
  return JSON.stringify(value, (_, val) =>
    isPlainObject(val)
      ? Object.keys(val)
          .sort()
          .reduce((result, key) => {
            result[key] = val[key];
            return result;
          }, {})
      : val
  );
}

/**
 * Default query keys hash function.
 */
export default function hash(hashKey) {
  const asArray = Array.isArray(hashKey) ? hashKey : [hashKey];
  return stableValueHash(asArray);
}
