import { typeToMethod } from '../constants';
import { extractTypeFromKey } from '../utils';

export function updateKeyWithResult(key, primaryKey, queryClient) {
  return (record) => {
    const [typeExtractedKey, mutationType] = extractTypeFromKey(key);

    // this will leave us with a key of service and ID if passed
    // const typeExtractedKey = typeExtractedKey.filter(i => !isPlainObject(i));
    const previousData = queryClient.getQueryData(typeExtractedKey);

    if ('create' === typeToMethod[mutationType]) {
      if (Array.isArray(previousData)) {
        queryClient.setQueryData(typeExtractedKey, previousData.concat(record));
        return;
      }

      queryClient.setQueryData(typeExtractedKey, {
        ...previousData,
        data: previousData.concat(record),
        total: previousData.total + 1,
      });

      return;
    }

    if ('remove' === typeToMethod[mutationType]) {
      if (Array.isArray(previousData)) {
        queryClient.setQueryData(
          typeExtractedKey,
          previousData.filter((item) => {
            return record[primaryKey] !== item[primaryKey];
          })
        );

        return;
      }

      queryClient.setQueryData(typeExtractedKey, {
        ...previousData,
        data: previousData.data.filter((item) => {
          return record[primaryKey] !== item[primaryKey];
        }),
      });
      return;
    }

    // any sort of update
    if (Array.isArray(previousData)) {
      queryClient.setQueryData(
        key,
        previousData.map((item) => {
          return record[primaryKey] === item[primaryKey] ? record : item;
        })
      );

      return;
    }

    queryClient.setQueryData(key, {
      ...previousData,
      data: previousData.data.map((item) => {
        return record[primaryKey] === item[primaryKey] ? record : item;
      }),
    });
  };
}
