import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { formatPhoneNumber, formatTel } from '../../utils/formatPhoneNumber';

import styles from './PhoneNumber.module.css';

const PhoneNumber = ({ number, prefix, clickable = false }) => (
  <p className={styles.phoneNumber}>
    {prefix && (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip-prefix">{prefix === 'M' ? 'Mobile' : 'Office'}</Tooltip>}
      >
        <span>{`${prefix}: `}</span>
      </OverlayTrigger>
    )}
    <>
      {/* <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-text">{formatPhoneNumber(number)}</Tooltip>}> */}
      {!clickable && <span>{formatPhoneNumber(number)}</span>}
      {clickable && <a href={formatTel(number)}>{formatPhoneNumber(number)}</a>}
      {/* </OverlayTrigger> */}
    </>
  </p>
);

export default PhoneNumber;
