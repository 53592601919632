import React, { useMemo } from 'react';
import convert from 'htmr';
import linkify from 'linkify-html';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const domainRegex = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;
const transform = {
  statuses: 'span',
  wfhs: 'span',
  a: (node, props, children) => {
    const { children: aChildren, href, ...passThroughProps } = node;

    if (Array.isArray(aChildren)) {
      if (
        typeof aChildren[0] === 'string' &&
        (aChildren[0].startsWith('https') || aChildren[0].startsWith('http'))
      ) {
        return (
          <a href={aChildren[0]} {...passThroughProps} target="_blank" rel="noopener noreferrer">
            {aChildren}
          </a>
        );
      }
    }

    if (!href) {
      return <span {...passThroughProps}>{aChildren}</span>;
    }

    const internalLink = href.toString().match(/^https?:\/\/app\.webfx\.com/);
    if (internalLink?.[0]) {
      const to = href.replace(internalLink[0], '');
      return (
        <Link to={to} {...passThroughProps}>
          {aChildren}
        </Link>
      );
    }

    return (
      <a href={href} {...passThroughProps} target="_blank" rel="noopener noreferrer">
        {aChildren}
      </a>
    );
  },
  // Fallback parser.. this is a hack fix to cleanup bad html inside of messages in
  // teamwork
  _: (node, props, children) => {
    if (node.indexOf('http:') === 0 && props) {
      const domain = props && Object.keys(props).find((p) => domainRegex.test(p));
      if (!domain) {
        return node;
      }
      return (
        <a href={`http://${domain}`} key={props.key}>
          {children}
        </a>
      );
    }
    if (node.indexOf('mailto:') === 0) {
      return <a href={`mailto:${node}`}>{children}</a>;
    }
    if (emailRegex.test(node)) {
      return <a href={`mailto:${node}`}>{node}</a>;
    }
    if (typeof props !== 'undefined') {
      const Element = node;
      return <Element {...props}>{children}</Element>;
    }

    return node;
  },
};

function Html({
  str,
  options = {},
  linkOptions = {},
  sanitize = true,
  sanitizeOptions = {},
  ...passThroughProps
}) {
  const opts = {
    transform,
    ...options,
  };

  try {
    const html = useMemo(() => {
      const raw = sanitize
        ? linkify(DOMPurify.sanitize(str, sanitizeOptions), linkOptions)
        : linkify(str, linkOptions);
      return convert(raw, opts);
    }, [str, sanitize]);

    return <div {...passThroughProps}>{html}</div>;
  } catch (e) {
    return (
      <div {...passThroughProps}>
        <i>Unable to display todo. Please check todo description.</i>
      </div>
    );
  }
}

export default Html;
