import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button } from 'react-bootstrap';

function TimeOutError({ resetErrorBoundary }) {
  return (
    <Alert variant="danger">
      <Alert.Heading>Oops something went wrong</Alert.Heading>
      <p>Sorry we were not able to perform this action would you like to retry?</p>
      <Button variant="light" onClick={resetErrorBoundary}>
        retry
      </Button>
    </Alert>
  );
}

TimeOutError.propTypes = {
  resetErrorBoundary: PropTypes.func.isRequired,
};

export default TimeOutError;
