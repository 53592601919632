import classnames from 'classnames';
import GoogleMapReact from 'google-map-react';
import React from 'react';

const Map = ({
  height = '100vh',
  width = '100%',
  className = '',
  center = {
    lat: 59.95,
    lng: 30.33,
  },
  zoom = 11,
  children,
  ...passThroughProps
}) => {
  return (
    // Important! Always set the container height explicitly
    <div className={classnames(['g-map', className])} style={{ height, width }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.POI_APP_GOOGLE_MAPS_URL_KEY }}
        defaultCenter={center}
        center={center}
        defaultZoom={zoom}
        {...passThroughProps}
      >
        {children}
      </GoogleMapReact>
    </div>
  );
};

export default Map;
