import { useInfiniteQuery as rqUseInfiniteQuery } from 'react-query';
import isPlainObject from 'lodash/isPlainObject';
import { flatInfiniteQueryResult } from './helpers/flatInfiniteQueryResult';

/**
 * Wrapper around react-query's useInfiniteQuery.
 *
 * @param {?Function} transform Added option that allows for a data transform before the default
 *  `select` func is run. The transform function will be invoked with the same args that the
 *  react-query would invoke the `select` func with.
 * @param {...any} args
 * @returns {object} query key + react-query's useInfiniteQuery return value
 */
export function useInfiniteQuery(...args) {
  const reactQueryArgsIndex = args.findIndex((a) => isPlainObject(a));

  if (reactQueryArgsIndex !== -1) {
    const reactQueryArgs = args[reactQueryArgsIndex];
    args[reactQueryArgsIndex] = {
      getNextPageParam,
      ...reactQueryArgs,
      select: selectFactory({ transform: reactQueryArgs.transform }),
    };
  } else {
    args.push({
      getNextPageParam,
      select: selectFactory(),
    });
  }

  if (!Array.isArray(args[0])) {
    args[0] = [args[0]];
  }

  // Ensures that all queries have the "find" type when using ['service', { params }] shortcut.
  if (isPlainObject(args[1]) && args[0][1] !== 'find') {
    args[0].splice(1, 0, 'find');
  }

  const res = rqUseInfiniteQuery(...args);

  return {
    queryKey: args[0],
    ...res,
  };
}

/**
 * This is a basic wrapper around react-query to
 * provide a common api interface and default handlers
 *
 * For more info about infinite queries: https://react-query.tanstack.com/reference/useInfiniteQuery
 *
 * @param  {...any} args
 */

function getNextPageParam(lastPage) {
  const nextSkip = lastPage.skip + lastPage.limit;

  return lastPage.total > nextSkip ? nextSkip : undefined;
}

/**
 * Factory method that returns a function to be passed as the `select` option to react-query's
 * useInfiniteQuery method.
 *
 * @param {?Function} transform Custom transform function will be applied before any of the default
 *  transforms.
 * @returns {Function} select function
 */
function selectFactory({ transform } = {}) {
  return (result = {}) => {
    if (transform) {
      // eslint-disable-next-line no-param-reassign -- intentional
      result = transform(result) ?? result;
    }

    return flatInfiniteQueryResult(result);
  };
}
