import { thunk, action, actionOn } from 'easy-peasy';
import isPlainObject from 'lodash/isPlainObject';
import omit from 'lodash/omit';
import { toast } from 'react-toastify';
import deepmerge from 'deepmerge';
import feathers from '../../services/feathersClient';
import pendingListeners from './pendingListeners';

const editableItem = (name, idKey = '_id') => ({
  isCreatePending: false,
  isSavePending: false,
  isRemovePending: false,
  omitValues: [],

  update: action((state, payload) => {
    state.data = omit(payload, state.omitValues);
  }),

  patch: action((state, payload) => {
    state.data = deepmerge(state.data, omit(payload, state.omitValues));
  }),

  removeLocal: action((state, payload) => {
    state.data = null;
  }),

  save: thunk(async (actions, payload, { getState, getStoreActions }) => {
    const viewingItemId = getState().viewingId || null;
    const {
      id = viewingItemId, // uses currently viewing ID by default
      data,
      useUpdate = false,
    } = payload;

    const { common } = getStoreActions();
    try {
      await feathers().service(getState().service).patch(id, data);

      toast.success(`The ${name} was successfully updated`);

      if (useUpdate) {
        actions.update(data);
        return;
      }

      actions.patch(data);
    } catch (error) {
      // common.showError(error);
      toast.error(error);
    }
  }),

  create: thunk(async (actions, payload, { getState, getStoreActions }) => {
    const { common } = getStoreActions();
    try {
      const response = await feathers().service(getState().service).create(payload);

      toast.success(`The ${name} was successfully created`);
      actions.update(response);
    } catch (error) {
      toast.error(error);
    }
  }),

  remove: thunk(async (actions, payload, { getState, getStoreActions }) => {
    const { common } = getStoreActions();
    let id = payload;
    let keepLocal = true;

    if (isPlainObject(payload)) {
      id = payload[idKey];
      keepLocal = payload.keepLocal || true;
    }

    try {
      const response = await feathers().service(getState().service).remove(id);

      toast.success(`The ${name} was successfully deleted`);

      if (keepLocal) {
        actions.patch({ deletedAt: new Date(), ...response });
        return;
      }

      actions.removeLocal(id);
    } catch (error) {
      toast.error(error);
    }
  }),

  ...pendingListeners('save', 'isSavePending', ' setIsSavePending'),
  ...pendingListeners('remove', 'isRemovePending', ' setIsRemovePending'),

  setIsCreatePending: actionOn(
    (actions) => [actions.create.startType, actions.create.successType, actions.create.failType],
    (state, target) => {
      const [startType, successType, failType] = target.resolvedTargets;

      switch (target.type) {
        case startType:
          state.isCreatePending = true;
          state.isSavePending = true;
          break;
        case successType:
          state.isCreatePending = false;
          state.isSavePending = false;
          break;
        case failType:
          state.isCreatePending = false;
          state.isSavePending = false;
          break;
      }
    }
  ),
});

export default editableItem;
