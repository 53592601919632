import { action, computed, thunk } from 'easy-peasy';

const lazyable = (idKey = '_id') => ({
  itemCount: computed((state) =>
    state.current.total >= state.ids.length ? state.ids.length + 1 : state.ids.length
  ),

  isItemLoaded: thunk((actions, index, { getState }) => {
    const { current, ids } = getState();
    return current.total <= ids.length || index < ids.length;
  }),

  fetched: action((state, payload) => {
    const items = payload.data || payload;
    const ids = [];
    const data = {};
    const fetchedAt = new Date();
    items.forEach((item) => {
      item.fetchedAt = fetchedAt;
      data[item[idKey]] = item;
      ids.push(item[idKey]);
    });

    state.data = {
      ...state.data,
      ...data,
    };
    state.ids = {
      ...state.ids,
      ...ids,
    };
  }),

  clearValues: action((state) => {
    state.data = {};
  }),

  loadMore: thunk(({ setFilter, fetch }, payload, { getState }) => {
    const { limit, current, isFetchPending } = getState();

    if (isFetchPending) {
      return;
    }

    setFilter({
      $skip: current.skip + limit,
    });
    fetch();
  }),
});

export default lazyable;
