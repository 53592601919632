import feathers from '../services/feathersClient';
import { typeToMethod } from './constants';
/**
 * Helper function to be used with React-Query's useMutation to build the queryFn
 *
 * When using the 'mutate' or 'mutateAsync', passing _id and _params will map those keys to feathers record id and params
 *
 * ex:
 *   const mutation = useMutation(feathersMutation('crm/filters', 'patch', opts)
 *   await mutation.mutate({ _id: recordId, _params: { query }, ...values })
 *
 *
 * @param service
 * @param method
 * @returns {Function} {function(...[*]=)}
 */
export function defaultMutationFn(service, method = 'patch', id = null, params = {}) {
  return (data = {}) => {
    const {
      _id = id,
      _method = method.toLowerCase(),
      _service = service,
      _params = params,
      _multi, // pass multiple values for multi update
      _createId,
      ...values
    } = data;

    method = typeToMethod[_method.toLowerCase()];

    if (method === 'create' && !!_createId && !!_id) {
      values[_createId] = _id;
    }

    if (['patch', 'put'].includes(method)) {
      return feathers()
        .service(_service)
        [method](_id, _multi || values, _params);
    }

    if (['create'].includes(method)) {
      return feathers()
        .service(_service)
        [method](_multi || values, _params);
    }

    if (['get', 'remove'].includes(method)) {
      return feathers().service(_service)[method](_id, _params);
    }

    return feathers().service(_service)[method](_params);
  };
}
