import classnames from 'classnames';

import Radio from '../Radio/Radio';
import InputLabel from '../Input/InputLabel/InputLabel';

import styles from './RadioGroup.module.css';

const RadioGroup = ({
  field,
  field: { name, value },
  label,
  disabled,
  readOnly,
  className,
  bottomGutter,
  bold,
  id,
}) => (
  <div
    className={classnames(
      styles.wrap,
      { [styles.bottomGutter]: bottomGutter, [styles.bold]: bold },
      className
    )}
  >
    <div className={styles.col1}>
      <Radio field={field} disabled={disabled} readOnly={readOnly} id={id} />
    </div>
    <div className={styles.col2}>
      <InputLabel
        text={label}
        htmlFor={name}
        className={classnames(styles.label, { [styles.labelActive]: value === id })}
      />
    </div>
  </div>
);

export default RadioGroup;
