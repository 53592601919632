import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';

import AsyncFallBackComponents from '../AsyncFallBackComponents';

function AsyncErrorBoundary({ children, ...props }) {
  const [key, setKey] = useState(1);

  const retry = useCallback(() => setKey((state) => state + 1), [setKey]);

  const FallbackWithRetryComponent = useCallback(
    (fallbackProps) => <AsyncFallBackComponents {...fallbackProps} retry={retry} />,
    [retry]
  );

  return (
    <ErrorBoundary
      key={key}
      {...props}
      FallbackComponent={FallbackWithRetryComponent}
      onReset={retry}
    >
      {children}
    </ErrorBoundary>
  );
}

AsyncErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  props: PropTypes.object,
};

export default AsyncErrorBoundary;
