import React from 'react';
import LazyLoad from 'react-lazy-load';
import classNames from 'classnames';

import styles from './UserAvatar.module.css';

const UserAvatar = ({ avatar, name, className, inline, sm, ...props }) => {
  return (
    <span {...props} className={classNames(styles.container, className, { [styles.sm]: sm })}>
      <LazyLoad width={sm ? 32 : 52}>
        <img
          src={avatar}
          className={classNames(styles.image, 'rounded-circle', { 'd-inline mr-2': inline })}
          loading="lazy"
        />
      </LazyLoad>
      <span className={classNames(styles.name, { 'd-inline': inline })}>{name}</span>
    </span>
  );
};

export default UserAvatar;
