// Scores from oopspam
// 0-2 : Low
// 3-4 : Medium
// 5-6 : High
const spamRisk = (score) => {
  let risk = 'Low';

  // Low, Medium, High
  if (score >= 5) {
    risk = 'High';
  } else if (score >= 3) {
    risk = 'Medium';
  }

  return risk;
};

export default spamRisk;
