import React from 'react';

import Select from 'react-select';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
// import { InputGroup as WebFXInputGroup } from '../Input/InputGroup/InputGroup';

import { IndicatorSeparator, changeTheme, customStyles } from './selectStyle';

import styles from './SelectInputGroup.module.css';

export default function SelectInputGroup(props) {
  const {
    label,
    inputValue,
    selectValue,
    options,
    handleInputChange,
    handleSelectChange,
    inputPlaceholder = '',
    selectPlaceholder = '',
  } = props;

  return (
    <>
      <Form.Label>{label}</Form.Label>
      <InputGroup className={styles.groupContainer}>
        <Form.Control
          type="text"
          placeholder={inputPlaceholder}
          value={inputValue}
          className={styles.input}
          onChange={(e) => handleInputChange(e.currentTarget.value)}
        />
        <InputGroup.Prepend className={styles.selectWrap}>
          <Select
            placeholder={selectPlaceholder}
            options={options}
            value={{ label: selectValue, value: selectValue }}
            onChange={(value) => handleSelectChange(value)}
            theme={changeTheme}
            components={{ IndicatorSeparator }}
            styles={customStyles}
            className={styles.select}
          />
        </InputGroup.Prepend>
      </InputGroup>
    </>
  );
}

SelectInputGroup.propTypes = {
  inputValue: PropTypes.string,
  selectValue: PropTypes.string,
  options: PropTypes.arrayOf({}),
  label: PropTypes.string,
  handleInputChange: PropTypes.func,
  handleSelectChange: PropTypes.func,
};

SelectInputGroup.defaultProps = {
  inputValue: '',
  selectValue: '',
  options: [],
  label: '',
  handleInputChange: () => {},
  handleSelectChange: () => {},
};
