/* eslint-disable import/no-cycle */
import * as users from './users';
import * as user from './user';
import * as countries from './countries';
import * as timezones from './timezones';
import * as resetPassword from './resetPassword';
import * as auth from './auth';
import * as utils from './utils';

import feathersClient, { setClient } from './feathersClient';
import request from './feathersRequest';

export {
  setClient,
  feathersClient,
  auth,
  user,
  users,
  countries,
  timezones,
  resetPassword,
  request,
  utils,
};
