import React from 'react';
import Icon from '../Icon/Icon';
import Link from '../Link/Link';

import styles from './BreadCrumb.module.css';

/**
 * Renders a beautifully styled component for page history path
 * @param {Array<object>} items - Array of breadcrumb items
 * @param {string} siteName - Name of the site (optional)
 */
const BreadCrumb = ({ items = [] }) => {
  const crumbs = [];
  const lastIndex = items.length - 1;
  const nextIcon = <Icon className={styles.crumbSeparator}>chevron_right</Icon>;

  items.forEach((item, index) => {
    if (item?.name) {
      // render without link for last item
      const nodeElement =
        index === lastIndex ? (
          <div className="font-14 semibold text-gray-550">{item.name}</div>
        ) : (
          <Link to={item.link} className="font-14 text-blue-vivid-300">
            {item.name}
          </Link>
        );

      // wrap with default tag and style
      crumbs.push(
        <div
          key={`${index}-breadcrumb`}
          className={'d-flex justify-content-center align-items-center'}
        >
          {nodeElement}
          {index !== lastIndex && nextIcon}
        </div>
      );
    }
  });

  // wrap and justify content to be inline and return
  return (
    <div className={'d-flex justify-content-center align-items-center mr-auto mb-4'}>{crumbs}</div>
  );
};

export default BreadCrumb;
