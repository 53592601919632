import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Icon, Typography } from '@webfx/core-web';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './MessageContainer.module.css';

const MessageContainer = ({ icon = '', message = '' }) => {
  return (
    <Row noGutters={true} className={'d-flex align-items-center'}>
      <Col
        className={classnames(
          'col-xs-12 col-sm-3 col-md-1 col-lg-1 col-xl-1',
          styles.messageIconCol,
          !icon && styles.hideCol
        )}
      >
        <div className={styles.messageIcon}>
          <Icon outlined={true}>{icon}</Icon>
        </div>
      </Col>
      <Col className={'col-xs-12 col-sm-9 col-md-11 col-lg-11 col-xl-11'}>
        <Typography
          variant={'h5'}
          bold={false}
          className={styles.messageText}
          data-fx-name="alertMessage"
        >
          {message}
        </Typography>
      </Col>
    </Row>
  );
};

MessageContainer.propTypes = {
  icon: PropTypes.string,
  message: PropTypes.string,
};

export default MessageContainer;
