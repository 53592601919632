import { useQueries as useReactQueries } from 'react-query';
import isPlainObject from 'lodash/isPlainObject';
import { resolveQueryKey } from './utils';

/**
 * This is a basic wrapper around react-query to
 * provide a common api interface.
 *
 * For more info: https://react-query.tanstack.com/reference/useQueries
 */
export function useQueries(queries) {
  const queriesWithKey = queries.map((q) => {
    if (isPlainObject(q)) {
      return {
        ...q,
        queryKey: resolveQueryKey(q.queryKey),
      };
    }

    return { queryKey: resolveQueryKey(q) };
  });

  const results = useReactQueries(queriesWithKey);

  results.forEach((result, index) => {
    result.queryKey = queriesWithKey[index].queryKey;
  });

  return results;
}
