import ReactInfiniteScroll from 'react-infinite-scroller';
import { Spinner } from 'react-bootstrap';

import styles from './InfiniteScroll.module.css';

const Loader = (
  // It should have key otherwise will be showing warning about unique key
  <div key="spinner" className={styles.spinnerWrapper}>
    <Spinner key={0} animation="border" size="sm" variant="primary" />
  </div>
);

const InfiniteScroll = ({
  loadMore,
  hasMore,
  disableLoader = false,
  showLoader = true,
  children,
  threshold = 250,
}) => {
  return (
    <ReactInfiniteScroll
      pageStart={0}
      loadMore={(args) => !disableLoader && hasMore && loadMore(args)}
      hasMore={!disableLoader && hasMore}
      loader={showLoader ? Loader : null}
      threshold={threshold}
      useWindow
    >
      {children}
    </ReactInfiniteScroll>
  );
};

export default InfiniteScroll;
