import { thunk, action, actionOn } from 'easy-peasy';
import isPlainObject from 'lodash/isPlainObject';
import omit from 'lodash/omit';
import { toast } from 'react-toastify';
import feathers from '../../services/feathersClient';
import pendingListeners from './pendingListeners';

const editable = (name, idKey = '_id') => ({
  isCreatePending: false,
  createError: null,
  updateError: null,
  deleteError: null,
  omitValues: [],
  setCreateError: action((state, payload) => {
    state.createError = payload;
  }),
  setUpdateError: action((state, payload) => {
    state.updateError = payload;
  }),
  setDeleteError: action((state, payload) => {
    state.deleteError = payload;
  }),

  update: action((state, payload) => {
    if (payload.delete && state.data) {
      delete state.data[payload[idKey]];
      const index = state.ids.findIndex((id) => id === payload[idKey]);
      state.ids.splice(index, 1);
      return;
    }

    if (!state.data[payload[idKey]]) {
      state.ids.push(payload[idKey]);
    }

    if (state.data) {
      state.data[payload[idKey]] = {
        ...state.data[payload[idKey]],
        ...omit(payload, state.omitValues),
      };
    }
  }),

  patch: action((state, payload) => {
    const { delete: destroy = false, ...data } = payload;

    if (destroy && state.data) {
      delete state.data[payload[idKey]];
      const index = state.ids.findIndex((id) => id === payload[idKey]);
      state.ids.splice(index, 1);
      return;
    }

    state.data[data[idKey]] = {
      ...state.data[data[idKey]],
      ...omit(data, state.omitValues),
    };
  }),

  removeLocal: action((state, payload) => {
    state.ids = state.ids.filter((id) => id !== payload);
    delete state.data[payload];
  }),

  save: thunk(async (actions, payload, { getState, getStoreActions }) => {
    const viewingItemId = getState().viewingId || null;
    const {
      id = payload[idKey] || viewingItemId,
      useUpdate = false, // TODO move to mergeStrategy for consistency
      throwError = false,
      showToast = true,
      ...data
    } = payload;
    try {
      await feathers().service(getState().service).patch(id, data);

      if (useUpdate) {
        actions.update(data);
      }

      if (!data[idKey]) {
        data[idKey] = id;
      }

      actions.patch(data);

      if (showToast) {
        toast.success(`The ${name} was successfully updated`);
        return;
      } else {
        return true;
      }
    } catch (error) {
      actions.setUpdateError(error);

      if (showToast) {
        toast.error(error.message || error);
      }

      if (throwError) {
        throw error;
      }
    }
  }),

  create: thunk(async (actions, payload, { getState, getStoreActions }) => {
    const { common } = getStoreActions();
    const { throwError = false, ...data } = payload;
    try {
      const response = await feathers().service(getState().service).create(data);

      toast.success(`The ${name} was successfully created`);
      actions.update(response);
    } catch (error) {
      actions.setCreateError(error);
      toast.error(error);

      if (throwError) {
        throw error;
      }
    }
  }),

  remove: thunk(async (actions, payload, { getState, getStoreActions }) => {
    let id = payload;
    let keepLocal = false;
    const { throwError = false } = payload;

    if (isPlainObject(payload)) {
      id = payload[idKey];
      keepLocal = payload.keepLocal || false;
    }

    try {
      const response = await feathers().service(getState().service).remove(id);

      toast.success(`The ${name} was successfully deleted`);

      if (keepLocal) {
        actions.patch({ deletedAt: new Date(), delete: true, ...response });
        return;
      }

      actions.removeLocal(id);
    } catch (error) {
      actions.setDeleteError(error);
      toast.error(error);

      if (throwError) {
        throw error;
      }
    }
  }),

  ...pendingListeners('save', 'isSavePending', ' setIsSavePending'),
  ...pendingListeners('remove', 'isRemovePending', ' setIsRemovePending'),

  setIsCreatePending: actionOn(
    (actions) => [actions.create.startType, actions.create.successType, actions.create.failType],
    (state, target) => {
      const [startType, successType, failType] = target.resolvedTargets;

      switch (target.type) {
        case startType:
          state.isCreatePending = true;
          state.isSavePending = true;
          break;

        case successType:
          state.isCreatePending = false;
          state.isSavePending = false;
          break;

        case failType:
          state.isCreatePending = false;
          state.isSavePending = false;
          break;
      }
    }
  ),
});

export default editable;
