import feathers from '../services/feathersClient';

/**
 * Helper function to be used with React-Query's useMutation to build the queryFn
 *
 * ex:
 *   const [mutate, { status, data, error }] = useMutation(feathersMutation('crm/filters', 'patch', opts)
 *   await mutate({ _id: recordId, _params: params, ...values })
 *
 *
 * @param service
 * @param method
 * @returns {function(...[*])}
 */
const feathersMutation = (service, method) => {
  return (data) => {
    const { _id, _params = {}, ...values } = data;

    method = method.toLowerCase();

    if (['patch', 'put'].includes(method)) {
      return feathers().service(service)[method](_id, values, _params);
    }

    if (['create'].includes(method)) {
      return feathers().service(service)[method](values, _params);
    }

    if (['get', 'remove'].includes(method)) {
      return feathers().service(service)[method](_id, _params);
    }

    return feathers().service(service)[method](_params);
  };
};

export default feathersMutation;
