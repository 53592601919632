import { resolveQueryKey } from './utils';

/**
 * This is a basic wrapper around react-query to provide a common api interface.
 * It uses a defaultQueryFn that handles the keys and do the call to the feathers API.
 *
 * Usage:
 *
 * const query = useQuery('service', reactQueryOpts) // find
 * const query = useQuery(['service', queryOrParams], reactQueryOpts) // find service
 * const query = useQuery(['service', 5], reactQueryOpts) // get service/5
 * const query = useQuery(['service', 5, queryOrParams], reactQueryOpts)
 *
 * For more info: https://react-query.tanstack.com/reference/useQuery
 */
export function useQueryKey(...args) {
  args[0] = resolveQueryKey(args[0]);
  return args[0];
}
