import classNames from 'classnames';
import styles from './PageContainer.module.css';

const PageContainer = ({ children, viewingAsOther = false }) => {
  return (
    <div
      id={'pageContainer'}
      className={classNames({
        [styles.pageContainer]: true,
        ['viewingAsOther']: viewingAsOther,
      })}
    >
      {children}
    </div>
  );
};

export default PageContainer;
