import React from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';

import { useAuth } from '@webfx/web-hooks';
import ScrollToTop from './ScrollToTop';

/**
 * Render private route
 * Only authenticated users can access to private route
 * Otherwise - redirect user to another allowed page route
 * @param {object} props Pass-through props
 * @returns {React.ReactElement}
 */
const PrivateRoute = (props) => {
  const user = useAuth().user;

  if (!user) {
    return <Redirect to="/signin" />;
  }

  return (
    <ScrollToTop>
      <Route {...props} />
    </ScrollToTop>
  );
};

export default withRouter(PrivateRoute);
