import classNames from 'classnames';

import styles from './AlphabeticFilter.module.css';

const AlphabeticFilter = ({ selected = '', onSelect = () => {}, className }) => {
  const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

  return (
    <div className={`d-flex h-100 align-items-center justify-content-between px-4 ${className}`}>
      {alphabet.map((letter) => (
        <span
          key={letter}
          onClick={(e) => {
            e.preventDefault();
            onSelect(letter);
          }}
          className={classNames(styles.item, 'text-uppercase', {
            [styles.itemSelected]: selected.toLowerCase() === letter.toLowerCase(),
          })}
        >
          {letter}
        </span>
      ))}
    </div>
  );
};

export default AlphabeticFilter;
