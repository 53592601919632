import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Typography from '../../Typography/Typography';
import Icon from '../../Icon/Icon';

import styles from './InputLabel.module.css';

const InputLabel = ({ htmlFor, text, tooltip, className, extraInfo }) => (
  <label htmlFor={htmlFor} className={className}>
    {text}{' '}
    {tooltip && (
      <OverlayTrigger overlay={(props) => <Tooltip {...props}>{tooltip}</Tooltip>}>
        <Icon>help</Icon>
      </OverlayTrigger>
    )}
    {extraInfo && (
      <Typography variant="body1" component="span" className={styles.extraInfo}>
        {extraInfo}
      </Typography>
    )}
  </label>
);

export default InputLabel;
