import { action, computed, thunk, thunkOn } from 'easy-peasy';
import moment from 'moment';

const viewable = () => ({
  fetchStrategy: 'client', // server || client
  maxFetchedDuration: 900, // max age of client first strategy in seconds
  viewingId: null,
  item: computed([(state) => state.viewingId, (state) => state.data], (viewingId, data) => {
    if (!viewingId || !data[viewingId]) {
      return null;
    }
    return data[viewingId];
  }),

  setViewingTo: action((state, payload) => {
    state.viewingId = payload;
  }),

  setViewing: thunk(async (actions, payload) => {
    let id;
    if (typeof payload === 'object') {
      id = payload.id;
    } else {
      id = payload;
    }
    actions.setViewingTo(id);
  }),

  clearViewing: action((state) => {
    state.viewingId = null;
  }),

  maybeGetOnSetViewing: thunkOn(
    (actions) => actions.setViewing,
    (actions, target, { getState }) => {
      const { data, viewingId, fetchStrategy, maxFetchedDuration } = getState();

      const { forceFetch = false } = target.payload;

      const fetchedAt = data[viewingId]?.fetchedAt || new Date();
      const secondsSinceFetch = moment().diff(moment(fetchedAt)) / 1000;
      if (
        !data[viewingId] ||
        fetchStrategy === 'server' ||
        secondsSinceFetch > maxFetchedDuration ||
        forceFetch
      ) {
        actions.get({ id: viewingId });
      }
    }
  ),
});

export default viewable;
