import { ProgressBar } from 'react-bootstrap';
import { useStoreActions } from 'easy-peasy';
import { Link } from '@webfx/core-web';
import { useActiveSite, useRouter } from '@webfx/web-hooks';

import FileImg from '../../FileImg/FileImg';
import Button from '../../Button/Button';
import Typography from '../../Typography/Typography';

import './ImportItem.style.css';

const ImportItem = ({ item }) => {
  const { url } = useRouter();
  const { deletePoller, deleteFile } = useStoreActions((actions) => actions.imports);
  const { siteId } = useActiveSite();

  const fileName =
    item.filename.length >= 33 ? `${item.filename.substring(0, 30)}...` : `${item.filename}`;
  const progress = item.progress ?? 0;

  return (
    <div className="importitem-wrapper">
      <FileImg type={item.extension} className="mr-2" />
      <div className="w-100">
        <div className="d-flex justify-content-between align-items-center">
          <Typography className="mb-0 font-12">{fileName}</Typography>
          <Button
            icon="clear"
            variant="light"
            className="border-0 p-0 bg-white"
            onClick={() => {
              deletePoller(item.id);
              deleteFile(item.id);
            }}
          />
        </div>
        <ProgressBar className="importitem-progress-bar my-2" now={progress} />
        <Typography className="font-12">
          {progress === 100 ? (
            <span className="bold font-11">
              Upload Complete! View your&nbsp;
              <Link to={url({ route: 'mcfxCustomer360', siteId, page: 'contacts' })} plain={true}>
                contacts
              </Link>
              &nbsp;and/or&nbsp;
              <Link
                to={url({ route: 'mcfxAnalyticsReports', siteId, reportId: 'deals' })}
                plain={true}
              >
                deals
              </Link>
            </span>
          ) : (
            <>
              {item.status === 'waiting' ? (
                <span className="bold font-11">
                  Based on the current queue, expect your upload to begin
                  {item.expectedWaitTime
                    ? ` in ${item.expectedWaitTime} minute(s)`
                    : ' momentarily'}
                  . Once it begins, expect processing to take 1 minute per 1,000 rows in your file
                </span>
              ) : (
                <>{item.status ? `${progress}%` : null}</>
              )}
            </>
          )}
        </Typography>
      </div>
    </div>
  );
};

export default ImportItem;
