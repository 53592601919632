import React from 'react';
import classNames from 'classnames';
import styles from './StatusIndicator.module.css';

const StatusIndicator = ({ children, sm, variant = 'danger' }) => {
  return (
    <span
      className={classNames('d-flex, position-relative', {
        [styles.sm]: sm,
        [styles.withChildren]: children,
      })}
    >
      <span className={classNames(`bg-${variant}`, styles.indicator)} />
      {children}
    </span>
  );
};

export default StatusIndicator;
