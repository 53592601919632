import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import useCollapse from 'react-collapsed';
import useResizeObserver from 'use-resize-observer';

import styles from './Collapse.module.css';

const ExpandButton = ({
  className,
  setExpanded,
  isExpanded,
  expandedContent,
  collapsedContent,
  getToggleProps,
}) => (
  <button
    {...getToggleProps({
      className,
      onClick: () => setExpanded((prevExpanded) => !prevExpanded),
    })}
  >
    {isExpanded ? expandedContent : collapsedContent}
  </button>
);

const Collapse = React.forwardRef(
  (
    {
      defaultExpanded = false,
      expandStyles = {},
      collapseStyles = {},
      collapsedHeight = 0,
      easing = 'cubic-bezier(0.4, 0, 0.2, 1)',
      duration = 300,
      onCollapseStart,
      onCollapseEnd,
      onExpandStart,
      onExpandEnd,
      className,
      btnClassName = 'btn btn-link',
      btnCollapsedContent = 'Show More',
      btnExpandedContent = 'Show Less',
      btnPosition = 'bottom', // top || bottom
      children,
      ...passThroughProps
    },
    ref
  ) => {
    const fallbackRef = useRef();
    const normalizedRef = ref || fallbackRef;
    const [isExpanded, setExpanded] = useState(defaultExpanded);
    const size = useResizeObserver({ ref: normalizedRef });
    const { getCollapseProps, getToggleProps } = useCollapse({
      isExpanded: size?.height ? isExpanded : true,
      expandStyles,
      collapseStyles,
      collapsedHeight,
      easing,
      duration,
      onCollapseStart,
      onCollapseEnd,
      onExpandStart,
      onExpandEnd,
    });

    if (!children) {
      return null;
    }

    return (
      <>
        <div className={classnames([styles.container, className])} {...passThroughProps}>
          {btnPosition === 'top' && size?.height > collapsedHeight && (
            <ExpandButton
              className={btnClassName}
              collapsedContent={btnCollapsedContent}
              expandedContent={btnExpandedContent}
              setExpanded={setExpanded}
              isExpanded={isExpanded}
              getToggleProps={getToggleProps}
            />
          )}
          <section {...getCollapseProps()}>{children}</section>
          {btnPosition === 'bottom' && size?.height > collapsedHeight && (
            <ExpandButton
              className={btnClassName}
              collapsedContent={btnCollapsedContent}
              expandedContent={btnExpandedContent}
              setExpanded={setExpanded}
              isExpanded={isExpanded}
              getToggleProps={getToggleProps}
            />
          )}
        </div>
        <div className={styles.contentMeasurer}>
          <div ref={normalizedRef}>{children}</div>
        </div>
      </>
    );
  }
);

export default Collapse;
