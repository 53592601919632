import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@webfx/core-web';

const HelperCell =
  ({ truncate, headerText, helpText, isHeader }) =>
  ({ cell }) => {
    if (truncate && typeof truncate !== 'number') {
      truncate = 36;
    }
    if (headerText && isHeader && !cell?.value) {
      cell = { ...cell, value: headerText };
    }
    if (truncate && cell?.value.length > truncate) {
      cell.value = cell?.value.slice(0, truncate) + '...';
    }

    return (
      <div className="d-flex align-items-center">
        {cell?.value}
        {helpText && isHeader && (
          <OverlayTrigger overlay={<Tooltip>{helpText}</Tooltip>} placement="bottom">
            <Icon className="ml-2 text-primary font-14">help</Icon>
          </OverlayTrigger>
        )}
      </div>
    );
  };

export default HelperCell;
