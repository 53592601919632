import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';

import Icon from '../Icon/Icon';
import styles from './TabItem.module.css';

const TabItem = ({ tab, active }) => {
  const tabItem = classnames(styles.tabItem, { [styles.activeTabItem]: active });

  return (
    <li className={tabItem}>
      <NavLink to={tab.link} className={styles.navlink}>
        {tab.icon && (
          <span className={styles.iconWrap}>
            <Icon>{tab.icon}</Icon>
          </span>
        )}
        <span className={styles.tabName}>{tab.name}</span>
      </NavLink>
    </li>
  );
};

TabItem.propTypes = {
  tab: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string])).isRequired,
  active: PropTypes.bool.isRequired,
};

export default TabItem;
