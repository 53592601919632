import Avatar from '../Avatar/Avatar';

import styles from './ProfileWidget.module.css';

const ProfileWidget = ({ profileName, avatarUrl, handleClick }) => (
  <div role="presentation" className={styles.wrap} onClick={handleClick}>
    <div className={styles.textWrap}>
      <span className={styles.text}>{profileName}</span>
    </div>
    <Avatar className={styles.imgWrap} alt="Profile" src={avatarUrl} disableLazyLoad={true} />
  </div>
);

export default ProfileWidget;
