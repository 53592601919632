import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useStoreActions } from 'easy-peasy';

import FileImg from '../../FileImg/FileImg';
import Button from '../../Button/Button';
import Typography from '../../Typography/Typography';

import './DownloadItem.style.css';

const DownloadItem = ({ download }) => {
  const fileName =
    download.filename.length >= 33
      ? `${download.filename.substring(0, 30)}...`
      : `${download.filename}.`;
  const { deletePoller, deleteFile, deleteCsfxJobItem } = useStoreActions(
    (actions) => actions.downloads
  );
  return (
    <div className="downloaditem-wrapper" data-fx-name="downloadModal">
      <FileImg type={download.extension} className="mr-2" />
      <div className="w-100">
        <div className="d-flex justify-content-between align-items-center">
          <Typography className="mb-0 font-12" data-fx-name="downloadName">
            {fileName}
            {download.extension}
          </Typography>
          <Button
            icon="clear"
            variant="light"
            className="border-0 p-0 bg-white"
            onClick={() => {
              deletePoller(download.id);
              deleteFile(download.id);
              deleteCsfxJobItem(download.id);
            }}
          />
        </div>
        <ProgressBar className="downloaditem-progress-bar mt-2 mb-2" now={download.progress} />
        <Typography className="text-secondary font-12" data-fx-name="downloadProgress">
          {download.progress}%
        </Typography>
      </div>
    </div>
  );
};

export default DownloadItem;
