import React, { useEffect } from 'react';
import { useAuth } from '@webfx/web-hooks';
import { useResize } from '@webfx/web-context';
import classnames from 'classnames';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import capitalize from 'voca/capitalize';
import Icon from '../Icon/Icon';
import Link from '../Link/Link';
import Typography from '../Typography/Typography';

import store from '../../store/index';

const titleMap = {
  lead: 'Lead Submission',
  event: 'Event:',
  visit: 'Page View',
  company: 'Company Matched: ',
  chat: 'Chat started',
};

const iconMap = {
  lead: 'flag',
  visit: 'insert_drive_file',
  company: 'business',
  chat: 'comment',
};

const Event = ({ data, index }) => {
  const { leadId, organizationId } = useParams();
  const ref = React.useRef();
  const event = data[index];
  const { setSize } = useResize();
  const url = store.getState().router.url;
  let timezone = useAuth().user.timezone;

  if (!timezone) {
    timezone = moment.tz.guess();
  }

  useEffect(() => {
    if (ref.current) {
      setSize({ id: index, size: ref.current.getBoundingClientRect().height + 16 });
    }
  }, [ref.current]);

  return (
    <div
      ref={ref}
      className={classnames({
        'timeline-event': true,
        [`timeline-event-${event.type}`]: true,
      })}
    >
      {event.type === 'session' ? (
        <Typography className="session-title">
          End of Interaction | Duration:{' '}
          {event.duration === 0 ? 'Less than 10 seconds' : event.durationFormatted}
        </Typography>
      ) : null}

      {event.type !== 'session' ? (
        <>
          <Typography className="timeline-event-date">
            {moment.tz(event.date, 'Etc/UTC').tz(timezone).format('MMM D, YYYY hh:mma')}
          </Typography>

          <h3 className="timeline-event-title">
            {event?.entity !== 'event' ? (
              <div>
                {titleMap[event.type]}
                {event.type === 'company' ? event.company : ''} : {event.title}
              </div>
            ) : (
              <div>
                {titleMap[event.entity]} {event.type}
              </div>
            )}
          </h3>
          {event.type === 'visit' ? (
            <Typography className="timeline-event-url">{event.url}</Typography>
          ) : null}
          {event.type === 'chat' ? (
            <Typography variant="subtitle3">{event.email}</Typography>
          ) : null}
          {event.source || event.medium ? (
            <div className="timeline-event-source d-flex px-2 py-1 rounded">
              {event.source ? (
                <Typography className="mb-0 mr-3">
                  <span className="font-weight-bold">Source:</span> {capitalize(event.source)}
                </Typography>
              ) : null}
              {event.medium ? (
                <Typography className="mb-0">
                  <span className="font-weight-bold">Medium:</span> {capitalize(event.medium)}
                </Typography>
              ) : null}
            </div>
          ) : null}

          {event?.entity !== 'event' && event.type === 'lead' && event.id !== leadId ? (
            <Link
              className="timeline-event-link btn btn-sm btn-orange mt-3"
              to={url({
                route: 'mcfxLeadManager',
                siteId: event.sid,
                entity: `${event.entity}s`,
                leadId: event.id,
              })}
            >
              View Lead
            </Link>
          ) : null}

          {event.type === 'company' && event.id !== organizationId ? (
            <Link
              className="timeline-event-link btn btn-sm btn-primary mt-3"
              to={url({
                route: 'mcfxCompanyTracker',
                siteId: event.sid,
                organizationId: event.companyId,
              })}
            >
              View Company
            </Link>
          ) : null}
          <div className="timeline-event-icon">
            <Icon outlined>{iconMap[event.type]}</Icon>
          </div>

          {(event.id === leadId && event.type === 'lead') ||
          (event.type === 'company' && event.id === organizationId) ? (
            <span className="badge badge-primary mt-2">Viewing</span>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

Event.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  index: PropTypes.number.isRequired,
};

export default Event;
