import { Link } from 'react-router-dom';

import styles from './CardEditLink.module.css';

// TODO: Deprecate, If we need a generic style then create a universal class

const CardEditLink = ({ to, text = 'Edit' }) => (
  <Link to={to} className={styles.cardEditLink}>
    {text}
  </Link>
);

export default CardEditLink;
