import { useMemo } from 'react';
import moment from 'moment-timezone';
import groupBy from 'lodash/groupBy';

/**
 * Custom hook that groups impressions by session and adds session information to each session.
 * @param {object[]} impressions impressions to be processed.
 * @returns {object[]} impressions grouped by session with added session information.
 */
export function useSessionedImpressions(impressions) {
  return useMemo(() => {
    if (!impressions) {
      return [];
    }

    const sessionedImpressions = [];
    const sessions = groupBy(impressions, 'sessionId');
    Object.entries(sessions).forEach(([_sessionId, events]) => {
      const lastEvent = events[0];
      const firstEvent = events[events.length - 1];
      const session = {
        entity: 'session',
        date: lastEvent.date,
        timeOnPage: moment(lastEvent.date).diff(moment(firstEvent.date), 'seconds'),
      };
      events.unshift(session);
      sessionedImpressions.push(...events);
    });

    return sessionedImpressions;
  }, [impressions]);
}
