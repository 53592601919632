import React from 'react';
import classnames from 'classnames';
import { Spinner } from 'react-bootstrap';

import Icon from '../Icon/Icon';
import SvgIcon from '../SvgIcon/SvgIcon';
import { ucFirst } from '../../utils/language';
import Link from '../Link';

import styles from './Button.module.css';

const ButtonInternals = ({
  text,
  icon,
  iconOutlined = false,
  iconClassName,
  svg,
  isLoading = false,
  fullWidth = false,
  iconTrailing = false,
  wrapperClassName,
  children,
  iconPosition = 'left',
}) => (
  <>
    {!isLoading && (
      <span
        className={classnames(
          styles.contentWrap,
          { [styles.contentWrapBlock]: (icon || svg) && text && fullWidth },
          wrapperClassName
        )}
      >
        {icon && iconTrailing && '\u00a0' /* &nbsp */}
        {icon && iconPosition === 'left' && !iconTrailing && (
          <Icon
            className={classnames({ [styles.icon]: !fullWidth }, iconClassName)}
            outlined={iconOutlined}
          >
            {icon}
          </Icon>
        )}
        {svg && (
          <SvgIcon
            className={classnames({ [styles.icon]: !fullWidth }, iconClassName)}
            data={svg}
          />
        )}
        {children && (
          <span className={classnames({ [styles.text]: !fullWidth })} data-fx-name="label">
            {children}
          </span>
        )}
        {text && (
          <span className={classnames({ [styles.text]: !fullWidth })} data-fx-name="label">
            {text}
          </span>
        )}
        {icon && iconTrailing && '\u00a0' /* &nbsp */}
        {icon && iconPosition === 'right' && iconTrailing && (
          <Icon
            className={classnames({ [styles.icon]: !fullWidth }, iconClassName)}
            outlined={iconOutlined}
          >
            {icon}
          </Icon>
        )}
        {/* need filler for correct view for the justify-content: space-between  */}
        {icon && text && fullWidth && <span className={styles.filler} />}
      </span>
    )}
    {isLoading && <Spinner animation="border" size="sm" />}
  </>
);

const Button = React.forwardRef(
  (
    {
      type = 'button',
      disabled = false,
      className,
      variant = '',
      outline = false,
      block = false,
      round = false,
      fullWidth = false,
      borderless = false,
      href = null,
      to = null,
      onClick = () => {},
      onMouseEnter = () => {},
      onMouseLeave = () => {},
      size = '',
      uppercase = false,
      btnGroup = false,
      jumbo = false,
      extraPadding = false,
      normalWeight = false,
      useNewTab = false,
      id = '',
      centerContent = false,
      ...internalProps
    },
    ref
  ) => {
    if (variant !== '') {
      variant = '-' + variant;
    }
    if (variant.indexOf('-outline') !== -1) {
      outline = true;
      variant = variant.replace('-outline', '');
    }

    if (block) {
      fullWidth = true;
    }
    if (fullWidth && !block) {
      block = true;
    }
    if (href !== null) {
      to = href;
    } // allow either href or to

    const external =
      to && (to.indexOf('http') === 0 || to.indexOf('tel') === 0 || to.indexOf('mailto') === 0);

    const btnClass = `btn${outline ? '-outline' : ''}${variant}`;
    const classes = classnames(
      'btn',
      {
        [btnClass]: outline || variant,
        [styles.disabled]: disabled,
        // // [styles.block]: block,
        'btn-block': block || fullWidth,
        [styles.round]: round,
        [styles.borderless]: borderless,
        [styles['btn' + ucFirst(size)]]: size !== '',
        [styles.uppercase]: uppercase,
        [styles.btnGroup]: btnGroup,
        [styles.jumbo]: jumbo,
        [styles.extraPadding]: extraPadding,
        [styles.normalWeight]: normalWeight,
      },
      className
    );

    // avoid bug inside eslint rule https://github.com/yannickcr/eslint-plugin-react/issues/1846
    const button = type;

    if (to !== null && !external) {
      return (
        <Link
          plain={useNewTab}
          ref={ref}
          to={to}
          className={classnames(
            classes,
            centerContent ? 'd-flex align-items-center justify-content-center' : ''
          )}
          onClick={onClick}
          data-fx-name={internalProps['data-fx-name']}
        >
          <ButtonInternals {...internalProps} fullWidth={fullWidth} />
        </Link>
      );
    }

    if (to !== null && external) {
      return (
        <a
          ref={ref}
          href={to}
          className={classes}
          onClick={onClick}
          {...{
            target: useNewTab ? '_blank' : '',
            rel: useNewTab ? 'noreferrer' : '',
          }}
          data-fx-name={internalProps['data-fx-name']}
        >
          <ButtonInternals {...internalProps} fullWidth={fullWidth} />
        </a>
      );
    }

    return (
      <button
        id={id}
        data-fx-name={internalProps['data-fx-name']}
        ref={ref}
        type={button}
        disabled={disabled}
        className={classnames(
          classes,
          centerContent ? 'd-flex align-items-center justify-content-center' : ''
        )}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <ButtonInternals {...internalProps} fullWidth={fullWidth} />
      </button>
    );
  }
);

export default Button;
