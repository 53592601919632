import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useField } from 'formik';

import Icon from '../Icon';

import './Checkbox.style.css';

const Checkbox = React.forwardRef(
  (
    {
      label,
      name,
      value,
      checked,
      disabled,
      readOnly,
      inline,
      onChange,
      onBlur,
      customSwitch,
      indeterminate,
      labelOnLeft,
      initialValue,
      round = false,
      size = null,
      inputClassname = null,
      labelClassname = null,
      wrapperClassname = null,
      error,
      ...passThroughProps
    },
    ref
  ) => {
    if (checked === undefined) {
      checked = value;
    }

    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [indeterminate]);

    return (
      <div
        className={classnames([
          'custom-control',
          'custom-checkbox',
          { 'custom-switch': customSwitch },
          { 'label-on-left': labelOnLeft },
          { 'custom-control-inline': inline },
          { 'custom-control-lg': size === 'lg' },
          { 'custom-control-sm': size === 'sm' },
          wrapperClassname,
        ])}
      >
        <input
          ref={resolvedRef}
          type="checkbox"
          className={classnames(['custom-control-input', inputClassname])}
          id={name}
          name={name}
          hidden={round}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          readOnly={readOnly}
          value={value}
          checked={checked}
          {...passThroughProps}
        />
        <label
          className={classnames(
            'cursor-pointer',
            {
              'custom-control-label': !round,
              'text-primary-light-gray': round && !checked,
            },
            labelClassname
          )}
          htmlFor={passThroughProps.id || name}
          data-fx-name="checkbox"
        >
          {round && (
            <Icon
              type="check"
              className={classnames([
                'rounded-circle mr-2 align-middle',
                {
                  'text-white bg-blue-vivid-300': checked,
                  'icon-unchecked': !checked,
                  'font-14': size === 'sm',
                  'font-18': size === 'md',
                  'font-22': size === 'lg',
                },
              ])}
            />
          )}
          {label}
        </label>
      </div>
    );
  }
);

/**
 *
 * @param root0
 * @param root0.name
 */
export const FormikCheckbox = ({ name, ...passThroughProps }) => {
  const [field, meta] = useField(name);

  return <Checkbox {...field} {...meta} {...passThroughProps} />;
};

export default Checkbox;
