import * as React from 'react';
import classNames from 'classnames';
import { Modal } from 'react-bootstrap';
import Button from '../Button/Button';
import styles from './Modal.module.css';

const ModalHeader = ({ children, ...props }) => (
  <Modal.Header {...props} className={styles.header}>
    {children}
  </Modal.Header>
);

const ModalFooter = ({ children, ...props }) => (
  <Modal.Footer {...props} className={styles.footer}>
    {children}
  </Modal.Footer>
);

const ModalBody = ({ children, ...props }) => (
  <Modal.Body {...props} className={styles.body}>
    {children}
  </Modal.Body>
);

const ModalTitle = ({ children, ...props }) => (
  <Modal.Title {...props} className={styles.title}>
    {children}
  </Modal.Title>
);

const ModalComponent = ({
  show = false,
  size = 'md',
  children,
  showCloseBtn = false,
  showCancelBtn = true,
  title,
  btnCancelText = 'CANCEL',
  btnConfirmText = 'CONFIRM',
  onConfirm,
  onClose,
  centered,
  headerButton = false,
  headerAction,
  headerButtonText,
  showFooter = true,
  showHeader = true,
  passedClassNames,
  contentClassName,
  backdrop = true,
  keyboard = true,
  autoFocus = true,
  scrollable = false,
  dataFxName,
  ...passThroughProps
}) => {
  React.Children.forEach(children, (child) => {
    if (!child) {
      return;
    }

    if (child.type.name === 'ModalHeader') {
      showHeader = false;
    } else if (child.type.name === 'ModalFooter') {
      showFooter = false;
    }
  });

  return (
    <Modal
      show={show}
      dialogClassName={classNames(
        styles.dialog,
        {
          [styles.dialogXL]: size === 'lg',
        },
        passedClassNames
      )}
      onHide={onClose}
      centered={centered}
      backdrop={backdrop}
      keyboard={keyboard}
      enforceFocus={autoFocus}
      contentClassName={classNames(contentClassName)}
      scrollable={scrollable}
      data-fx-name={dataFxName || passThroughProps['data-fx-name']}
    >
      {showHeader && (
        <ModalHeader closeButton={showCloseBtn}>
          <ModalTitle>{title}</ModalTitle>
          {headerButton && (
            <Button
              variant="primary-outline"
              text={headerButtonText}
              borderless
              size="sm"
              uppercase
              onClick={headerAction}
            />
          )}
        </ModalHeader>
      )}
      {children}
      {showFooter && (
        <ModalFooter>
          {showCancelBtn && (
            <Button
              variant="danger-outline"
              text={btnCancelText}
              size="lg"
              borderless
              className={styles.btnCancel}
              onClick={onClose}
            />
          )}
          <Button
            variant="primary"
            text={btnConfirmText}
            size="lg"
            className={styles.btnConfirm}
            onClick={onConfirm}
          />
        </ModalFooter>
      )}
    </Modal>
  );
};

ModalComponent.Header = ModalHeader;
ModalComponent.Title = ModalTitle;
ModalComponent.Body = ModalBody;
ModalComponent.Footer = ModalFooter;

export default ModalComponent;
