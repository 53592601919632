import { forwardRef } from 'react';
import classnames from 'classnames';

import styles from './Tooltip.module.css';

// TODO: refactor as standard bootstrap tooltip with overlayTrigger built in.
const renderTooltip = forwardRef(({ style, className, children }, ref) => (
  <div ref={ref} style={style} className={classnames(styles.wrapper, className)}>
    {children}
  </div>
));

export default renderTooltip;
