import isPlainObject from 'lodash/isPlainObject';
import feathers from '../services/feathersClient';

/**
 * Default Query Fn that wraps feathers service calls using query keys.
 *
 * @param queryKey
 * @param pageParam
 * @returns {Promise<*>}
 */
export async function defaultQueryFn({ queryKey, pageParam }) {
  let params = {};

  const parseParams = (paramsToParse = {}) => {
    if (!paramsToParse) {
      paramsToParse = {};
    }

    // Prevent queryKey mutation.
    paramsToParse = { ...paramsToParse };

    if (!paramsToParse.query) {
      paramsToParse = { query: paramsToParse };
    }

    if (pageParam) {
      paramsToParse.query.$skip = pageParam;
    }

    return paramsToParse;
  };

  switch (queryKey.length) {
    case 3:
      params = parseParams(queryKey[2]);

      if (queryKey[1] === 'find') {
        params = parseParams(queryKey[2]);
        return await feathers().service(queryKey[0]).find(params);
      }

      return await feathers().service(queryKey[0]).get(queryKey[1], params);

    case 2:
      if (isPlainObject(queryKey[1])) {
        params = parseParams(queryKey[1]);

        return await feathers().service(queryKey[0]).find(params);
      }

      return await feathers().service(queryKey[0]).get(queryKey[1], params);

    default:
      return await feathers().service(queryKey[0]).find(params);
  }
}
