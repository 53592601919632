import { action, computed, persist, thunkOn } from 'easy-peasy';
import memoizerific from 'memoizerific';
import { compile, match } from 'path-to-regexp';

const router = persist(
  {
    pathname: '',
    search: '',
    hash: '',
    state: undefined,
    key: undefined,
    params: {},
    routes: [],
    active: null,
    lastViewed: {},
    prevViewed: {},

    // generate a url to use within views by name
    url: computed([(state) => state.routes], (routes) =>
      memoizerific(100)((opts) => {
        // TODO refactor to use React router core generatePath

        let findBy;
        let replacements;

        if (typeof opts === 'string') {
          findBy = opts;
          replacements = {};
        } else {
          const { route, ...replacementOpts } = opts;

          findBy = route;
          replacements = replacementOpts;
        }

        let route = routes.find((r) => r.name === findBy || r.url === findBy);

        if (!route) {
          route = routes.find((r) => 0 === r.url.indexOf(findBy));
        }

        // additionally find route match
        if (!route) {
          route = routes.find((r) => r.match(findBy));
        }

        if (!route) {
          return '#';
        }

        return route.generate(replacements);
      })
    ),

    // generate a route to use within views by name
    route: computed([(state) => state.routes], (routes) =>
      memoizerific(100)((name) => {
        const route = routes.find((r) => r.name === name);

        if (!route) {
          console.warn(`Route ${name} is not defined`);
          return '';
        }
        return route.url;
      })
    ),

    // Get a route name by url match
    routeName: computed([(state) => state.routes], (routes) => (url) => {
      const route = routes.find((r) => r.match(url));

      if (!route) {
        return '#';
      }

      return route.name;
    }),

    app: computed([(state) => state.pathname], (pathname) => {
      const apps = [
        'analytics',
        'chat',
        'marketingcloud',
        'contentgenius',
        'nutshell',
        'playbook',
        'competitorspy',
      ];

      /**
       * Checks the active app by pathname
       * 1. If the pathname includes the app name
       * 2. If the pathname starts with the app name
       * 3. Default to 'teamwork'
       */
      const active =
        apps.find((app) => pathname.includes(app)) ||
        apps.find((app) => pathname.startsWith(`/${app}`)) ||
        'teamwork';

      return active;
    }),

    setLocation: action((state, payload) => {
      Object.keys(payload).forEach((k) => {
        state[k] = payload[k];
      });
    }),

    onSetLocation: thunkOn(
      (actions) => actions.setLocation,
      async (actions, target) => {
        actions.parseGlobalParams(target.payload);
      }
    ),

    parseGlobalParams: action((state, payload) => {
      const { pathname } = payload;
      let match = false;
      let route = null;

      if (!state.routes.length) {
        return;
      }

      state.routes.forEach((r) => {
        if (match) {
          return;
        }
        match = r.match(pathname);
        route = r.name;
      });

      // guard incase there were no matches
      if (!match) {
        return;
      }

      state.params = match.params;
      state.active = route;

      // keep the last viewed page
      // for persisting redirect to last viewed page on sign in.
      if (route !== 'signin' && route !== 'confirmPassword') {
        state.prevViewed = state.lastViewed;
        state.lastViewed = {
          ...payload,
          params: match.params,
        };
      }
    }),

    setLastViewed: action((state, payload) => {
      state.prevViewed = state.lastViewed;
      state.lastViewed = payload;
    }),

    setParams: action((state, payload) => {
      state.params = payload;
    }),

    addRoute: action((state, payload) => {
      if (payload.name && state.routes.findIndex((r) => r.name === payload.name) !== -1) {
        throw new Error('Route is already defined');
      }

      payload.generate = compile(payload.url);
      payload.match = match(payload.url, { decode: decodeURIComponent });

      state.routes.push(payload);
    }),

    registerRoutes: action((state, payload) => {
      payload.forEach((route) => {
        if (route.name && state.routes.findIndex((r) => r.name === route.name) !== -1) {
          throw new Error('Route is already defined');
        }
        route.generate = compile(route.url); // TODO remove in favor of React router core generatePath
        route.match = match(route.url, { decode: decodeURIComponent });
        state.routes.push(route);
      });
    }),
  },
  {
    storage: 'localStorage',
    allow: ['lastViewed'],
  }
);

export default router;
