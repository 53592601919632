import * as localStorage from './localStorage';
import cropText from './cropText';
import copyToClipboard from './copyToClipboard';
import dirtyProps from './dirtyProps';
import truncateValue from './truncateValue';
import numberWithCommas from './numberWithCommas';
import objectToQuery from './objectToQuery';
import formatPhoneNumber from './formatPhoneNumber';
import * as validators from './validators';
import * as language from './language';
import feathersMutation from './feathersMutation';
import hash from './hash';
import logError from './logError';
import spamRisk from './spamRisk';

import { extractServerError, extractServerResponse } from './server';
import { cancelablePromise, delay } from './cancelablePromise';

export {
  localStorage,
  cropText,
  copyToClipboard,
  dirtyProps,
  formatPhoneNumber,
  truncateValue,
  numberWithCommas,
  objectToQuery,
  cancelablePromise,
  delay,
  validators,
  language,
  extractServerError,
  extractServerResponse,
  feathersMutation,
  hash,
  logError,
  spamRisk,
};
