import classnames from 'classnames';

const Svg = ({
  src,
  className,
  svgStyle = {},
  svgClassName = '',
  svgProps,
  dataFxName,
  component: Component = 'div',
  ...passThroughProps
}) => {
  const Icon = src;

  return (
    <Component className={classnames(['svg-container', className])} {...passThroughProps}>
      <Icon
        className={svgClassName}
        preserveAspectRatio="xMinYMin meet"
        style={{ width: '100%', height: '100%', ...svgStyle }}
        data-fx-name={dataFxName}
        {...svgProps}
      />
    </Component>
  );
};

export default Svg;
