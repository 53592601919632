import fetchable from './mixins/fetchable';
import editable from './mixins/editable';
import viewable from './mixins/viewable';
import itemsAsOptions from './mixins/itemsAsOptions';

export default {
  ...fetchable('/companies', 'companyId'),
  ...viewable(),
  ...editable('company', 'companyId'),
  ...itemsAsOptions('companyId', 'name'),
};
