import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import ReactCalendar from 'react-calendar';
import _ from 'lodash';

import { useClickOutside } from '@webfx/web-hooks';
import Icon from '../Icon/Icon';

import './DefaultDatePicker.style.css';

//TODO: remove moment and replace with Luxon

/**
 * Helper to format a date string.
 *
 * @param {string} dateStr Date string to format
 * @param {string} dateFormat Date format, e.g. 'MMM yyyy'
 * @param {*} onInvalid Return value if `dateStr` is invalid.
 * @returns {string} formatted date string
 */
const toFormat = (dateStr, dateFormat, { onInvalid = '' } = {}) => {
  let formattedDate = onInvalid;
  try {
    const dt = moment(dateStr, dateFormat);
    if (dt.isValid()) {
      formattedDate = dt.format(dateFormat);
    }
  } catch (e) {
    console.error(`Failed to parse date: ${dateStr} | ${e.message || e}`);
  }

  return formattedDate;
};

/**
 * Helper to parse a date string into a JS Date object.
 *
 * @param {string} dateStr Date string to parse
 * @param {string} dateFormat Date format, e.g. 'MMM yyyy'
 * @param {Function} onInvalid If `dateStr` is invalid, then this function's return value will be
 *  returned.
 * @returns {Date} JS Date object.
 */
const toDate = (dateStr, dateFormat, { onInvalid = () => new Date() } = {}) => {
  const formattedDate = toFormat(dateStr, dateFormat, { onInvalid: null });
  return formattedDate ? moment(formattedDate, dateFormat).toDate() : onInvalid();
};

const Input = ({
  name,
  value,
  onChange,
  onFocus,
  placeholder = 'MM/DD/YYYY',
  dateFormat = 'MM/DD/YYYY',
}) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setInputValue(toFormat(value, dateFormat));
  }, [value]);

  const handleOnChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleOnKeyDown = (event) => {
    if (event.key === 'Enter') {
      onChange(name, event.target.value);
    }
  };

  return (
    <input
      type="text"
      className="DefaultDatePicker__input"
      name={name}
      placeholder={placeholder}
      onChange={handleOnChange}
      onKeyDown={handleOnKeyDown}
      value={inputValue}
      onFocus={onFocus}
      data-fx-name={_.camelCase(name + 'Value')}
    />
  );
};

const ShortcutButton = ({ startDate, endDate, text = '', months = null, onClick }) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    isExactDate();
  }, [startDate, endDate]);

  const isExactDate = () => {
    if (months) {
      if (
        moment(startDate).isSame(moment().startOf('month').subtract(months, 'months'), 'day') &&
        moment(endDate).isSame(moment().subtract(1, 'months').endOf('month'), 'day')
      ) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    } else {
      if (
        moment(startDate).isSame(moment().startOf('month'), 'day') &&
        moment(endDate).isSame(moment().endOf('month'), 'day')
      ) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    }
  };

  const handleOnClick = () => {
    if (months) {
      onClick(
        moment().startOf('month').subtract(months, 'months'),
        moment().subtract(1, 'months').endOf('month')
      );
    } else {
      onClick(moment().startOf('month'), moment().endOf('month'));
    }
  };

  return (
    <button
      type="button"
      className={classnames('DefaultDatePicker-Shortcuts__Button', {
        ['DefaultDatePicker-Shortcuts__Button--active']: isActive,
      })}
      onClick={() => handleOnClick()}
      data-fx-name={_.camelCase(text)}
    >
      {text}
    </button>
  );
};

const DefaultDatePicker = ({
  className = '',
  id = '',
  view = null,
  defaultView = null,
  showDoubleView = false,
  selectRange = false,
  value = '',
  onDateChange = null,
  closeOnSelect = true,
  startDatePlaceholder = 'Start Date',
  shortcuts = false,
  dateFormat = 'MM/DD/YYYY',
  isInputInteractive = false,
  ...props
}) => {
  const ref = React.useRef();
  const [tempDate, setTempDate] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  useClickOutside(ref, () => setIsFocused(false));

  // DefaultDatePicker.handleClickOutside = ;

  useEffect(() => {
    update(value);
  }, [value]);

  useEffect(() => {
    if (selectRange && moment(startDate, dateFormat).isAfter(endDate)) {
      setEndDate('');
    }
  }, [startDate]);

  const update = (value) => {
    if (selectRange) {
      if (value[0] !== startDate || value[1] !== endDate) {
        setStartDate(toFormat(value[0], dateFormat));
        setEndDate(toFormat(value[1], dateFormat));
      }
    } else {
      if (value !== startDate) {
        setStartDate(toFormat(value, dateFormat));
        setEndDate(toFormat(value, dateFormat));
      }
    }
  };

  const handleOnChange = (value) => {
    const startDate = toFormat(value[0], dateFormat);
    const endDate = toFormat(value[1], dateFormat);

    setStartDate(startDate);
    setEndDate(endDate);

    if (onDateChange !== null) {
      if (selectRange) {
        onDateChange([startDate, endDate]);
      } else {
        onDateChange(startDate);
      }
    }
    if (closeOnSelect) {
      setIsFocused(false);
    }
  };

  const getRangeValue = (date1, date2) => {
    const rawNextValue = [date1, date2].sort((a, b) => a - b);
    return [
      moment(rawNextValue[0], dateFormat).startOf('month'),
      moment(rawNextValue[1], dateFormat).endOf('month'),
    ];
  };

  const handleOnClickMonth = (value) => {
    if (selectRange) {
      // Range selection turned on
      if (tempDate === null) {
        setTempDate(value);
        setStartDate(toFormat(value, dateFormat));
        setEndDate(toFormat(value, dateFormat));
      } else {
        const newRange = getRangeValue(tempDate, value);
        handleOnChange(newRange);
        setTempDate(null);
      }
    } else {
      // Range selection turned off
      handleOnChange([value, value]);
    }
  };

  const handleOnInputChange = (name, value) => {
    setIsFocused(false);
    if (selectRange) {
      if (name === 'startDate') {
        handleOnChange([value, endDate]);
      } else {
        handleOnChange([startDate, value]);
      }
    } else {
      handleOnChange([value]);
    }
  };

  const handleMonthsAutoSelect = (startDate, endDate) => {
    handleOnChange([startDate, endDate]);
    if (closeOnSelect) {
      setIsFocused(false);
    }
  };

  return (
    <div
      ref={ref}
      className={classnames([
        'DefaultDatePicker',
        className,
        {
          'DefaultDatePicker--has-shortcuts': shortcuts && selectRange,
          'DefaultDatePicker--doubleView': showDoubleView,
          'DefaultDatePicker--view': typeof view === 'string',
        },
      ])}
    >
      <div
        className="DefaultDatePicker__inputs"
        data-fx-name="datePicker"
        onClick={() => (isInputInteractive ? setIsFocused(!isFocused) : {})}
      >
        <Icon className="DefaultDatePicker__Calendar-icon" onClick={() => setIsFocused(!isFocused)}>
          date_range
        </Icon>

        <Input
          name="startDate"
          placeholder={startDatePlaceholder}
          value={startDate}
          onChange={handleOnInputChange}
          onFocus={() => setIsFocused(true)}
          dateFormat={dateFormat}
        />
        {selectRange && (
          <>
            —
            <Input
              name="endDate"
              placeholder="End Date"
              value={endDate}
              onChange={handleOnInputChange}
              onFocus={() => setIsFocused(true)}
              dateFormat={dateFormat}
            />
          </>
        )}
      </div>

      <div
        className={classnames(['DefaultDatePicker-Dialog', { 'd-none': !isFocused }])}
        data-fx-name="datePickerDialog"
      >
        {shortcuts && selectRange && (
          <div className="DefaultDatePicker-Shortcuts">
            <ShortcutButton
              startDate={startDate}
              endDate={endDate}
              text="This Month"
              onClick={handleMonthsAutoSelect}
            />
            <ShortcutButton
              startDate={startDate}
              endDate={endDate}
              text="Last Month"
              months={1}
              onClick={handleMonthsAutoSelect}
            />
            <ShortcutButton
              startDate={startDate}
              endDate={endDate}
              text="Last 3 Months"
              months={3}
              onClick={handleMonthsAutoSelect}
            />
            <ShortcutButton
              startDate={startDate}
              endDate={endDate}
              text="Last 12 Months"
              months={12}
              onClick={handleMonthsAutoSelect}
            />
          </div>
        )}
        <ReactCalendar
          id={id}
          showDoubleView={showDoubleView}
          value={[toDate(startDate, dateFormat), toDate(endDate, dateFormat)]}
          clearIcon={null}
          onChange={(value) => handleOnChange(value)}
          placeholder="Date"
          showNeighboringMonth={false}
          returnValue="range"
          selectRange={selectRange}
          {...props}
          {...(view !== null && { view })}
          {...(defaultView !== null && { defaultView })}
          {...(view === 'year' && { onClickMonth: (value) => handleOnClickMonth(value) })}
        />
      </div>
    </div>
  );
};

export default DefaultDatePicker;
