import { paramsForServer } from 'feathers-hooks-common';

import feathers from './feathersClient';

const query = (search) => {
  if (typeof search !== 'string') {
    return null;
  }

  return {
    query: {
      $or: [
        {
          firstName: {
            $like: `%${search}%`,
          },
        },
        {
          lastName: {
            $like: `%${search}%`,
          },
        },
      ],
    },
  };
};

export const find = (search) => feathers().service('users').find(query(search));
export const get = (id, params) => feathers().service('users').get(id, paramsForServer(params));
export const createUser = (payload) => feathers().service('users').create(payload);
export const patch = (id, payload) => feathers().service('users').patch(id, payload);
export const remove = (id) => feathers().service('users').remove(id);
