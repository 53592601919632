import React from 'react';
import { datadogRum } from '@datadog/browser-rum';

export default class LogOnlyErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // TODO: move to unified core function.
    console.error(error, errorInfo);
    datadogRum.addError(error, undefined, 'source');
  }

  render() {
    const { children } = this.props;
    return this.state.hasError ? null : children;
  }
}
