import classNames from 'classnames';

import Icon from '../Icon/Icon';

import styles from './IconLabel.module.css';

const IconLabel = ({
  hideTextOnXS = false,
  icon,
  label,
  outlined = false,
  iconOutlined,
  className,
  iconClassName,
  labelClassName,
  children,
  iconPosition = 'left',
  ...passThroughProps
}) => {
  const outlineIcon = iconOutlined ?? outlined;
  return (
    <span
      className={`d-flex align-items-center ${className}`}
      data-fx-name={passThroughProps['data-fx-name']}
    >
      {icon && iconPosition === 'left' && (
        <Icon className={classNames('mr-1', styles.icon, iconClassName)} outlined={outlineIcon}>
          {icon}
        </Icon>
      )}
      <span
        className={classNames(labelClassName, styles.label, { 'd-none d-lg-inline': hideTextOnXS })}
        data-fx-name="label"
      >
        {label}
      </span>
      {icon && iconPosition === 'right' && (
        <Icon className={classNames(iconClassName, styles.icon)} outlined={outlineIcon}>
          {icon}
        </Icon>
      )}
      {children}
    </span>
  );
};

export default IconLabel;
