import { computed } from 'easy-peasy';
import get from 'lodash/get';
export const itemsAsOptions = (valueProp, labelProp) => ({
  itemsAsOptions: computed((state) => {
    if (!state.items) {
      return [];
    }
    return state.items
      .filter((i) => !!get(i, valueProp, null) && !!get(i, labelProp, null))
      .map((i) => ({ value: get(i, valueProp, null), label: get(i, labelProp, null) }));
  }),
});

export default itemsAsOptions;
