import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import qs from 'qs';
import { createContextStore, computed, actionOn, action, thunk } from 'easy-peasy';
import { useDidUpdateEffect } from '@webfx/web-hooks';
import hash from '../utils/hash';

function getSchemaHash(schema) {
  return hash({ ...(!schema ? {} : schema.describe()), s: encodeURIComponent(location.search) });
}

const QueryParamsContext = createContextStore((runtimeModel) => {
  return {
    schemaCache: {},
    queryParams: qs.parse(location.search, { ignoreQueryPrefix: true }),

    fetchItem: computed(
      [(state) => state.schemaCache, (state) => state.queryParams],
      (cache, queryParams) => {
        return (schema) => {
          const schemaHash = hash({
            ...(!schema ? {} : schema.describe()),
            s: encodeURIComponent(location.search),
          });

          if (!cache[schemaHash]) {
            return schema?.cast(queryParams) || queryParams;
          }

          return cache[schemaHash].params;
        };
      }
    ),

    subscribe: action((state, schema) => {
      const schemaHash = getSchemaHash(schema);

      if (!state.schemaCache[schemaHash]) {
        state.schemaCache[schemaHash] = {
          subscribersCount: 0,
        };
      }

      state.schemaCache[schemaHash].params = schema?.cast(state.queryParams) || state.queryParams;
      state.schemaCache[schemaHash].schema = schema;
      state.schemaCache[schemaHash].subscribersCount++;
    }),

    unsubscribe: action((state, schema) => {
      const schemaHash = getSchemaHash(schema);

      if (!state.schemaCache[schemaHash]) {
        return;
      }

      state.schemaCache[schemaHash].subscribersCount--;

      if (state.schemaCache[schemaHash]?.subscribersCount === 0) {
        delete state.schemaCache[schemaHash];
      }
    }),

    updateQueryParams: thunk(
      (actions, { params, replaceState = false, replaceSearch = true } = {}, { getState }) => {
        const { queryParams } = getState();

        if (replaceState) {
          actions.setQueryParams(params);

          runtimeModel.history.push({
            search: qs.stringify(params, { encode: false }),
          });

          return;
        }

        const newParams = {
          ...queryParams,
          ...params,
        };

        actions.setQueryParams(newParams);

        if (replaceSearch) {
          runtimeModel.history.push({
            search: qs.stringify(newParams, { encode: false }),
          });
        }
      }
    ),

    setQueryParams: action((state, payload) => {
      state.queryParams = payload;
    }),

    rebuild: actionOn(
      (actions) => actions.setQueryParams,
      (state) => {
        Object.entries(state.schemaCache).forEach(([, object]) => {
          object.params = object.schema?.cast(state.queryParams) || state.queryParams;
        });
      }
    ),
  };
});

/**
 * Hook to handle query params.
 * It support casting url param values using a simple schema.
 *
 * Schema Format
 * The schema is a json object where the values are the cast type.
 * If an array is passed, the second args will be the default value if no query param is found for that key.
 *
 * Currently it supports these type castes
 *
 * - string: Cast to string (default).
 * - number: Cast to integer.
 * - boolean: Cast to boolean.
 * - array[TYPE]: Cast an array to a desired type.
 * - [object]: Casts a object using a sub schema.
 * - [function]: Custom caster which receives the value to be casted. It should return an object.
 *
 * Ex:
 * {
 *   fieldA: 'number',
 *   fieldB: ['string', 'my_default_value']
 * }
 *
 * Usage example:
 *
 const { params, setParams, rawParams, queryParams } = useQueryParams({
    view: [(val) => {
      const mapper = {
        starred: { starred: true },
        new: { status: 'Unchanged' }
      };
      return mapper[val];
    }, 'overview'],
    status: ['string', 'Lead'],
    tagId: 'array[number]',
    assigneeUserId: 'number',
    ownerUserId: 'number',
  });
 *
 * Return values
 * - params: Object with the computed values using the schema.
 *   This object is the we would want to pass in a service query params.
 *   ex: ?view=started&tagId[]=1&tagId[]=2&assigneeUserId=1
 *       -> { starred: true, tagId: [1,2], assigneeUserId: 2 }
 *
 * - rawParams: Object using default values without being computed by the schema.
 *   Useful when we want to compare a param casted by a function inside the UI.
 *   ex: ?view=started&tagId[]=1&tagId[]=2&assigneeUserId=2
 *       // { view: 'overview', tagId: ['1', '2'], assigneeUserId: "2"  }
 *
 * - setParams(params, options): mutator function to update query params.
 *   By default it will merge values.
 *   Options: {
 *     replaceState: false, // Replaces the whole param.
 *     replaceSearch: true  // Update the search query on the url
 *   }
 *
 * - paramsState: Object with return exact params from the current search,
 *   ignoring any schema value. Rarely used.
 *
 * @param schema
 * @returns {{paramsState: *, params: *, rawParams: *, setParams: *}}
 */
export const useQueryParams = (schema = null) => {
  const { subscribe, unsubscribe, updateQueryParams } = QueryParamsContext.useStoreActions(
    (actions) => actions
  );
  const { fetchItem, queryParams } = QueryParamsContext.useStoreState((state) => state);

  useEffect(() => {
    subscribe(schema);
    return () => {
      unsubscribe(schema);
    };
  }, [schema]);

  const castedParams = fetchItem(schema) || {};

  const setParamsState = (params, { replaceState = false, replaceSearch = true } = {}) => {
    const payload = {
      params,
      replaceState,
      replaceSearch,
    };

    updateQueryParams(payload);
  };

  return [castedParams, setParamsState, { queryParams }];
};

const QueryParams = ({ children }) => {
  const { updateQueryParams } = QueryParamsContext.useStoreActions((actions) => actions);

  useDidUpdateEffect(() => {
    updateQueryParams({
      params: qs.parse(location.search, { ignoreQueryPrefix: true }),
      replaceState: true,
    });
  }, [location.search]);

  return children;
};

const QueryParamsProvider = ({ children }) => {
  const history = useHistory();

  return (
    <QueryParamsContext.Provider runtimeModel={{ history }}>
      <QueryParams>{children}</QueryParams>
    </QueryParamsContext.Provider>
  );
};

export default QueryParamsProvider;
