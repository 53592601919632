import React from 'react';

const Image = ({ src, srcSet, ...passThroughProps }) => {
  if (!src) {
    return null;
  }

  // split incoming src
  const pathParts = src.split('.');
  const ext = pathParts.pop();
  const imagePath = pathParts.join('.');

  // if pass srcSet as true without its own definition default to 2x and 3x
  if (srcSet === true && src) {
    srcSet = `${imagePath}@2x.${ext} 2x, ${imagePath}@3x.${ext} 3x`;
  }

  return <img src={src} srcSet={srcSet} {...passThroughProps} />;
};

export default Image;
