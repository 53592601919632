import React from 'react';
import { DateRangePicker as ReactDateRangePicker } from 'react-dates';
import moment from 'moment';

const DateRangePicker = ({ startDate, endDate, ...props }) => {
  // Hot patch while we remove this for the other date picker
  if (typeof startDate === 'string') {
    startDate = moment(startDate);
  }
  if (typeof endDate === 'string') {
    endDate = moment(endDate);
  }

  return <ReactDateRangePicker {...props} startDate={startDate} endDate={endDate} />;
};

export default DateRangePicker;
