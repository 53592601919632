import React from 'react';
import PropTypes from 'prop-types';
import FusionCharts from 'fusioncharts';
import ReactFC from 'react-fusioncharts';
import Maps from 'fusioncharts/fusioncharts.maps';
import Charts from 'fusioncharts/fusioncharts.charts';
import Widgets from 'fusioncharts/fusioncharts.widgets';
import USA from 'fusioncharts/maps/es/fusioncharts.usa';
import World from 'fusioncharts/maps/es/fusioncharts.world';
import TimeSeries from 'fusioncharts/fusioncharts.timeseries';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import TreeMap from 'fusioncharts/fusioncharts.treemap';

// import license key
import { FUSION_CHART_LICENSE_KEY } from '../../config/app';

// load license key
FusionCharts.options.license({
  key: FUSION_CHART_LICENSE_KEY,
  creditLabel: false,
});

// add root assets
ReactFC.fcRoot(FusionCharts, Charts, TimeSeries, Widgets, Maps, World, USA, FusionTheme, TreeMap);

// component
const FusionChart = React.forwardRef(
  (
    {
      type,
      events = {},
      width = '100%',
      height = '320',
      dataFormat = 'json',
      chart = {},
      ...chartConfigs
    },
    ref
  ) => {
    // eslint-disable-next-line no-param-reassign
    chart.theme = 'fusion';
    return (
      <ReactFC
        ref={ref}
        type={type}
        width={width}
        height={height}
        dataFormat={dataFormat}
        dataSource={{
          chart,
          ...chartConfigs,
        }}
        events={events}
      />
    );
  }
);

FusionChart.propTypes = {
  type: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.object,
  dataFormat: PropTypes.string,
};

export default FusionChart;
