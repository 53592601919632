import React from 'react';
import { Icon } from '@webfx/core-web';
import { OverlayTrigger, Popover as Overlay } from 'react-bootstrap';
import classNames from 'classnames';
import styles from './Popover.module.css';

/**
 *
 * @param {object} root0
 * @param {React.children} root0.children
 * @param {React.ReactElement} root0.content
 * @param {boolean} root0.darkMode
 * @param {string} root0.triggerText
 * @param {string} root0.trigger
 * @param {boolean} root0.withHelpIcon
 * @param {string} root0.show
 * @returns {React.ReactElement}
 */
const Popover = ({
  children,
  content,
  darkMode = false,
  triggerText,
  trigger = ['hover', 'focus'],
  triggerClassName,
  withHelpIcon = true,
  show,
  ...props
}) => {
  return (
    <OverlayTrigger
      trigger={trigger}
      rootClose
      {...(show && { show })}
      overlay={
        <Overlay
          className={classNames(styles.popover, { [styles.darkMode]: darkMode })}
          arrowProps={{
            className: styles.popover,
          }}
        >
          <Overlay.Content>
            {withHelpIcon && <Icon className={'text-primary font-24'}>help</Icon>}
            <div>{content}</div>
          </Overlay.Content>
        </Overlay>
      }
      {...props}
    >
      {triggerText ? (
        <span
          className={classNames('font-13 text-blue-vivid-300 cursor-pointer', triggerClassName)}
        >
          {triggerText}
        </span>
      ) : (
        children
      )}
    </OverlayTrigger>
  );
};

export default Popover;
