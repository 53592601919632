import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Linkify from 'linkify-react';
import classnames from 'classnames';

import styles from './WebAddress.module.css';

const Link = ({ options, address, ...passThroughProps }) => {
  options.attributes = {
    ...options.attributes,
    ...passThroughProps,
  };

  return <Linkify options={options}>{address}</Linkify>;
};

const WebAddress = ({ address, className, attributes, onClick, tooltip, ...passThroughProps }) => {
  const options = {
    defaultProtocol: 'https',
    className: classnames([className, styles.webAddress]),
    target: {
      url: '_blank',
    },
    attributes: {
      ...attributes,
      onClick,
    },
    ...passThroughProps,
  };

  if (!tooltip) {
    return <Link options={options} address={address} />;
  }

  return (
    <OverlayTrigger placement="top" overlay={<Tooltip>{tooltip}</Tooltip>}>
      <Link options={options} address={address} />
    </OverlayTrigger>
  );
};

export default WebAddress;
