import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';

export default function dirtyProps(originalValues, values, whiteList = [], blacklist = []) {
  // add all dirty values that are different from original
  const dirty = Object.keys(omit(originalValues, blacklist)).reduce((dirty, key) => {
    if (values[key] === undefined) {
      return dirty;
    }
    if (!isEqual(originalValues[key], values[key]) || whiteList.includes(key)) {
      dirty[key] = values[key];
    }

    return dirty;
  }, {});

  // add keys from values that don't exist in original
  return Object.keys(omit(values, blacklist)).reduce((newValues, key) => {
    if (originalValues[key] === undefined) {
      newValues[key] = values[key];
    }
    return newValues;
  }, dirty);
}
