import { useState } from 'react';
import classnames from 'classnames';

import List from '../List/List';
import Link from '../Link/Link';
import Icon from '../Icon/Icon';
import Typography from '../Typography/Typography';
import Button from '../Button/Button';

import styles from './LinkList.module.css';

const LinkList = ({
  links,
  className,
  showIcon = true,
  icon = 'trending_flat',
  directionVariant = 'column',
  gutterBottom,
  rightSpace,
  uppercase,
  hideFirstBullet = true,
  textColor,
  showMax,
}) => {
  const [maxDisplayed, setMaxDisplayed] = useState(showMax);

  return (
    <List className={classnames(styles[directionVariant], className)}>
      {links.reduce((result, { link, name, subtitle }, index) => {
        if (maxDisplayed < index) {
          return result;
        }
        return [
          ...result,
          <li
            key={link || 'more'}
            className={classnames({
              [styles.gutterBottom]: gutterBottom,
              [styles.rightSpace]: rightSpace,
              [styles.uppercase]: uppercase,
              [styles.hideFirstBullet]: hideFirstBullet,
            })}
          >
            {(maxDisplayed === index && (
              <Button
                text={`Show more (${links.length - index})`}
                variant="link"
                onClick={() => setMaxDisplayed(maxDisplayed + showMax)}
              />
            )) || (
              <Link to={link} className={styles.linkItem}>
                <div>
                  <Typography bold className={`${textColor} ${subtitle && 'mb-0'}`}>
                    {name}
                  </Typography>
                  {subtitle && (
                    <Typography className="mb-2" variant="subtitle3" underline>
                      {subtitle}
                    </Typography>
                  )}
                </div>
                {showIcon && <Icon className={styles.iconColor}>{icon}</Icon>}
              </Link>
            )}
          </li>,
        ];
      }, [])}
    </List>
  );
};

export default LinkList;
