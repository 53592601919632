import classnames from 'classnames';
import { useField } from 'formik';
import React, { useState } from 'react';

import './Input.style.css';
import InputGroup from './InputGroup/InputGroup';

const Input = React.forwardRef(
  (
    {
      label,
      labelInfo,
      labelPosition = 'top',
      labelInfoPosition = 'top',
      labelInfoClassName,

      labelClassName,
      inputGroupTextLeft = null,
      inputGroupTextRight = null,

      Component = 'input',
      name,
      id,
      value,
      initialValue,
      type = 'text',
      placeholder,
      disabled = false,
      readOnly = false,
      error,
      errors,
      errorVisible = true,
      touched = false,
      initialError,
      initialTouched,
      inputClassName,

      onBlur,
      onFocus,

      icon,
      iconPosition,
      iconOutined = false,
      iconTooltip = null,

      clearable,
      onClear,
      clickableIcon,
      onIconClick,

      variant,

      className,
      inlineEdit,
      ...passThroughProps
    },
    ref
  ) => {
    const [focused, setFocused] = useState(false);
    const [iconActive, setIconActive] = useState(false);
    const embedLabel = labelPosition === 'embed';

    if (!id) {
      id = name;
    }

    className = classnames([className, { 'inline-edit': inlineEdit }]);

    if (inlineEdit) {
      if (!icon) {
        icon = 'edit';
      }
      if (!iconPosition) {
        iconPosition = 'right';
      }
    }

    const doOnBlur = (event) => {
      setFocused(false);
      if (onBlur) {
        onBlur(event, false);
      }
    };

    const doOnFocus = (event) => {
      setFocused(true);
      if (onFocus) {
        onFocus(event, true);
      }
    };

    const doOnIconClick = (event) => {
      if (inlineEdit && ref.current) {
        ref.current.focus();
      }
      if (onIconClick) {
        setIconActive(!iconActive);
        onIconClick(event, !iconActive);
      }
    };

    const groupProps = {
      label,
      labelInfo,
      labelPosition,
      labelInfoPosition,
      labelInfoClassName,
      labelClassName,
      inputGroupTextLeft,
      inputGroupTextRight,
      value,
      icon,
      iconPosition,
      iconOutined,
      onIconClick: onIconClick ? doOnIconClick : null,
      iconActive,
      iconTooltip,
      clearable,
      onClear,
      error,
      errors,
      errorVisible,
      touched,
      focused,
      className,
      variant,
    };
    return (
      <InputGroup {...groupProps} data-fx-name="inputGroup">
        <Component
          id={id}
          ref={ref}
          className={classnames(inputClassName, 'form-control', {
            [`form-control-${variant}`]: variant,
            'embed-label-control': embedLabel,
            'is-invalid': error && touched,
            'has-icon-left': icon && iconPosition === 'left',
            'has-icon-right': (icon && iconPosition === 'right') || clearable,
          })}
          type={type === 'password' && iconActive ? 'text' : type}
          name={name}
          placeholder={!embedLabel ? placeholder : ''}
          onBlur={doOnBlur}
          onFocus={doOnFocus}
          disabled={disabled}
          readOnly={readOnly}
          value={initialValue && !value && value !== '' ? initialValue : value}
          {...passThroughProps}
        />
      </InputGroup>
    );
  }
);

export default Input;

export const FormikInput = ({ name, field: formikField, onChange, ...passThroughProps }) => {
  const fieldName = name || formikField.name;
  const [field, meta] = useField(fieldName);

  return (
    <Input
      {...field}
      {...meta}
      {...passThroughProps}
      onChange={(e) => {
        if (field.onChange) {
          field.onChange(e);
        }
        if (onChange) {
          onChange(e);
        }
      }}
    />
  );
};
