import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import classnames from 'classnames';

import './Link.style.css';

const Link = React.forwardRef(
  (
    {
      children,
      className,
      to,
      href,
      onMouseOver,
      onMouseOut,
      onClick,
      plain,
      uppercase,
      bold,
      external = true,
      ['data-fx-name']: dataFxName
    },
    ref
  ) => {
    if (!to && href) {
      to = href;
    }

    if (plain) {
      return (
        <a
          ref={ref}
          href={to}
          target={external ? '_blank' : ''}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          onClick={onClick}
          className={classnames('link', className, {
            uppercase,
            bold,
          })}
          rel="noreferrer"
          data-fx-name={dataFxName}
        >
          {children}
        </a>
      );
    }

    return (
      <RouterLink
        ref={ref}
        to={to}
        className={classnames('link', className, {
          uppercase,
          bold,
        })}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onClick={onClick}
        data-fx-name={dataFxName}
      >
        {children}
      </RouterLink>
    );
  }
);

export default Link;
