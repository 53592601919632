import { thunk, action } from 'easy-peasy';

import feathers from '../services/feathersClient';

const spreadsheets = {
  data: [],
  loading: false,

  fetched: action((state, payload) => {
    state.data = payload;
  }),

  clear: action((state) => {
    state.data = [];
  }),

  setLoading: action((state, loading) => {
    state.loading = loading;
  }),

  fetch: thunk(async (actions, payload = {}) => {
    actions.setLoading(true);
    const { query = {}, ...params } = payload;

    const data = await feathers()
      .service('spreadsheets')
      .find({
        query,
        ...params,
      });
    actions.fetched(data);
    actions.setLoading(false);
  }),
};

export default spreadsheets;
