import { datadogRum } from '@datadog/browser-rum';

const logError = (error, context, source = 'source') => {
  console.error(error);

  if (error instanceof Error) {
    datadogRum.addError(error, context, source);
  }
};

export default logError;
