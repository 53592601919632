import React from 'react';
import classnames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import styles from './EmailAddress.module.css';

const Link = ({ email, className, ...passThroughProps }) => (
  <a
    href={`mailto:${email}`}
    className={classnames([styles.emailAddress, className])}
    {...passThroughProps}
  >
    {email}
  </a>
);

const EmailAddress = ({ email, tooltip, className, ...passThroughProps }) => {
  if (!tooltip) {
    return <Link email={email} className={className} {...passThroughProps} />;
  }

  return (
    <OverlayTrigger placement="top" overlay={<Tooltip>{tooltip}</Tooltip>}>
      <Link email={email} className={className} {...passThroughProps} />
    </OverlayTrigger>
  );
};

export default EmailAddress;
