import React from 'react';
import classnames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getUserTooltip } from '@webfx/teamwork-web/src/ui/components/UserOverlayTooltip';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { useUserRoles, useRouter } from '@webfx/web-hooks';

import Avatar from '../Avatar/Avatar';
import { Card, CardTitle, CardInfo, CardEditLink, CardSubTitle } from '../Card';
import PhoneNumber from '../PhoneNumber/PhoneNumber';
import EmailAddress from '../EmailAddress/EmailAddress';
import Checkbox from '../Checkbox/Checkbox';
import Icon from '../Icon';
import Svg from '../Svg/Svg';
import iconNotificationAlertSvg from '../../assets/images/icon-notification-alert.svg';
import styles from './UserCard.module.css';

/**
 *
 * @param {object }root0
 * @param {object} root0.user
 * @param {boolean} root0.editable
 * @param {string} root0.className
 * @param {React.children} root0.children
 * @param {boolean} root0.showToggles
 * @returns {React.ReactElement}
 */
const UserCard = ({
  showToggles,
  companyUser,
  editable,
  className,
  children,
  extendedTooltip = false,
}) => {
  const { url } = useRouter();
  const { onMutate, roles } = useUserRoles();
  const user = companyUser?.user;
  if (!user) {
    return null;
  }

  const { userId, avatarUrl, firstName, lastName, title, officeNumber, email, lastActiveAt } = user;
  const tooltip = extendedTooltip ? getUserTooltip(user) : `${firstName} ${lastName}`;
  const isActive = companyUser?.active;
  const { lastActiveUpdatedByUser, lastActiveUpdatedAt, lastReportSent } = companyUser;
  const { firstName: updatedByFirstName, lastName: updatedByLastName } =
    lastActiveUpdatedByUser || {};
  let lastActiveUpdatedByUserName = null;
  if (lastActiveUpdatedByUser) {
    lastActiveUpdatedByUserName = `${updatedByFirstName} ${updatedByLastName}`;
  }

  let lastActiveUpdatedAtDate = null;
  if (lastActiveUpdatedAt) {
    lastActiveUpdatedAtDate = DateTime.fromISO(lastActiveUpdatedAt).toFormat('MMM dd, yyyy');
  }
  const fullName = _.startCase(_.toLower(`${firstName} ${lastName}`));

  return (
    <Card
      className={classnames(
        {
          [styles.inactive]: !isActive,
        },
        className
      )}
      data-fx-name="userCard"
    >
      <Card.Body className="position-relative">
        {editable ? (
          <div
            data-fx-name="edit"
            className="position-absolute"
            style={{
              inset: '10px 10px 0 auto',
            }}
          >
            <CardEditLink
              text={<Icon outlined className="font-16 text-primary-light-gray" type="edit" />}
              to={url({ route: 'editUser', id: userId })}
            />
          </div>
        ) : null}
        <div className="d-flex">
          <Avatar
            src={avatarUrl}
            tooltip={tooltip}
            alt={`${fullName}'s Avatar`}
            data-fx-name="avatar"
          />
          <CardInfo className="ml-3" data-fx-name="cardInfo">
            {firstName || lastName ? (
              <div data-fx-name="fullName">
                <CardTitle className={'font-14 font-weight-bold'} text={fullName} />
              </div>
            ) : null}

            {title ? <CardSubTitle className={'font-11'} text={title} /> : null}
            {officeNumber ? <PhoneNumber text={officeNumber} /> : null}
            <div className={styles.emailRow}>
              {email ? (
                <EmailAddress className={'font-13'} email={email} data-fx-name="emailAddress" />
              ) : null}
              {!companyUser?.hasDigestReport ? (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip data-fx-name="tooltip">
                      Not receiving any MarketingCloudFX digest emails (leads/companies/calls)
                    </Tooltip>
                  }
                >
                  <Svg
                    src={iconNotificationAlertSvg}
                    title={'Not receiving digest reports'}
                    className={styles.iconNotif}
                    data-fx-name="monthlyReportIcon"
                  />
                </OverlayTrigger>
              ) : null}
            </div>

            {lastActiveAt ? (
              <div className="font-10 text-primary-light-gray" data-fx-name="lastActive">
                Last Login:{' '}
                {new Date(lastActiveAt).toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                })}
              </div>
            ) : null}

            {lastReportSent ? (
              <div className="font-10 text-primary-light-gray">
                Last Report Sent:{' '}
                {new Date(lastReportSent).toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                })}
              </div>
            ) : null}
            {children}
          </CardInfo>
        </div>
        <CardInfo>
          {showToggles ? (
            <>
              <div className="border-top my-2 py-2 border-primary-light-gray">
                {roles.map(({ name, key }) => {
                  return (
                    <Checkbox
                      id={`user-${userId}-${key}`}
                      key={`user-${userId}-${key}`}
                      checked={companyUser[key]}
                      round
                      onChange={() => onMutate(key, companyUser)}
                      size="md"
                      disabled={!isActive}
                      name={name}
                      label={name}
                      labelClassname="font-13 mb-1"
                      wrapperClassname="pl-0"
                      data-fx-name={_.camelCase(name)}
                    />
                  );
                })}
              </div>
            </>
          ) : null}
          <hr className="mb-2 mt-4" />
          <div className="d-flex justify-content-between align-items-center">
            <div
              className={classnames([
                'text-gray-550 font-14',
                {
                  'ml-5': !showToggles,
                },
              ])}
            >
              {lastActiveUpdatedByUserName ? (
                <div data-fx-name="updatedByName">
                  {isActive ? 'Added' : 'Deactivated'} by:{' '}
                  <span className={classnames(['font-weight-bold'])}>
                    {_.startCase(_.toLower(lastActiveUpdatedByUserName))}
                  </span>
                </div>
              ) : null}
              {lastActiveUpdatedAtDate ? (
                <div className={classnames(['font-12'])} data-fx-name="updatedOn">
                  on {lastActiveUpdatedAtDate}
                </div>
              ) : null}
            </div>
            {showToggles ? (
              <div className="pt-3">
                <Checkbox
                  id={`user-${userId}-active`}
                  key={`user-${userId}-active`}
                  checked={isActive}
                  onChange={() => onMutate('active', companyUser)}
                  customSwitch
                  size="sm"
                  name="Active"
                  label={isActive ? 'Active' : 'Inactive'}
                  data-fx-name={_.camelCase(isActive ? 'Active' : 'Inactive')}
                />
              </div>
            ) : null}
          </div>
        </CardInfo>
      </Card.Body>
    </Card>
  );
};

export default UserCard;
