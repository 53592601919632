import React from 'react';
import ErrorPage from '../ErrorPage/ErrorPage';

const GeneralErrorPage = () => (
  <ErrorPage title={'Oops...'} subtitle={'Something went wrong when loading the page.'}>
    <div className="mt-auto pb-5">
      <hr className="mt-5 mb-3 p-0 mx-0" style={{ width: '50px', borderColor: '#000' }} />

      <p>
        Please report this error to our
        <br />
        product development team here <a href={'mailto:app@webfx.com'}>app@webfx.com</a>.
      </p>
    </div>
  </ErrorPage>
);

export default GeneralErrorPage;
