import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

/**
 * Converts object to query string
 *
 * @param params
 * @returns {string}
 */
const objectToQuery = (params) => {
  const esc = encodeURIComponent;
  return Object.keys(pickBy(params, identity))
    .map((k) => esc(k) + '=' + esc(params[k]))
    .join('&');
};

export default objectToQuery;
