import PropTypes from 'prop-types';
import React from 'react';

import Link from '../Link/Link';
import styles from './Logo.module.css';
const logo = '/assets/images/logos/webfx-light';

/**
 * Renders a logo with a link to a specified URL.
 *
 * @param {object} props - The component props.
 * @param {string} props.linkTo - The URL to link to.
 * @param {string} props.linkClassName - The class name for the link.
 * @param {string} props.imgSrc - The source URL for the image.
 * @param {string} props.imgSrcSet - The source set for the image.
 * @param {string} props.imgAlt - The alt text for the image.
 * @param {string} props.imgClassName - The class name for the image.
 * @param {Function} props.onClick - The function to call when the image is clicked.
 * @param {string} props.title - The title(tooltip) text for the image.
 * @returns {Element} - The rendered logo component.
 */
const Logo = ({
  linkTo,
  linkClassName,
  imgSrc,
  imgSrcSet,
  imgAlt,
  imgClassName,
  onClick,
  title,
}) => (
  <Link to={linkTo} className={`${styles.logo} ${linkClassName}`}>
    <img
      onClick={onClick}
      src={imgSrc}
      srcSet={imgSrcSet}
      alt={imgAlt}
      className={`${styles.img} ${imgClassName}`}
      title={title}
    />
  </Link>
);

Logo.propTypes = {
  linkProps: PropTypes.shape({
    to: PropTypes.string,
    className: PropTypes.string,
  }),
  imageProps: PropTypes.shape({
    src: PropTypes.string,
    srcSet: PropTypes.string,
    alt: PropTypes.string,
    className: PropTypes.string,
  }),
};

Logo.defaultProps = {
  linkTo: '/',
  linkClassName: '',
  imgSrc: logo + '.png',
  imgSrcSet: logo + '@2x.png 2x, ' + logo + '@3x.png 3x',
  imgAlt: 'WebFX',
  imgClassName: '',
};

export default Logo;
