import React, { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { Alert } from 'react-bootstrap';

function DisplayError({ error }) {
  useEffect(() => {
    datadogRum.addError(error, undefined, 'source');
  }, []);

  return (
    <Alert variant="danger">
      <Alert.Heading>Oops something went wrong</Alert.Heading>
      <p>Sorry we are not able to display this content for you at this time</p>
    </Alert>
  );
}

export default DisplayError;
