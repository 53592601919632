import { useMemo } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import classnames from 'classnames';
import { If, Then } from 'react-if';

import InputLabel from '../Input/InputLabel/InputLabel';
import InputLabelInfo from '../Input/InputLabelInfo/InputLabelInfo';
import { IndicatorSeparator, changeTheme, customStyles } from './selectStyles';

import './SelectInput.styles.css';

// TODO: Component still needs cleanup and refactor to more closely resemble Input component
// Props should be consistent for Formik integration and create abstractions around React Select
const SelectInput = ({
  field = {},
  form = {},

  className,
  classNamePrefix,

  placeholder = false,

  label,
  labelClassName,
  labelInfo,
  tooltip,
  labelInfoPosition = 'top',

  options = [],
  optionLabel = '',
  optionValue = '',

  creatable = false,
  async = false,
  allowCustomValue = false, // allow to show value as default event when value isn't inside options, useful for edits.
  isDisabled = false, // isDisabled? For consistency (we can use any of two: isDisabled or disabled)
  disabled = false,
  isSearchable,

  cacheOptions,
  defaultOptions,
  loadOptions,

  clearable, // isClearable? For consistency (we can use any of two: isClearable or clearable)
  isClearable,
  onBlur,
  onFocus,

  menuPlacement = 'bottom',
  defaultValue,

  ...passThroughProps
}) => {
  const { name = '', value = null } = field;
  const { setFieldValue = () => {} } = form;

  let selectValue = useMemo(() => {
    if (value === null || value === '') {
      return '';
    } else {
      // Flatten the options array
      const flattenedOptions = options.reduce((acc, optionGroup) => {
        if (optionGroup.options) {
          return [...acc, ...optionGroup.options];
        } else {
          return [...acc, optionGroup];
        }
      }, []);
      // Find the selected value in the flattened array
      return flattenedOptions.find((option) => option.value === value);
    }
  }, [value, options]);

  if (value && (allowCustomValue || creatable)) {
    if (optionLabel && optionValue) {
      selectValue = { label: value[optionLabel], value: value[optionValue] };
    } else {
      selectValue = { label: value, value };
    }
  }

  const selectProps = {
    name,
    options,
    menuPlacement,
    defaultValue,
    menuShouldScrollIntoView: true,
    onChange: (option) => {
      let value = null;
      if (option) {
        value = option.value;
        if (optionLabel) {
          value = option[optionLabel];
        }
      }
      setFieldValue(name, value);
    },
    theme: changeTheme,
    value: selectValue,
    styles: customStyles,
    placeholder,
    components: { IndicatorSeparator },
    classNamePrefix: classnames('select-input', classNamePrefix),
    isDisabled: disabled || isDisabled,
    isSearchable,
    isClearable: clearable || isClearable,
    cacheOptions,
    defaultOptions,
    loadOptions,
    onBlur,
    onFocus,
    ...passThroughProps,
  };

  return (
    <div className={classnames('select-input', className)} data-fx-name="selectInput">
      {label && (
        <InputLabel
          className={classnames('form-label', labelClassName)}
          htmlFor={name}
          text={label}
          tooltip={tooltip}
        />
      )}

      <If condition={!!labelInfo && labelInfoPosition === 'top'}>
        <Then>
          <InputLabelInfo text={labelInfo} />
        </Then>
      </If>

      {!creatable && !async && <Select {...selectProps} menuPosition={'absolute'} />}

      {!creatable && async && <AsyncSelect {...selectProps} />}

      {creatable && <CreatableSelect placeholder={placeholder || 'test'} {...selectProps} />}

      <If condition={!!labelInfo && labelInfoPosition === 'bottom'}>
        <Then>
          <InputLabelInfo text={labelInfo} className={'mb-0'} />
        </Then>
      </If>
    </div>
  );
};

export default SelectInput;
