import { action, thunk, computed } from 'easy-peasy';
import isEmpty from 'lodash/isEmpty';
import downloadFile from '../utils/downloadFile';
import feathers from '../services/feathersClient';
import { useNotifications } from '@webfx/web-hooks';

const downloads = {
  downloads: computed((state) => Object.values(state.files)),
  files: {},
  pollers: {},

  setFile: action((state, payload) => {
    state.files[payload.id] = payload;
  }),

  setPoller: action((state, payload) => {
    state.pollers[payload.id] = payload.poller;
  }),

  deleteFile: action((state, jobId) => {
    if (!isEmpty(state.files) && state.files[jobId]) {
      delete state.files[jobId];
    }
  }),

  deletePoller: action((state, jobId) => {
    if (!isEmpty(state.pollers) && state.pollers[jobId]) {
      clearInterval(state.pollers[jobId]);
      delete state.pollers[jobId];
    }
  }),

  deleteCsfxJobItem: action((state, jobId, actions = null) => {
    const existingData = localStorage.getItem('csfxCurrentPdfJob');
    if (!isEmpty(existingData)) {
      const entries = JSON.parse(existingData);
      if (!isEmpty(entries)) {
        const results = entries.filter((value) => parseInt(value.jobId) !== parseInt(jobId));
        if (!isEmpty(results)) {
          localStorage.setItem('csfxCurrentPdfJob', JSON.stringify(results));
          return;
        }
      }
    }

    localStorage.removeItem('csfxCurrentPdfJob');
    if (actions) {
      actions.clearAllDownloads();
    }
  }),

  deleteC360JobItem: action((state, jobId, actions = null) => {
    const existingData = localStorage.getItem('c360CurrentJobs');

    if (!isEmpty(existingData)) {
      const entries = JSON.parse(existingData);
      if (!isEmpty(entries)) {
        const results = entries.filter((value) => parseInt(value.jobId) !== parseInt(jobId));
        if (!isEmpty(results)) {
          localStorage.setItem('c360CurrentJobs', JSON.stringify(results));
          return;
        }
      }
    }

    localStorage.removeItem('c360CurrentJobs');
    if (actions) {
      actions.clearAllDownloads();
    }
  }),

  downloadCsfxExport: thunk(async (actions, payload, { getStoreState }) => {
    const { toast } = useNotifications();
    const {
      config: { config: getConfig },
    } = getStoreState();

    const fileInfo = {
      id: payload.jobId,
      filename: payload.name,
      queue: payload.queue,
      progress: 0,
      extension: payload.extension,
    };

    actions.setFile(fileInfo);

    const pollerInterval = setInterval(async () => {
      try {
        const { progress, ...rest } = await feathers()
          .service('queues')
          .get(payload.jobId, { query: { queue: payload.queue } });

        // find existing item and if none
        // clear local store and clear view
        let hasItem = false;
        const existingData = localStorage.getItem('csfxCurrentPdfJob');
        if (existingData) {
          const findItem = JSON.parse(existingData).filter(
            (v) => parseInt(payload.jobId) === parseInt(v.jobId)
          );
          hasItem = findItem.length > 0;
        } else {
          actions.clearAllDownloads();
          clearInterval(pollerInterval);
        }

        // show toast and return
        if (rest.state === 'failed') {
          if (hasItem) {
            toast.error('File could not be downloaded, kindly try again');
          }
          return;
        }

        // verify that item still exist before updating progress
        if (hasItem) {
          actions.setFile({ ...fileInfo, progress });
        }

        if (progress !== 100) {
          return;
        }

        // check if completed item still exist
        // in a case where user deletes the entry from the pool
        if (hasItem) {
          downloadFile(
            `${getConfig('api')}/browserless/${payload.name}`,
            `${payload.name}.${payload.extension}`
          );
        }

        actions.deleteFile(payload.jobId);
        actions.deletePoller(payload.jobId);
        actions.deleteCsfxJobItem(payload.jobId, actions);
      } catch (error) {
        console.log(error);

        actions.deleteFile(payload.jobId);
        actions.deletePoller(payload.jobId);
        actions.deleteCsfxJobItem(payload.jobId, actions);
      }
    }, 2000);

    actions.setPoller({
      poller: pollerInterval,
      id: payload.jobId,
    });
  }),

  downloadExport: thunk(async (actions, payload, { getStoreState }) => {
    const { queue, filename, extension, ...data } = await feathers()
      .service('/mcfx/export')
      .create({}, payload.params);
    const {
      config: { config: getConfig },
    } = getStoreState();
    const jobId = `${queue}/${data.jobId}`;
    const fileInfo = { id: jobId, filename, queue, progress: 0, extension };
    actions.setFile(fileInfo);
    actions.setPoller({
      poller: setInterval(async () => {
        const { progress } = await feathers()
          .service('queues')
          .get(data.jobId, { query: { queue } });

        actions.setFile({ ...fileInfo, progress });
        if (progress !== 100) {
          return;
        }
        actions.deletePoller(jobId);
        downloadFile(`${getConfig('api')}/mcfx/export/${filename}`, `${filename}.${extension}`);
      }, 2000),
      id: jobId,
    });
  }),

  downloadC360Export: thunk(async (actions, payload, { getStoreState }) => {
    const { toast } = useNotifications();
    const {
      config: { config: getConfig },
    } = getStoreState();

    const fileInfo = {
      id: payload.jobId,
      filename: payload.filename,
      queue: payload.queue,
      progress: 0,
      extension: payload.extension,
    };

    actions.setFile(fileInfo);

    const pollerInterval = setInterval(async () => {
      try {
        const { progress, ...rest } = await feathers()
          .service('queues')
          .get(payload.jobId, { query: { queue: payload.queue } });

        // find existing item and if none
        // clear local store and clear view
        let hasItem = false;
        const existingData = localStorage.getItem('c360CurrentJobs');
        if (existingData) {
          const findItem = JSON.parse(existingData).filter(
            (v) => parseInt(payload.jobId) === parseInt(v.jobId)
          );
          hasItem = findItem.length > 0;
        } else {
          actions.clearAllDownloads();
          clearInterval(pollerInterval);
        }

        // show toast and return
        if (rest.state === 'failed') {
          if (hasItem) {
            toast.error('File could not be downloaded, kindly try again');
          }
          return;
        }

        // verify that item still exist before updating progress
        if (hasItem) {
          actions.setFile({ ...fileInfo, progress });
        }

        if (progress !== 100) {
          return;
        }

        // check if completed item still exist
        // in a case where user deletes the entry from the pool
        if (hasItem) {
          downloadFile(
            `${getConfig('api')}/identity/export/${payload.filename}`,
            `${payload.filename}.${payload.extension}`
          );
        }

        actions.deleteFile(payload.jobId);
        actions.deletePoller(payload.jobId);
        actions.deleteC360JobItem(payload.jobId, actions);
      } catch (error) {
        console.log(error);

        actions.deleteFile(payload.jobId);
        actions.deletePoller(payload.jobId);
        actions.deleteC360JobItem(payload.jobId, actions);
      }
    }, 2000);

    actions.setPoller({
      poller: pollerInterval,
      id: payload.jobId,
    });
  }),

  clearAllDownloads: action((state) => {
    Object.values(state.pollers).map(clearInterval);
    state.files = {};
    state.pollers = {};
    localStorage.removeItem('csfxCurrentPdfJob');
    localStorage.removeItem('c360CurrentJobs');
  }),
};

export default downloads;
