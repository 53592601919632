import React from 'react';
import classNames from 'classnames';

import styles from './Labeled.module.css';

const Text = ({ children, className, ...props }: any) => (
  <span className={classNames(className, styles.content)} {...props}>
    {children}
  </span>
);

const Labeled = ({ labelText, children, ...props }: any) => (
  <div {...props}>
    <span className={styles.label}>{labelText}</span>
    {children}
  </div>
);

Labeled.Text = Text;

export default Labeled;
