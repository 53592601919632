import isPlainObject from 'lodash/isPlainObject';

/**
 * Helper function to clear/update results from a mutation.
 */
export function updateQueryCacheData(
  queryClient,
  keysToUpdate,
  mutationMethod,
  record,
  primaryKey = null
) {
  if (!Array.isArray(keysToUpdate)) {
    keysToUpdate = [keysToUpdate];
  }

  // ensure all keys are arrays to match useQuery syntax
  keysToUpdate = keysToUpdate.map((k) => (Array.isArray(k) ? k : [k]));

  for (const key of keysToUpdate) {
    const oldData = queryClient.getQueryData(key);

    // if the key has no exact matching data queried data,
    // we can't make safe specific updates on records,
    // so we invalidate the services queries to be fetched next time.
    if (!oldData) {
      queryClient.invalidateQueries(key[0]);
      continue;
    }

    if (mutationMethod.toLowerCase() === 'create') {
      if (Array.isArray(oldData)) {
        queryClient.setQueryData(key, oldData.concat(record));
        continue;
      }

      queryClient.setQueryData(key, {
        ...oldData,
        data: oldData.data.concat(record),
        total: oldData.total + 1,
      });

      continue;
    }

    // if primary key is not present we can't make safe specific updates on records,
    // so we invalidate the service or service with params if params passed to key.
    if (!primaryKey) {
      queryClient.invalidateQueries(key.filter((k, i) => i === 0 || isPlainObject(k)));
      continue;
    }

    if (mutationMethod.toLowerCase() === 'remove') {
      if (Array.isArray(oldData)) {
        queryClient.setQueryData(
          key,
          oldData.filter((item) => {
            return record[primaryKey] !== item[primaryKey];
          })
        );

        continue;
      }

      queryClient.setQueryData(key, {
        ...oldData,
        data: oldData.data.filter((item) => {
          return record[primaryKey] !== item[primaryKey];
        }),
      });

      continue;
    }

    // Update by default
    if (Array.isArray(oldData)) {
      queryClient.setQueryData(
        key,
        oldData.map((item) => {
          return record[primaryKey] === item[primaryKey] ? record : item;
        })
      );

      continue;
    }

    queryClient.setQueryData(key, {
      ...oldData,
      data: oldData.data.map((item) => {
        return record[primaryKey] === item[primaryKey] ? record : item;
      }),
    });
  }
}
