import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';

import Icon from '../../Icon/Icon';
import styles from './TabItemSecond.module.css';

const TabItemSecond = ({ tab, active }) => {
  const tabItem = classnames(styles.tabItem, { [styles.activeTabItem]: active });
  const tabIcon = classnames(styles.iconWrap, {
    [styles.iconWrapNoText]: tab.hideName,
    [styles.iconWrapActive]: active,
  });

  return (
    <li className={tabItem}>
      <NavLink to={tab.link} className={styles.navLink}>
        {tab.icon && (
          <span className={tabIcon}>
            <Icon outlined>{tab.icon}</Icon>
          </span>
        )}
        {tab.name && !tab.hideName && <span className={styles.tabName}>{tab.name}</span>}
      </NavLink>
    </li>
  );
};

TabItemSecond.propTypes = {
  tab: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string])).isRequired,
  active: PropTypes.bool.isRequired,
};

export default TabItemSecond;
