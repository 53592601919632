import classnames from 'classnames';

import styles from './Typography.module.css';

const Typography = ({
  className,
  component = 'p',
  gutterTop,
  gutterBottom,
  variant = 'body1',
  children,
  bold,
  uppercase = false,
  underline = false,
  ['data-fx-name']: dataFxName,
  ...passThroughProps
}) => {
  const Component = component;

  return (
    <Component
      data-fx-name={dataFxName}
      className={classnames(
        styles[variant],
        {
          [styles.gutterTop]: gutterTop,
          [styles.gutterBottom]: gutterBottom,
          [styles.bold]: bold,
          [styles.uppercase]: uppercase,
          [styles.underline]: underline,
        },
        className
      )}
      {...passThroughProps}
    >
      {children}
    </Component>
  );
};

export default Typography;
