import React, { useRef } from 'react';
import { useField } from 'formik';
import ReactQuill from 'react-quill';
import { useDebouncedCallback } from 'use-debounce';

import 'react-quill/dist/quill.snow.css';

const Wysiwyg = React.forwardRef(
  ({ value, height = 100, onChange, touched, error, ...passThroughProps }, ref) => {
    const fallbackRef = useRef();
    const normalizedRef = ref ?? fallbackRef;

    return (
      <>
        <ReactQuill
          ref={normalizedRef}
          defaultValue={value}
          onChange={(value) => {
            onChange(value);
          }}
          {...passThroughProps}
        >
          <div style={{ height }} />
        </ReactQuill>
        {touched && error && <div className="error">{error}</div>}
      </>
    );
  }
);

export const FormikWysiwyg = React.forwardRef(({ name, onChange, ...passThroughProps }, ref) => {
  const [field, meta, helpers] = useField(name);
  const debouncedChange = useDebouncedCallback(
    // function
    (value) => {
      if (helpers.setTouched) {
        helpers.setTouched(true);
      }
      if (helpers.setValue) {
        helpers.setValue(value);
      }
      if (field.onChange) {
        field.onChange(value);
      }
    },
    // delay in ms
    400
  );

  return (
    <>
      <Wysiwyg
        {...field}
        {...meta}
        {...passThroughProps}
        ref={ref}
        onChange={(value) => {
          debouncedChange(value);
          if (onChange) {
            onChange(value);
          }
        }}
      />
    </>
  );
});

export default Wysiwyg;
