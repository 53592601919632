import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, Slide } from 'react-toastify';

export function NotificationsContainer(props) {
  return <ToastContainer {...props} data-fx-name="alertMessage" />;
}

/*
   refer to https://fkhadra.github.io/react-toastify/api/toast-container/
   in order to add more props.
*/
NotificationsContainer.propTypes = {
  containerId: PropTypes.string,
  autoClose: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  position: PropTypes.oneOf([
    'top-right',
    'top-center',
    'top-left',
    'bottom-right',
    'bottom-center',
    'bottom-left',
  ]),
  // transition: PropTypes.node, //A reference to a valid react-transition-group/Transition component
  draggable: PropTypes.bool,
  hideProgressBar: PropTypes.bool,
};

NotificationsContainer.defaultProps = {
  containerId: 'global-notifications-container',
  autoClose: 5000,
  position: 'bottom-right',
  transition: Slide,
  draggable: false,
  hideProgressBar: true,
  theme: 'colored',
  icon: false,
};
