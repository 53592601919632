import { useCallback, useRef, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import get from 'lodash/get';
import join from 'lodash/join';
import { Transition } from 'react-transition-group';
import { datadogRum } from '@datadog/browser-rum';

import Icon from '../Icon/Icon';
import Typography from '../Typography/Typography';
import Button from '../Button/Button';

import styles from './Snackbar.module.css';

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
};

const Snackbar = ({
  autoHideDuration = 5000,
  className,
  hideCloseButton,
  icon,
  iconOutined,
  variant,
}) => {
  const timerAutoHide = useRef();
  const hideError = useStoreActions((actions) => actions.common.hideError);
  const error = useStoreState((state) => state.common.error);

  const message = join(get(error, ['message'], null), '');

  const handleHideError = () => {
    clearTimeout(timerAutoHide.current);
    timerAutoHide.current = 0;
    hideError();
  };

  useEffect(() => {
    if (error?.message) {
      datadogRum.addError(error, undefined, 'source');
    }
  }, [error?.message]);

  /*
    Timer that controls delay before snackbar auto hides
    forked from
    https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Snackbar/Snackbar.js
  */
  const setAutoHideTimer = useCallback(
    (autoHideDurationParam) => {
      const autoHideDurationBefore =
        autoHideDurationParam != null ? autoHideDurationParam : autoHideDuration;

      if (autoHideDurationBefore == null) {
        return;
      }

      clearTimeout(timerAutoHide.current);
      timerAutoHide.current = setTimeout(() => {
        const autoHideDurationAfter =
          autoHideDurationParam != null ? autoHideDurationParam : autoHideDuration;
        if (autoHideDurationAfter == null) {
          return;
        }
        handleHideError();
      }, autoHideDurationBefore);
    },
    [autoHideDuration, handleHideError]
  );

  if (!message) {
    return null;
  }

  setAutoHideTimer();

  return (
    <Transition in={!!message} timeout={300}>
      {(state) => (
        <div
          className={`${styles.alert} ${className} ${variant === 'error' && styles.error}`}
          style={{ ...transitionStyles[state] }}
        >
          <div className={styles.wrap} data-fx-name="popUpNotification">
            <div className={styles.col1}>
              {icon && (
                <Icon outlined={iconOutined} className={styles.icon}>
                  {icon}
                </Icon>
              )}
              <Typography bold className={styles.message} data-fx-name="alertMessage">
                {message}
              </Typography>
            </div>
            {!hideCloseButton && (
              <div>
                <Button
                  variant="dark-outline"
                  icon="close"
                  borderless
                  onClick={handleHideError}
                  className={styles.button}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </Transition>
  );
};

export default Snackbar;
