import classnames from 'classnames';

import styles from './FileImg.module.css';

const defaultFileImg = '/assets/images/icons/icon-file-default.png';
const fileDoc = '/assets/images/icons/icon-file-doc.png';
const fileJpg = '/assets/images/icons/icon-file-jpg.png';
const filePng = '/assets/images/icons/icon-file-png.png';
const filePpt = '/assets/images/icons/icon-file-ppt.png';
const fileXls = '/assets/images/icons/icon-file-xls.png';
const fileZip = '/assets/images/icons/icon-file-zip.png';
const filePdf = '/assets/images/icons/icon-file-pdf.png';
const fileTxt = '/assets/images/icons/icon-file-txt.png';
const fileGif = '/assets/images/icons/icon-file-gif.png';
const fileHtml = '/assets/images/icons/icon-file-html.png';

const fileType = {
  doc: fileDoc,
  docx: fileDoc,
  jpg: fileJpg,
  jpeg: fileJpg,
  png: filePng,
  ppt: filePpt,
  pptx: filePpt,
  xls: fileXls,
  xlsx: fileXls,
  zip: fileZip,
  pdf: filePdf,
  txt: fileTxt,
  gif: fileGif,
  html: fileHtml,
};

const FileImg = ({ type = '', alt = 'File', className, imgClassName }) => {
  const typeWithOutDot = type && type.replace('.', '');
  const formattedType = typeWithOutDot && typeWithOutDot.toLocaleLowerCase();

  return (
    <div className={classnames(styles.wrapper, className)}>
      <img
        src={formattedType in fileType ? fileType[formattedType] : defaultFileImg}
        alt={alt}
        className={classnames(styles.img, imgClassName)}
      />
    </div>
  );
};

export default FileImg;
