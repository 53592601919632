import React from 'react';
import classnames from 'classnames';
import Dropzone from '@webfx/teamwork-web/src/components/DropzoneFilesUpload/Dropzone';

import styles from './AvatarUploader.module.css';

const AvatarUploader = React.forwardRef(
  (
    {
      name,
      className,
      stylePanelLayout = 'compact',
      imageUrl = '',
      onupdatefiles = null,
      avatarData = {},
      ...passThroughProps
    },
    ref
  ) => {
    const formObject = {
      [`${avatarData.key}`]: avatarData.value,
    };

    const avatarUrl = imageUrl ? `${window.origin}/uploads/${imageUrl?.split('/')?.pop()}` : null;

    return (
      <div className={classnames(['avatar-uploader', styles.wrapper, className])}>
        <Dropzone
          ref={ref}
          placeHolder={'Drag & Drop your Profile Picture or Click to Browse'}
          allowedFilesType={'image/jpeg, image/png'}
          maxFiles={1}
          stylePanelLayout={stylePanelLayout}
          formObject={{
            avatar: formObject,
          }}
          title={null}
          avatarUrl={avatarUrl}
          showImagePreviewOnDragDiv={true}
          onFilesProcessed={onupdatefiles}
          {...passThroughProps}
        />
      </div>
    );
  }
);

export default AvatarUploader;
