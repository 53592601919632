import React from 'react';
import { columnsToDisplayMap } from './constants';
const RequestError = ({ error }) => {
  if (error.code === 400) {
    return (
      <div>
        <span>{error.message}</span>
        {error.errors.length && (
          <ul>
            {error.errors.map((error) => {
              let message = error;
              if (typeof error === 'object') {
                message = error.message || '';
              }
              const columnIndex = message.indexOf(' ');
              const displayMessage =
                columnsToDisplayMap.get(message.slice(0, columnIndex)) ??
                message.slice(0, columnIndex);
              return <li key={error.message}>{displayMessage + message.slice(columnIndex)}</li>;
            })}
          </ul>
        )}
      </div>
    );
  }

  return error.message;
};

export default RequestError;
