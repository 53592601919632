import { action, actionOn, thunkOn } from 'easy-peasy';

const common = {
  modal: {},
  loader: {
    selectedMessage: true,
    getCategories: true,
    editCompanyLogo: false,
    deleteCompany: false,
    getCountries: true,
    getTimezones: true,
    getUsers: true,
    selectedUser: true,
    findCompanies: true,
    getRecentMessages: true,
    getAllMessages: true,
    getFilesByCategories: true,
    getArchiveMessages: true,
    getTodoLists: true,
    getMilestones: true,
    getMilestoneSingle: true,
    getTemplates: true,
    getTodos: true,
    getMessages: true,
    getSubscribePeople: true,
    getProjectTemplates: true,
    getProject: true,
    getTodoList: true,
    filterTodoLists: true,
    getPrivateAssignee: true,

    // This values are using for checking do we can show loader again in some pages
    showMessagesLoader: true,
    showMessagesCommentsLoader: true,
    showArchiveMessagesCommentsLoader: true,
    showTodoLoader: true,
  },
  error: {},
  appError: null,
  // activeSite: null,
  isMobile: false,
  isTablet: false,
  isLaptop: true,
  screenSize: 0,

  // actions
  showModal: action((state, payload) => {
    state.modal = payload;
  }),

  hideModal: action((state) => {
    state.modal = {};
  }),

  showLoader: action((state, payload) => {
    state.loader[payload.key] = true;
  }),

  hideLoader: action((state, payload) => {
    state.loader[payload.key] = false;
  }),

  showError: action((state, payload) => {
    state.error = payload;
  }),

  hideError: action((state) => {
    state.error = {};
  }),

  setAppError: action((state, payload) => {
    state.appError = payload;
  }),

  clearAppError: actionOn(
    (actions, storeActions) => storeActions.router.setLocation,
    (state, target) => {
      state.appError = null;
    }
  ),

  showNotification: action((state, payload) => {
    state.error = payload;
  }),

  setActiveSite: action((state, payload) => {
    state.activeSite = payload;
  }),

  setScreenSize: action((state, payload) => {
    state.isMobile = payload === 'mobile';
    state.isTablet = payload === 'tablet';
    state.isLaptop = payload === 'laptop';
  }),

  setScreenSizeInPx: action((state, payload) => {
    state.screenSize = payload;
  }),

  clearLoadersState: action((state, payload) => {
    payload.keys.forEach((key) => (state.loader[key] = true));
  }),
};

export default common;
