import classnames from 'classnames';

const Textarea = ({
  field: { name, value, onChange, onBlur },
  placeholder,
  disabled,
  readOnly,
  className,
  error,
  rows = '3',
}) => (
  <textarea
    id={name}
    className={classnames('form-control', className, { 'is-invalid': error })}
    name={name}
    placeholder={placeholder}
    onChange={onChange}
    onBlur={onBlur}
    disabled={disabled}
    readOnly={readOnly}
    value={value}
    rows={rows}
  />
);

export default Textarea;
