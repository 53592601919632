import OverlayTooltip from '@webfx/teamwork-web/src/ui/components/OverlayTooltip/OverlayTooltip';
import UserOverlayTooltip from '@webfx/teamwork-web/src/ui/components/UserOverlayTooltip';
import CardAvatar from '../CardAvatar/CardAvatar';

import styles from './CardAllCommentatorsAvatars.module.css';

const getLeftStyleForIcons = (iconIndex) => ({ left: `${23 * iconIndex}px` });

const getListStyleWidth = (list, fixedWidth) =>
  fixedWidth ? { width: '103px' } : { width: `${32 * list.length - 9 * (list.length - 1) + 2}px` };

// TODO: deprecate an move avatar groups as part of avatar module

const CardAllCommentatorsAvatars = ({ commentators = [], fixedWidth = false }) => {
  if (!commentators.length) {
    return null;
  }

  return (
    <ul className={styles.list} style={getListStyleWidth(commentators, fixedWidth)}>
      {commentators.length <= 3 ? (
        commentators.map((currentCommentator, index) => {
          return (
            <li
              className={styles.item}
              key={currentCommentator.userId}
              style={getLeftStyleForIcons(index)}
            >
              <UserOverlayTooltip user={currentCommentator} placement={'left'}>
                <CardAvatar
                  imageUrl={currentCommentator.avatar}
                  size="sm"
                  className={styles.avatar}
                />
              </UserOverlayTooltip>
            </li>
          );
        })
      ) : (
        <>
          {commentators.slice(0, 3).map((currentCommentator, index) => {
            return (
              <li
                className={styles.item}
                key={currentCommentator.userId}
                style={getLeftStyleForIcons(index)}
              >
                <UserOverlayTooltip user={currentCommentator} placement={'left'}>
                  <CardAvatar
                    imageUrl={currentCommentator.avatar}
                    size="sm"
                    className={styles.avatar}
                  />
                </UserOverlayTooltip>
              </li>
            );
          })}
          <li className={styles.item} style={getLeftStyleForIcons(3)}>
            <OverlayTooltip
              text={commentators
                .slice(3)
                .map((commentator) => commentator.name)
                .join(', ')}
              placement={'left'}
            >
              <CardAvatar
                imageText={`${commentators.length - 3}`}
                size="sm"
                className={styles.avatar}
              />
            </OverlayTooltip>
          </li>
        </>
      )}
    </ul>
  );
};

export default CardAllCommentatorsAvatars;
