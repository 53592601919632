import { useRef } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import classnames from 'classnames';

import useShowTooltip from '../../../utils/useShowTooltip';

import styles from './CardTitle.module.css';

const CardTitle = ({ text, truncate, className }) => {
  const ref = useRef();
  const useTooltip = useShowTooltip(ref);

  if (!truncate || !useTooltip) {
    return (
      <h3 ref={ref} className={classnames([styles.title, className])}>
        {text}
      </h3>
    );
  }

  return (
    <OverlayTrigger placement="top" overlay={<Tooltip>{text}</Tooltip>}>
      <h3 ref={ref} className={classnames([styles.title, styles.truncated, className])}>
        {text}
      </h3>
    </OverlayTrigger>
  );
};

export default CardTitle;
