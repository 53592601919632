import { thunk, action, computed, actionOn } from 'easy-peasy';
import { v4 as uuid } from 'uuid';

const filterable = (initialFilters = {}) => ({
  initialFilters,
  filters: {},
  filterHash: uuid(),

  setFilter: action((state, payload) => {
    if (Array.isArray(payload)) {
      payload.forEach((f) => {
        state.filters[f.key] = f.value;
      });
    } else {
      Object.keys(payload).forEach((key) => {
        state.filters[key] = payload[key];
      });
    }

    state.filterHash = uuid();
  }),

  setInitialFilters: action((state, payload) => {
    state.initialFilters = payload;
  }),

  clearFilter: action((state, payload) => {
    if (Array.isArray(payload)) {
      payload.forEach((key) => {
        delete state.filters[key];
      });
    } else {
      delete state.filters[payload];
    }
    state.filterHash = uuid();
  }),

  resetFilters: action((state, payload) => {
    state.filters = {};
    state.filterHash = uuid();
  }),

  resetFiltersOnReset: actionOn(
    (actions) => actions.reset,
    (state, target) => {
      state.filters = {};
      state.filterHash = uuid();
    }
  ),

  resetToInitial: action((state, payload) => {
    state.filters = state.initialFilters;
    state.filterHash = uuid();
  }),
});

export default filterable;
