import React from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { useStoreState } from 'easy-peasy';
import { useLocation } from 'react-router';

class ReactErrorBoundary extends React.Component {
  constructor(props) {
    super();
    this.state = {
      error: props.error || null,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
  }

  render() {
    if (!this.state.error) {
      return this.props.children;
    }

    // TODO: move to unified core function.
    datadogRum.addError(this.state.error, undefined, 'source');

    const { FallbackComponent } = this.props;

    return <FallbackComponent error={this.state.error}>{this.props.children}</FallbackComponent>;
  }
}

const ErrorBoundary = ({ children, FallbackComponent }) => {
  const appError = useStoreState((state) => state.common.appError);
  const location = useLocation();

  // if (appError) {
  //   return (
  //     <CenterizedHandler FallbackComponent={FallbackComponent} error={appError}>
  //       {children}
  //     </CenterizedHandler>
  //   )
  // }

  return (
    <ReactErrorBoundary
      key={location.pathname}
      FallbackComponent={FallbackComponent}
      error={appError}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
