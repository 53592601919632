import { ResizeProvider } from '@webfx/web-context';
import EventList from './EventList';
import { useSessionedImpressions } from './useSessionedImpressions';

import './Timeline.css';

const Timeline = ({ className, data = null, height = 200 }) => {
  const impressions = useSessionedImpressions(data);

  return (
    <div className={`timeline ${className}`}>
      <ResizeProvider>
        {impressions?.length ? <EventList events={impressions} height={height} /> : null}
      </ResizeProvider>
    </div>
  );
};

export default Timeline;
