export function dataOrDefaults(data, defaults = { total: 0, limit: 20, data: [] }) {
  if (!data) {
    return defaults;
  }

  const obj = {};
  Object.keys(defaults).forEach((k) => {
    obj[k] = !data[k] ? defaults[k] : data[k];
  });

  return obj;
}
