import ReactTags from 'react-tag-autocomplete';

const TagList = ({ disabled, handleDelete, ...props }) => {
  const onHandleDelete = (index) => {
    if (disabled || !handleDelete) {
      return false;
    }

    handleDelete(index);
  };

  return <ReactTags inputAttributes={{ disabled }} handleDelete={onHandleDelete} {...props} />;
};

export default TagList;
