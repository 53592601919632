import classnames from 'classnames';

import Checkbox from '../Checkbox/Checkbox';
import InputLabel from '../Input/InputLabel/InputLabel';

import styles from './CheckboxGroup.module.css';

const CheckboxGroup = ({
  field,
  field: { name },
  form,
  value,
  label,
  disabled,
  readOnly,
  className,
  bottomGutter,
  bold,
  uncheckedGrayColor = false,
  extraInfo,
}) => {
  const handleChange = (event) => {
    form.setFieldTouched(field.name, true);

    if (typeof field.value === 'boolean') {
      let newValue = false;
      if (event.target.checked) {
        newValue = true;
      }

      field.onChange(field.name)(newValue);
      return;
    }

    const set = new Set(field.value);
    if (set.has(value)) {
      set.delete(value);
    } else {
      set.add(value);
    }
    field.onChange(field.name)(Array.from(set));
  };

  let checked = field.value === true;
  if (typeof field.value === 'object') {
    checked = field.value.includes(value);
  }

  return (
    <div
      className={classnames(
        styles.wrap,
        { [styles.bottomGutter]: bottomGutter, [styles.bold]: bold },
        className
      )}
    >
      <div className={styles.col1}>
        <Checkbox
          {...field}
          onChange={handleChange}
          checked={checked}
          disabled={disabled}
          readOnly={readOnly}
          uncheckedGrayColor={uncheckedGrayColor || !field.value}
        />
      </div>
      <div className={styles.col2}>
        <InputLabel text={label} htmlFor={name} className={styles.label} extraInfo={extraInfo} />
      </div>
    </div>
  );
};

export default CheckboxGroup;
