/* eslint-disable no-param-reassign */
import { action, computed } from 'easy-peasy';

const resizable = () => ({
  ids: {},
  sizes: new Map(),
  ref: null,
  defaultSize: 60,
  size: computed((state) => (index) => {
    return state.sizes.get(index) || state.defaultSize;
  }),

  idIndex: computed((state) => (id) => {
    return state.ids[id] || 0;
  }),

  setSize: action((state, payload) => {
    const index = payload.index ?? payload.id;
    state.sizes.set(index, Math.round(payload.size));

    if (state.ref?.current) {
      state.ref.current.resetAfterIndex(index, true);
    }
  }),

  resetAfterIndex: action((state, payload) => {
    if (state.ref?.current) {
      state.ref.current.resetAfterIndex(payload);
    }
  }),

  setRef: action((state, payload) => {
    state.ref = payload;
    if (payload?.current) {
      payload.current.resetAfterIndex(0);
    }
  }),
});

export default resizable;
