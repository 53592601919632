/* eslint-disable react-hooks/rules-of-hooks -- Disable eslint rule 'react-hooks/rules-of-hooks' to allow usage of hooks in this file. */

import { useMutation as useReactQueryMutation, useQueryClient } from 'react-query';
import isFunction from 'lodash/isFunction';

import { updateQueryCacheData } from './helpers/updateQueryCacheData';
import { getKeyParts, parseMutationArgs } from './utils';
import { defaultMutationFn } from './defaultMutationFn';

/**
 * Helper hook to mutate data using feathers services.
 *
 * Usage:
 *
 * const mutator = useMutation(['some-service'])
 * const mutator = useMutation(['some-service'], reactQueryOpts)
 * const mutator = useMutation(['some-service'], 'primaryKey', reactQueryOpts)
 * const mutator = useMutation(['some-service'], [queryToUpdate], reactQueryOpts)
 * const mutator = useMutation(['some-service'], 'primaryKey', [queryToUpdate], reactQueryOpts)
 *
 * mutator.mutate({ _method: 'create',  ...data })
 * mutator.mutate({ _id: 5, _method: 'patch',  ...data })
 *
 * const mutator = useMutation(['some-service', 'patch'])
 * mutator.mutate({ _id: 1, ...data })
 *
 * For more info: https://react-query.tanstack.com/reference/useMutation
 */
export function useMutation(mutationKey, ...args) {
  // Rare usage of react-query useMutation without defaults;
  if (isFunction(mutationKey)) {
    return useReactQueryMutation(mutationKey, ...args);
  }

  let { reactQueryOpts, keysToUpdate } = parseMutationArgs(args);

  const queryClient = useQueryClient();

  if (!Array.isArray(mutationKey)) {
    mutationKey = [mutationKey];
  }

  if (keysToUpdate && !Array.isArray(keysToUpdate)) {
    keysToUpdate = [keysToUpdate];
  }

  const { service, method, id, params } = getKeyParts(mutationKey);

  const {
    beforeCacheUpdate,
    afterCacheUpdate,
    onMutate = (v) => v,
    ...passThroughReactQueryOpts
  } = reactQueryOpts;

  return useReactQueryMutation(defaultMutationFn(service, method, id, params), {
    onMutate: async (variables) => {
      const customMutateRes = await onMutate(variables);

      return {
        mutationKey,
        id: variables?._id || id,
        service: variables?._service || service,
        method: variables?._method || method,
        queryClient,
        ...customMutateRes,
      };
    },

    onSuccess: (data, variables, context) => {
      if (beforeCacheUpdate) {
        beforeCacheUpdate(data, variables, context);
      }

      if (context?.id) {
        const idKeys = [[context.service, context.id]];
        keysToUpdate = (keysToUpdate || []).concat(idKeys);
      }

      updateQueryCacheData(
        queryClient,
        keysToUpdate || [mutationKey],
        context.method,
        data,
        context.primaryKey
      );

      if (afterCacheUpdate) {
        afterCacheUpdate(data, variables, context);
      }
    },
    ...passThroughReactQueryOpts,
  });
}
/* eslint-enable react-hooks/rules-of-hooks -- Enable eslint rule 'react-hooks/rules-of-hooks' to allow usage of hooks in this file. */
