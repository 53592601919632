import LazyLoad from 'react-lazy-load';
import classnames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { DEFAULT_AVATAR_URL } from '@webfx/teamwork-web/src/config/app';

import styles from './Avatar.module.css';

/**
 * There was a strange behaviour with default param for @src
 * e.g. `const Avatar = ({src = defaultAvatar}) => *rest of code*`
 * src === null, @defaultAvatar value isn't apply the same for empty `string`
 * So the solution is move the `condition` to `src` property and check from this place.
 */

const Image = ({
  src,
  alt = 'Avatar',
  className,
  disableLazyLoad = false,
  ...passThroughProps
}) => {
  const handleImageError = ({ currentTarget }) => {
    currentTarget.onError = null;
    currentTarget.src = DEFAULT_AVATAR_URL;
  };
  return (
    <>
      {disableLazyLoad ? (
        <img
          className={classnames(styles.avatar, className)}
          onError={handleImageError}
          {...passThroughProps}
          src={src || DEFAULT_AVATAR_URL}
          alt={alt}
        />
      ) : (
        <LazyLoad className={className}>
          <img
            className={classnames(styles.avatar, className)}
            data-fx-name="userAvatar"
            onError={handleImageError}
            {...passThroughProps}
            src={src || DEFAULT_AVATAR_URL}
            alt={alt}
            loading="lazy"
          />
        </LazyLoad>
      )}
    </>
  );
};

const Avatar = ({ tooltip, ...passThroughProps }) => {
  if (!tooltip) {
    return <Image {...passThroughProps} />;
  }

  return (
    <OverlayTrigger placement="top" overlay={<Tooltip>{tooltip}</Tooltip>}>
      <Image {...passThroughProps} />
    </OverlayTrigger>
  );
};

export default Avatar;
