import { useQuery as useReactQuery } from 'react-query';
import { resolveQueryKey } from './utils';

/**
 * This is a basic wrapper around react-query to provide a common api interface.
 * It uses a defaultQueryFn that handles the keys and do the call to the feathers API.
 *
 * Usage:
 *
 * const query = useQuery('service', reactQueryOpts) // find
 * const query = useQuery(['service', queryOrParams], reactQueryOpts) // find service
 * const query = useQuery(['service', 5], reactQueryOpts) // get service/5
 * const query = useQuery(['service', 5, queryOrParams], reactQueryOpts)
 *
 * For more info: https://react-query.tanstack.com/reference/useQuery
 *
 * Note: If the params object doesn't include a property named query , the object will be used as query by default. This works as shortcut
 * This means that: `useQuery(['service', { $limit: 1 }])`
 * is the same as:  `useQuery(['service', { query: { $limit: 1 }}])`
 *
 * @param {Array|string} serviceInfo
 * @param {String} serviceInfo[0] The service path that will be fetched.
 * @param {Object|string|number} serviceInfo[1] If object, it will be used as params for the query.
 *    If number or string, it will be used to perform a GET request to the the specified record.
 * @param {Object} serviceInfo[2] When performing a GET request, service as query params.
 * @param {Object} reactQueryOptions React Query options
 * @param {any} args Any other value is forwarded to ReactQuery
 *
 * @returns {Object} Returns the same as ReactQuery plus a queryKey prop. This prop can be used to reference this query in the cache.
 */
export function useQuery(serviceInfo, reactQueryOptions, ...args) {
  const finalServiceInfo = resolveQueryKey(serviceInfo);

  const res = useReactQuery(finalServiceInfo, reactQueryOptions, ...args);

  return {
    queryKey: finalServiceInfo,
    ...res,
  };
}
