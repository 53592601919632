import React from 'react';
import { Spinner as BootstrapSpinner } from 'react-bootstrap';
import classnames from 'classnames';
import styles from './Spinner.module.css';

const Spinner = ({
  className,
  small = false,
  inline = false,
  variant = 'dark',
  style = {},
  ...passThroughProps
}) => {
  const Spin = () => (
    <BootstrapSpinner
      animation="border"
      role="status"
      variant={variant}
      {...passThroughProps}
      data-fx-name="loadingSpinner"
    >
      <span className="sr-only">Loading...</span>
    </BootstrapSpinner>
  );

  if (small || inline) {
    return <Spin />;
  }

  return (
    <div className={classnames([styles.wrap, className])} style={style}>
      <Spin />
    </div>
  );
};

export default Spinner;
