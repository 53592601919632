import { useState } from 'react';
import { useStoreState } from 'easy-peasy';
import numbro from 'numbro';
import classnames from 'classnames';
import IconLabel from '../IconLabel/IconLabel';
import Button from '../Button/Button';
import Typography from '../Typography/Typography';
import ImportItem from './ImportItem/ImportItem';
import './Imports.style.css';

const Imports = () => {
  const [isMinimized, setMinimized] = useState(false);
  const { imports } = useStoreState((state) => state.imports);

  const totalProgress = numbro(
    imports.reduce((accum, impt) => accum + impt.progress, 0) / imports.length
  ).format({
    trimMantissa: true,
    mantissa: 1,
  });

  return imports.length > 0 ? (
    <div
      className={classnames({
        'imports-wrapper': true,
        minimized: isMinimized,
      })}
    >
      <div className="imports-header">
        <IconLabel
          labelClassName="bold"
          iconClassName="import-icon"
          icon="publish"
          label={`Imports (${imports.length})`}
          className="flex-grow-1"
        />
        <Typography className="text-secondary font-12 mb-0 mr-2">{totalProgress}%</Typography>
        <Button
          icon={isMinimized ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
          variant="light"
          className="border-0 p-0 text-dark bg-white"
          onClick={() => {
            setMinimized(!isMinimized);
          }}
        />
      </div>
      <div className="bg-green-03 text-white font-14 p-1">
        File received! It is safe to close this page.
      </div>
      <div className="import-items">
        {imports.map((impt) => (
          <ImportItem key={impt.id} item={impt} />
        ))}
      </div>
    </div>
  ) : null;
};

export default Imports;
