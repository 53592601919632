import React from 'react';
import PropTypes from 'prop-types';

import List from '../List/List';
import TabItem from '../TabItem/TabItem';
import TabItemSecond from '../TabItem/TabItemSecond/TabItemSecond';
import styles from './PageTabs.module.css';

const PageTabs = ({ activeTab, leftTabs, rightTabs }) => (
  <div className={styles.wrap}>
    <div>
      <List>
        {leftTabs.map((tab) => (
          <TabItem key={tab.name} tab={tab} active={tab.name.toLowerCase() === activeTab} />
        ))}
      </List>
    </div>
    <div className={styles.col}>
      <List>
        {rightTabs.map((tab) => (
          <TabItemSecond key={tab.name} tab={tab} active={tab.name.toLowerCase() === activeTab} />
        ))}
      </List>
    </div>
  </div>
);

PageTabs.defaultProps = {
  activeTab: null,
};

PageTabs.propTypes = {
  activeTab: PropTypes.string,
  leftTabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  rightTabs: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PageTabs;
