import React from 'react';

import DisplayErrorBoundary from './DisplayErrorBoundary';

const withDisplayErrorBoundary = (Component) => (props) => {
  return (
    <DisplayErrorBoundary>
      <Component {...props} />
    </DisplayErrorBoundary>
  );
};

export default withDisplayErrorBoundary;
