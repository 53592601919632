import React from 'react';
import { Badge as BootstrapBadge } from 'react-bootstrap';
import classnames from 'classnames';

import './Badge.style.css';

const Badge = ({ children, className, light, uppercase, type, ...passThroughProps }) => {
  return (
    <BootstrapBadge
      className={classnames([
        className,
        {
          'badge-light': light,
          uppercase,
          badgeOver: type === 'over',
          'danger-light': passThroughProps.variant === 'danger-light',
          'success-light': passThroughProps.variant === 'success-light',
          lime: passThroughProps.variant === 'lime',
        },
      ])}
      {...passThroughProps}
    >
      {children}
    </BootstrapBadge>
  );
};

export default Badge;
