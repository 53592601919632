import React, { memo, useState } from 'react';
import classnames from 'classnames';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { useField } from 'formik';

import { API } from '../../config/app';
import { getAccessToken } from '../../services/auth';
import styles from './AvatarUploader.module.css';

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginImageResize,
  FilePondPluginFileValidateType
);
const accessToken = getAccessToken();
const getFilepondAuthorizedServerConfig = (isAvatar = false, pond) => {
  const apiUpload = useStoreState((state) => state.config.config('apiUpload'));

  return {
    url: `${apiUpload}${isAvatar ? '?storage=local' : ''}`,
    process: {
      headers: {
        Authorization: accessToken,
      },
    },
    revert: {
      headers: {
        Authorization: accessToken,
      },
    },

    load: (source, load, error, progress, abort, headers) => {
      const request = new Request(`${API}/filepond/load?url=${source}`, {
        headers: {
          Authorization: accessToken,
        },
      });
      fetch(request).then(function (response) {
        const responseHeaders = [];

        let filename = null;
        const cd = response.headers.get('content-disposition');

        if (cd) {
          filename = cd.split('filename=')[1].split(';')[0].slice(1, -1);
        }

        for (const pair of response.headers.entries()) {
          responseHeaders.push(pair[0] + ': ' + pair[1]);
        }
        headers(responseHeaders.join('\r\n'));
        response.blob().then(function (blob) {
          if (filename) {
            blob.name = filename;
          }
          load(blob);
        });
      });

      return {
        abort: () => {
          abort();
        },
      };
    },
  };
};

const AvatarUploader = React.forwardRef(
  (
    {
      name,
      className,

      onprocessfile,
      onremovefile,

      labelIdle = 'Drag & Drop your picture or <span class="filepond--label-action">Browse</span>',
      stylePanelLayout = 'compact',
      imagePreviewHeight = 170,
      imageResizeTargetWidth = 200,
      ...passThroughProps
    },
    ref
  ) => {
    const [field, meta, helpers] = useField(name);
    const { value } = field;
    const { setValue } = helpers;
    const showError = useStoreActions((actions) => actions.common.showError);

    const onProcessFile = (error, file) => {
      const { fileExtension, fileSize, fileType, filename, serverId, id } = file;
      setValue([
        {
          extension: fileExtension,
          byteSize: fileSize,
          type: fileType,
          name: filename,
          serverId,
          source: `https://media.webfx.com/avatars/${serverId}`,
          filePondId: id,
        },
      ]);
      if (onprocessfile) {
        onprocessfile(error, file);
      }
    };

    const onRemoveFile = (error, file) => {
      const { id } = file;

      if (value.length === 1) {
        setValue([]);
      } else {
        setValue(value && value.filter(({ filePondId }) => filePondId !== id));
      }

      if (onremovefile) {
        onremovefile(error, file);
      }
    };

    const onError = ({ main, sub }) => {
      showError({ message: `${main || ''}: ${sub || ''}` });
    };

    return (
      <div className={classnames(['avatar-uploader', styles.wrapper, className])}>
        <FilePond
          ref={ref}
          server={getFilepondAuthorizedServerConfig(true, ref)}
          labelIdle={labelIdle}
          onprocessfile={onProcessFile}
          onremovefile={onRemoveFile}
          onerror={onError}
          files={value}
          stylePanelLayout={stylePanelLayout}
          allowMultiple={false}
          maxFiles={1}
          maxParallelUploads={1}
          {...passThroughProps}
        />
      </div>
    );
  }
);

export default AvatarUploader;
