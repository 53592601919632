import React from 'react';
import Chart from 'react-apexcharts';

const SparkChart = ({ colors, series, options, ...passThroughProps }) => {
  const sparkOpts = {
    chart: {
      type: 'line',
      height: 35,
      width: 100,
      sparkline: {
        enabled: true,
      },
    },
    stroke: {
      curve: 'straight',
      colors,
    },
    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      marker: {
        show: false,
      },
    },
    ...options,
  };
  return <Chart options={sparkOpts} series={series} {...passThroughProps} />;
};

export default SparkChart;
