import React, { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';

import GeneralErrorPage from '../GeneralErrorPage';
import ForbiddenError from '../ForbiddenError';
import NotAllowedError from '../NotAllowedError';
import NotFoundPage from '../NotFoundPage';
import TimeOutError from '../TimeOutError';

function AsyncFallBackComponents(props) {
  useEffect(() => {
    if (props.error) {
      datadogRum.addError(props.error, undefined, 'source');
    }
  }, []);

  switch (props.error.code) {
    case 403:
      return <ForbiddenError {...props} />;
    case 404:
      return <NotFoundPage {...props} />;
    case 405:
      return <NotAllowedError {...props} />;
    case 408:
      return <TimeOutError {...props} />;

    default:
      return <GeneralErrorPage {...props} />;
  }
}

export default AsyncFallBackComponents;
