import React from 'react';
import get from 'lodash/get';
import { Dropdown } from 'react-bootstrap';
import { Typography, Link, Icon, Button, ConditionalWrapper } from '@webfx/core-web';

import './EntityCell.css';

const CustomToggle = React.forwardRef(({ children, onClick, setActive }, ref) => (
  <button
    ref={ref}
    onClick={(e) => {
      setActive();
      onClick(e);
    }}
    variant="link"
    className="more-button"
  >
    <Icon>more_horiz</Icon>
    {children}
  </button>
));

const EntityCell =
  ({ idKey = '_id', to, subtitleKey, options, hideViewButton, customAction, setActiveRow }) =>
  ({ cell, row }) => {
    return (
      <div className="d-flex align-items-center entity-cell">
        {options && (
          <Dropdown>
            <Dropdown.Toggle setActive={() => setActiveRow(row.id)} as={CustomToggle} />
            <Dropdown.Menu className="options-dropdown">
              {options.reduce((value, option, index) => {
                const renderedOption = option.renderer(row);

                if (renderedOption === null) {
                  return value;
                }

                return [
                  ...value,
                  <Dropdown.Item
                    as="div"
                    key={index}
                    onClick={option.onClick ? () => option.onClick(row) : undefined}
                  >
                    {renderedOption}
                  </Dropdown.Item>,
                ];
              }, [])}
            </Dropdown.Menu>
          </Dropdown>
        )}
        <ConditionalWrapper
          condition={to}
          wrapper={(children) => (
            <Link to={to(get(row.original, idKey))} className="w-100">
              {children}
            </Link>
          )}
        >
          <div className="d-flex w-100 justify-content-center">
            <div className="flex-grow-1">
              <Typography className="mb-0 mr-1">{cell.value}</Typography>
              {subtitleKey && (
                <Typography className="mb-0" variant="subtitle3">
                  {get(row.original, subtitleKey)}
                </Typography>
              )}
            </div>
            {!hideViewButton && (
              <Button
                className="view-button"
                iconClassName="view-button-icon"
                size="sm"
                variant="outline"
                text="View"
                icon="visibility"
              />
            )}
          </div>
        </ConditionalWrapper>
        {customAction ? customAction(row) : null}
      </div>
    );
  };

export default EntityCell;
