import classnames from 'classnames';

import Icon from '../Icon/Icon';

import styles from './Radio.module.css';

const Radio = ({ id, field: { name, value, onChange, onBlur }, disabled, readOnly, className }) => {
  const checked = id === value;
  return (
    <div className={styles.radioWrap}>
      <div className={styles.radioIconWrap}>
        <Icon
          className={classnames(
            styles.icon,
            {
              [styles.iconDisabled]: disabled || readOnly,
            },
            className
          )}
        >
          {checked ? 'radio_button_checked' : 'radio_button_unchecked'}
        </Icon>
      </div>
      <input
        type="radio"
        className={styles.radio}
        id={id}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        readOnly={readOnly}
        value={id}
        checked={checked}
      />
    </div>
  );
};

export default Radio;
