import React from 'react';
import classnames from 'classnames';
import Checkbox from '../Checkbox/Checkbox';
import styles from './CompanyCheckboxes.module.css';
import _ from 'lodash';

/**
 *
 * @param {object }root0
 * @param {object} root0.user
 * @param {string} root0.className
 * @param {object} root0.company
 * @param {Function} root0.togglePrimaryContact
 * @param {Function} root0.toggleDecisionMaker
 * @param {Function} root0.toggleActive
 * @returns {React.ReactElement}
 */
const CompanyCheckboxes = ({
  togglePrimaryContact,
  toggleDecisionMaker,
  toggleActive,
  user,
  company,
  className,
  unsavedCompanyUsers,
}) => {
  if (!company) {
    return null;
  }
  const companyId = company?.companyId;
  const companyName = company?.name;
  const companyUser = user?.find((companyUserObj) => companyUserObj?.companyId === companyId);
  const companyUserId = companyUser?.companyUserId;

  const isPrimaryContact = companyUser?.primaryContact;
  const isDecisionMaker = companyUser?.decisionMaker;
  const isActive = companyUser?.active;
  const isInteractiveContact = companyUser?.interactiveContact;

  if (unsavedCompanyUsers.includes(companyId)) {
    return (
      <div>
        <p>Click &rsquo;Save Changes&rsquo; to save company and enable company user editing.</p>
      </div>
    );
  }

  return (
    <div className={classnames('d-flex', className)} data-fx-name="company">
      <div className={styles.checkbox}>
        <Checkbox
          id={`user-${companyUserId}-primary-poc`}
          key={`user-${companyUserId}-primary-poc`}
          checked={isPrimaryContact}
          customSwitch
          onChange={togglePrimaryContact('primaryContact', companyId, companyUser, companyName)}
          size="sm"
          labelOnLeft
          name="Primary POC"
          label="Primary Marketing Contact"
          data-fx-name={_.camelCase('Primary Marketing Contact')}
        />
      </div>
      <div className={styles.checkbox}>
        <Checkbox
          id={`user-${companyUserId}-decision-maker`}
          key={`user-${companyUserId}-decision-maker`}
          checked={isDecisionMaker}
          customSwitch
          onChange={toggleDecisionMaker('decisionMaker', companyId, companyUser, companyName)}
          size="sm"
          labelOnLeft
          name="Decision Maker"
          label="Budget Decision Maker"
          data-fx-name={_.camelCase('Budget Decision Maker')}
        />
      </div>
      <div className={styles.checkbox}>
        <Checkbox
          id={`user-${companyUserId}-active`}
          key={`user-${companyUserId}-active`}
          checked={isActive}
          customSwitch
          onChange={toggleActive('active', companyId, companyUser, companyName)}
          size="sm"
          labelOnLeft
          name="Active"
          label="Active/Inactive"
          data-fx-name={_.camelCase('Active/Inactive')}
        />
      </div>
      <div className={styles.checkbox}>
        <Checkbox
          id={`user-${companyUserId}-interactive-contact`}
          key={`user-${companyUserId}-interactive-contact`}
          checked={isInteractiveContact}
          customSwitch
          onChange={toggleActive('interactiveContact', companyId, companyUser, companyName)}
          size="sm"
          labelOnLeft
          name="Interactive Contact"
          label="Interactive Contact"
          data-fx-name={_.camelCase('Interactive Contact')}
        />
      </div>
    </div>
  );
};

export default CompanyCheckboxes;
