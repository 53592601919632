import React from 'react';
import { Dropdown } from 'react-bootstrap';
import classnames from 'classnames';
import Icon from '../Icon/Icon';

const Toggle = React.forwardRef(({ children, className, toggleOnClick, onClick }, ref) => {
  // hack to remove bootstrap dropdown class
  className = className.replace('dropdown-toggle', '');

  const wrappedOnClick = (e) => {
    onClick(e);
    if (toggleOnClick) {
      toggleOnClick(e);
    }
  };

  return (
    <button
      ref={ref}
      onClick={wrappedOnClick}
      className={classnames(['btn btn-sm btn-rounded', className])}
    >
      <Icon>more_horiz</Icon>
      {children}
    </button>
  );
});

function MoreMenu({
  children,
  toggleClassName,
  toggleOnClick,
  menuClassName,
  ...passThroughprops
}) {
  return (
    <Dropdown {...passThroughprops}>
      <Dropdown.Toggle as={Toggle} className={toggleClassName} toggleOnClick={toggleOnClick} />
      <Dropdown.Menu className={classnames(['border-0 shadow-sm py-0', menuClassName])}>
        {children}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default MoreMenu;
