import React, { useState } from 'react';

import Icon from '../Icon/Icon';
import styles from './Select.module.css';

const Select = () => {
  const [show, toggleShow] = useState(false);

  return (
    <div className={styles.select}>
      <div role="presentation" className={styles.block} onClick={() => toggleShow(!show)}>
        <span className={styles.title}>RESPONSE TEMPLATE</span>
        <span className={styles.description}>No Template - Select to clear</span>
        <span className={styles.icon}>
          <Icon>keyboard_arrow_down</Icon>
        </span>
      </div>
      {show && (
        <div className={styles.list}>
          <ul className={styles.ul}>
            <li className={styles.option}>Option1</li>
            <li className={styles.option}>Option2</li>
            <li className={styles.option}>Option3</li>
          </ul>
          <div role="presentation" className={styles.overlay} onClick={() => toggleShow(false)} />
        </div>
      )}
    </div>
  );
};

export default Select;
