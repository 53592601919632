import { thunk, action, computed, actionOn } from 'easy-peasy';
import get from 'lodash/get';
import deepmerge from 'deepmerge';
import feathers from '../../services/feathersClient';

const fetchable = (service, idKey = '_id', serviceIdReplacer) => ({
  data: {},
  ids: [], // this holds the proper order of the data
  items: computed((state) => state.ids.map((id) => state.data[id])),
  service: computed([(state, storeState) => storeState], (storeState) => {
    if (!serviceIdReplacer) {
      return service;
    }
    return service.replace(':id', get(storeState, serviceIdReplacer));
  }),
  params: {},
  isFetchPending: false,
  isFetchOnePending: false,
  isFindPending: false,
  isGetPending: false,
  lastFindAt: null,

  findError: null,
  getError: null,

  setFindError: action((state, payload) => {
    state.findError = payload;
  }),
  setGetError: action((state, payload) => {
    state.getError = payload;
  }),

  empty: action((state, items) => {
    state.data = {};
    state.ids = [];
    state.params = {};
    state.lastFindAt = null;
  }),

  /**
   * Handle Fetching list Models
   */
  fetched: action((state, payload) => {
    const items = payload?.data || payload;
    const data = {};
    const ids = [];
    const fetchedAt = new Date().getTime();
    state.lastFindAt = fetchedAt;

    if (items) {
      items.forEach((item) => {
        item.fetchedAt = fetchedAt;
        data[item[idKey]] = {
          ...(state.data[item[idKey]] || {}),
          ...item,
        };
        ids.push(item[idKey]);
      });
    }

    state.ids = ids;
    state.data = data;
  }),

  fetch: thunk(async (actions, payload = {}, { getState }) => {
    await actions.find(payload);
  }),

  find: thunk(async (actions, payload = {}, { getState }) => {
    actions.setFindError(null);

    let { query = {}, throwError = false, ...params } = payload;
    const { filters = {}, params: stateParams, service, skip, limit } = getState();

    // get any filters available and override with any passed by call
    if (filters) {
      query = {
        ...filters,
        ...query,
      };
    }

    if (skip !== undefined && query.$skip === undefined) {
      query.$skip = skip;
    }

    if (limit !== undefined && query.$limit === undefined) {
      query.$limit = limit;
    }

    // get any params available and override with any passed by call
    params = {
      ...stateParams,
      ...params,
    };
    try {
      const data = await feathers()
        .service(service)
        .find({
          query,
          ...params,
        });

      actions.fetched(data);
    } catch (err) {
      actions.setFindError(err);
      if (throwError) {
        throw err;
      }
    }
  }),

  /**
   * Handle Fetching Single Models
   */
  fetchedOne: action((state, payload) => {
    const { item, mergeStrategy = 'deep' } = payload;

    item.fetchedAt = new Date().getTime();
    if (!state.data[item[idKey]]) {
      state.ids.push(item[idKey]);
      state.data[item[idKey]] = item;
      return;
    }

    switch (mergeStrategy) {
      case 'shallow':
        state.data[item[idKey]] = {
          ...state.data[item[idKey]],
          ...item,
        };
        break;
      case 'replace':
        state.data[item[idKey]] = item;
        break;
      default:
        state.data[item[idKey]] = deepmerge(state.data[item[idKey]], item); // default to deepmerge
    }
  }),

  // TODO: fully deprecate fetchOne
  fetchOne: thunk(async (actions, payload, { getState }) => {
    await actions.get(payload);
  }),

  get: thunk(async (actions, payload, { getState }) => {
    actions.setGetError(null);

    const state = getState();

    let data,
      id,
      params = { ...state.params },
      mergeStrategy,
      throwError = false;

    if (typeof payload === 'string') {
      id = payload;
    } else {
      id = payload[idKey] || payload.id;
      params = payload;

      if (params.mergeStrategy) {
        mergeStrategy = params.mergeStrategy;
      }

      if (params.throwError) {
        throwError = params.throwError;
      }

      delete params[idKey];
      delete params.id;
      delete params.mergeStrategy;
      delete params.throwError;
    }
    try {
      data = await feathers().service(service).get(id, params);
      actions.fetchedOne({ item: data, mergeStrategy });
    } catch (err) {
      const { code } = err;
      if (code === 404 && service === 'tw/todos') {
        data = await feathers()
          .service(service)
          .get(id, { ...params, query: { ...params.query, $paranoid: false } });
        actions.fetchedOne({ item: data, mergeStrategy });
        return;
      }
      actions.setGetError(err);
      if (throwError) {
        throw err;
      }
    }
  }),

  /**
   * Handle Paramater Setting
   */
  setParam: action((state, payload) => {
    if (Array.isArray(payload)) {
      payload.forEach((f) => {
        state.params[f.key] = f.value;
      });
    } else {
      Object.keys(payload).forEach((key) => {
        state.params[key] = payload[key];
      });
    }
  }),

  clearParam: action((state, payload) => {
    if (Array.isArray(payload)) {
      payload.forEach((key) => {
        delete state.params[key];
      });
    } else {
      delete state.params[payload];
    }
  }),

  resetParams: action((state, payload) => {
    state.params = {};
  }),

  /**
   * Set Fetching Statuses
   */
  setIsFindPending: action((state, payload) => {
    state.isFetchPending = payload;
    state.isFindPending = payload;
  }),

  setIsGetPending: action((state, payload) => {
    state.isFetchOnePending = payload;
    state.isGetPending = payload;
  }),

  // automate fetch pending
  setIsFindPendingOnFetch: actionOn(
    (actions) => actions.find,
    (state, target) => {
      if (-1 !== target.type.indexOf('start')) {
        state.isFetchPending = true;
        state.isFindPending = true;
      } else {
        state.isFetchPending = false;
        state.isFindPending = false;
      }
    }
  ),

  // automate fetch pending
  setIsGetPendingOnGet: actionOn(
    (actions) => actions.get,
    (state, target) => {
      if (-1 !== target.type.indexOf('start')) {
        state.isGetPending = true;
        state.isFetchOnePending = true;
      } else {
        state.isGetPending = false;
        state.isFetchOnePending = false;
      }
    }
  ),
});

export default fetchable;
