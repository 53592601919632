import { Button } from '@webfx/core-web';
import classnames from 'classnames';
import React from 'react';
import styles from './Selector.module.css';

export default function Selector({
  text,
  icon,
  selected = false,
  onToggle = () => null,
  className = '',
  ...passThroughProps
}) {
  const [isSelected, setIsSelected] = React.useState(selected);
  const clickAction = () => {
    setIsSelected(!isSelected);
    onToggle(!isSelected);
  };

  return (
    <Button
      variant={isSelected ? 'primary' : 'secondary'}
      icon={icon}
      className={classnames(
        isSelected ? styles.selected : styles.default,
        'shadow-none ',
        className
      )}
      onClick={clickAction}
      iconOutlined
      outline
      text={text}
      {...passThroughProps}
    />
  );
}
