import classnames from 'classnames';
import { If, Then } from 'react-if';

import Icon from '../../Icon/Icon';
import InputLabel from '../InputLabel/InputLabel';
import InputLabelInfo from '../InputLabelInfo/InputLabelInfo';
import InputErrorText from '../InputErrorText/InputErrorText';
import ConditionalWrapper from '../../ConditionalWrapper/ConditionalWrapper';

const InputGroup = ({
  className,
  label,
  labelInfo,
  labelInfoPosition = 'top',
  labelInfoClassName,
  labelPosition = 'top',
  labelClassName,
  value,
  inputGroupTextLeft = null,
  inputGroupTextRight = null,

  error,
  errorVisible = true,
  touched = false,
  focused = false,

  icon,
  iconPosition = 'left',
  iconOutined = false,
  iconTooltip,
  onIconClick,
  iconActive,
  children,
  clearable = false,
  onClear,
  variant,

  ...passThroughProps
}) => {
  const isActive = focused || (value && value.length > 0);
  const embedLabel = labelPosition !== 'top' && label;

  return (
    <div
      className={classnames([
        'form-group',
        'position-relative',
        {
          [`form-group-${variant}`]: variant,
          'form-group-embedded': embedLabel,
          'is-invalid': !!error && touched,
          focused: isActive,
          [`has-icon-${iconPosition}`]: icon && iconPosition,
        },
        className,
      ])}
      {...passThroughProps}
    >
      <If condition={!!label}>
        <Then>
          <InputLabel
            text={label}
            htmlFor={name}
            className={classnames('form-label', labelClassName)}
            embedLabel={embedLabel}
          />
        </Then>
      </If>
      <If condition={!!labelInfo && !embedLabel && labelInfoPosition === 'top'}>
        <Then>
          <InputLabelInfo text={labelInfo} className={labelInfoClassName} />
        </Then>
      </If>
      <div className="input-wrapper position-relative">
        <div className="input-group">
          <If condition={!!inputGroupTextLeft}>
            <Then>
              <div className="input-group-prepend">
                <div className="input-group-text">{inputGroupTextLeft}</div>
              </div>
            </Then>
          </If>
          {children}
          <If condition={!!inputGroupTextRight}>
            <Then>
              <div className="input-group-prepend">
                <div className="input-group-text">{inputGroupTextRight}</div>
              </div>
            </Then>
          </If>
        </div>
        <If condition={!!labelInfo && labelInfoPosition === 'bottom'}>
          <Then>
            <InputLabelInfo text={labelInfo} className={classnames([labelInfoClassName, 'mb-0'])} />
          </Then>
        </If>

        <If condition={!!icon}>
          <Then>
            <ConditionalWrapper
              condition={onIconClick && true}
              wrapper={(children) => (
                <button
                  type="button"
                  className={classnames([
                    'input-icon input-icon-btn',
                    {
                      [`input-icon-${iconPosition}`]: true,
                      'input-icon-active': iconActive,
                      'input-icon-error': error && touched,
                    },
                  ])}
                  onClick={onIconClick}
                >
                  {children}
                </button>
              )}
            >
              <Icon
                className={classnames({
                  'input-icon': !onIconClick,
                  'input-icon-error': error && touched,

                  [`input-icon-${iconPosition}`]: !onIconClick,
                  'input-icon-active': iconActive,
                })}
                outlined={iconOutined}
              >
                {icon}
              </Icon>
            </ConditionalWrapper>
          </Then>
        </If>

        {clearable && value ? (
          <button className="input-icon input-icon-clear input-icon-btn" onClick={onClear}>
            <Icon>closed</Icon>
          </button>
        ) : null}
      </div>
      {error && touched && errorVisible && <InputErrorText text={error} />}
    </div>
  );
};

export default InputGroup;
