import LazyLoad from 'react-lazy-load';
import classnames from 'classnames';

import Typography from '../../Typography/Typography';
import styles from './CardAvatar.module.css';
const avatar = '/assets/images/avatar.png';

// TODO: deprecate. We dont need a seperate avatar field when we already have one
//       if need special layout privde through that molecule component

const CardAvatar = ({ imageUrl, className, imageText, size }) => {
  const dimension = size === 'sm' ? 32 : 48;
  return (
    <div className={classnames(styles.cardAvatar, className)}>
      <span className={classnames(styles.imageWrap, { [styles.smallImageWrap]: size === 'sm' })}>
        {imageText ? (
          <Typography className={styles.number}>
            <strong>{`+${imageText}`}</strong>
          </Typography>
        ) : (
          <LazyLoad width={dimension} height={dimension}>
            <img
              className={classnames(styles.image, { [styles.smallImage]: size === 'sm' })}
              src={imageUrl || avatar}
              alt="user avatar"
              loading="lazy"
            />
          </LazyLoad>
        )}
      </span>
    </div>
  );
};

export default CardAvatar;
