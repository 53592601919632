import React, { useRef } from 'react';
import { Card as BootstrapCard } from 'react-bootstrap';

const Card = React.forwardRef(({ children, className, ...passThroughProps }, ref) => {
  const fallbackRef = useRef();
  const normalizedRef = ref ?? fallbackRef;

  return (
    <BootstrapCard
      ref={normalizedRef}
      className={className}
      data-fx-name={passThroughProps['data-fx-name']}
      {...passThroughProps}
    >
      {children}
    </BootstrapCard>
  );
});

// Card = Object.assign(Card, BootstrapCard);
Card.Title = BootstrapCard.Title;
Card.Subtitle = BootstrapCard.Subtitle;
Card.Text = BootstrapCard.Text;
Card.Link = BootstrapCard.Link;
Card.Header = BootstrapCard.Header;
Card.Body = BootstrapCard.Body;
Card.ImgOverlay = BootstrapCard.ImgOverlay;
Card.Footer = BootstrapCard.Footer;

export default Card;
