import React from 'react';
import classNames from 'classnames';

const FeaturedIcon = ({
  icon = 'star',
  featuredColor = 'yellow',
  isFeatured,
  className,
  ...props
}) => {
  return (
    <i
      className={classNames({
        [className]: true,
        'material-icons': true,
        ['text-gray']: !isFeatured,
        [`text-${featuredColor}`]: isFeatured,
      })}
      {...props}
    >
      {icon}
    </i>
  );
};

export default FeaturedIcon;
