import classnames from 'classnames';
import { Field, FieldArray } from 'formik';

import Typography from '../Typography/Typography';
import List from '../List/List';
import CheckboxGroup from '../CheckboxGroup/CheckboxGroup';

import styles from './InputCheckboxes.module.css';

const InputCheckboxes = ({ label, checkboxes = [], bottomGutter, className, listClassName }) => {
  return (
    <div className={classnames({ [styles.bottomGutter]: bottomGutter }, className)}>
      <Typography>{label}</Typography>
      <FieldArray
        name="options"
        render={() => (
          <List className={listClassName}>
            {checkboxes.map(({ name, label: checkboxLabel, extraInfo }) => (
              <li key={name} className={styles.item}>
                <Field
                  component={CheckboxGroup}
                  name={name}
                  id={name}
                  label={checkboxLabel}
                  extraInfo={extraInfo}
                  bold
                />
              </li>
            ))}
          </List>
        )}
      />
    </div>
  );
};

export default InputCheckboxes;
