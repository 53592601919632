import { actionOn } from 'easy-peasy';

const pendingListeners = (actionName, propName, listenerName) => ({
  [propName]: false,

  [listenerName]: actionOn(
    (actions) => [
      actions[actionName].startType,
      actions[actionName].successType,
      actions[actionName].failType,
    ],
    (state, target) => {
      const [startType, successType, failType] = target.resolvedTargets;

      switch (target.type) {
        case startType:
          state[propName] = true;
          break;

        case successType:
          state[propName] = false;
          break;

        case failType:
          state[propName] = false;
          break;
      }
    }
  ),
});

export default pendingListeners;
