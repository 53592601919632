import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Icon.style.css';
import styles from './Icon.module.css';

// forked from https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Icon/Icon.js
const Icon = ({
  className,
  component: Component = 'i',
  outlined = false,
  children,
  rounded = false,
  size = 'small',
  type,
  //Mute props to prevent errors from the google maps api
  $prerender,
  $onMouseAllow,
  $geoService,
  $dimensionKey,
  $getDimensions,
  $hover,
  ...passThroughProps
}) => (
  <Component
    className={classNames(className, {
      'material-icons': true, // always true for easy class targeting
      'material-icons-outlined': outlined,
      roundedIcon: rounded,
      [styles.medium]: size === 'medium',
      [styles.extraSmall]: size === 'extraSmall',
    })}
    aria-hidden
    {...passThroughProps}
  >
    {type ?? children}
  </Component>
);

Icon.defaultProps = {
  className: null,
  component: 'span',
};

Icon.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  component: PropTypes.elementType,
};

export default Icon;
