import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';

import Icon from '../Icon/Icon';

import styles from './ExpandableInput.module.css';

const ExpandableInput = ({ icon, text, id, value, name, buttonProps, ...props }) => {
  const inputRef = useRef(null);
  const [isFocus, setIsFocus] = useState(null);

  useEffect(() => {
    if (isFocus) {
      inputRef.current.focus();
    }
  }, [isFocus]);

  if (!isFocus) {
    return (
      <button
        onClick={() => {
          setIsFocus(true);
        }}
        className={classNames(styles.btn, 'btn btn-sm')}
        {...buttonProps}
      >
        <Icon>{icon}</Icon>
        <span className={classNames(styles.label, 'text-uppercase')}>{text}</span>
      </button>
    );
  }

  return (
    <div className="input-group mr-sm-2">
      <div className="input-group-prepend">
        <div className={classNames(styles.icon, 'input-group-text bg-transparent')}>
          <Icon>search</Icon>
        </div>
      </div>
      <input
        ref={inputRef}
        onBlur={() => {
          if (!inputRef.current.value) {
            setIsFocus(false);
          }
        }}
        type="text"
        id={id || name}
        name={name}
        className={classNames(styles.control)}
        {...props}
      />
    </div>
  );
};

export default ExpandableInput;
