import Paginate from 'react-paginate';

import Icon from '../Icon/Icon';

import './Pagination.style.css';

const Pagination = ({
  className = '',
  onPageChange,
  initialPage,
  forcePage,
  pageCount,
  navigationText = false,
  withBorder = false,
  pageRangeDisplayed = 3,
  marginPagesDisplayed = 1,
}) => {
  return (
    <Paginate
      containerClassName={`Pagination ${className} ${withBorder && 'Pagination--border'}`}
      disabledClassName="Pagination__item--no-display"
      pageClassName="Pagination__item"
      nextClassName="Pagination__item"
      previousClassName="Pagination__item"
      breakClassName="Pagination__item Pagination__item--break"
      activeClassName="Pagination__item--active"
      pageLinkClassName="Pagination__item__archor"
      breakLabel="..."
      pageRangeDisplayed={pageRangeDisplayed}
      marginPagesDisplayed={marginPagesDisplayed}
      onPageChange={onPageChange}
      initialPage={initialPage}
      forcePage={forcePage}
      nextLabel={navigationText || withBorder ? 'Next' : <Icon>chevron_right</Icon>}
      previousLabel={navigationText || withBorder ? 'Previous' : <Icon>chevron_left</Icon>}
      pageCount={pageCount}
    />
  );
};

export default Pagination;
