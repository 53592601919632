import React from 'react';
import { Spinner } from 'react-bootstrap';

import Logo from '../Logo/Logo';
import Typography from '../Typography/Typography';
import styles from './Preloader.module.css';

export default function Preloader() {
  return (
    <div className={styles.wrap}>
      <Logo className={styles.logo} />
      <Typography component="div" variant="h3" className={styles.text}>
        <Spinner
          animation="border"
          role="status"
          size="sm"
          className={styles.spinner}
          data-fx-name="loadingSpinner"
        />
        Loading&hellip;
      </Typography>
    </div>
  );
}
