/**
 * Helper function to be used when doing 'infinite' queries.
 * It adds a 'data' key containing the items of all available pages.
 *
 * Usage:
 *
 * useInfiniteQuery('service', {
 *   select: (data) => api.helpers.flatInfiniteQueryResult(data),
 * })
 */
export function flatInfiniteQueryResult(result = {}) {
  // If data.pages doesn't exists we return
  if (!result?.pages) {
    return result;
  }

  const lastPage = result.pages[result.pages.length - 1];
  let { total, skip, limit } = lastPage;

  // Support $limit: -1 queries
  if (Array.isArray(lastPage)) {
    total = lastPage.length;
    skip = 0;
    limit = -1;
  }

  const data = result.pages.reduce((carry, page) => {
    // Support $limit: -1 queries
    if (Array.isArray(page)) {
      return carry.concat(page);
    }

    return carry.concat(page.data);
  }, []);

  return {
    ...result,
    total,
    skip,
    limit,
    hasMore: data ? data.length < total : true,
    data,
  };
}
