const recusrsiveCropText = (str = '', sliceFromIdx) => {
  if (str.length <= 13) {
    return [str, sliceFromIdx];
  }

  const incremented = sliceFromIdx + 1;
  return recusrsiveCropText(str.split(' ').slice(0, -1).join(' '), incremented);
};

const cropText = (str = '', len = 13) => {
  const isMultiplyLines = str.length > len;
  if (!isMultiplyLines) {
    return [isMultiplyLines];
  }

  const firstPart = str.slice(0, len);
  const secondPart = str.slice(len);

  // const [firstPart, sliceFromIndex] = recusrsiveCropText(str, 0);
  // const secondPart = str
  //   .split(' ')
  //   .slice(-sliceFromIndex)
  //   .join(' ');

  return { isMultiplyLines, firstPart, secondPart };
};

export default cropText;
