import { Link } from 'react-router-dom';
import { useRouter } from '@webfx/web-hooks';
import './ForbiddenError.styles.css';

const ForbiddenError = () => {
  const { url } = useRouter();

  return (
    <div>
      <h2>You need permission to access this item</h2>
      <p>
        You do not have the permissions to access this item.
        <Link className="btn btn-primary" to={url({ route: 'dashboard' })}>
          Dashboard
        </Link>
      </p>
    </div>
  );
};

export default ForbiddenError;
