import { action, thunk, computed } from 'easy-peasy';
import isEmpty from 'lodash/isEmpty';
import feathers from '../services/feathersClient';

const imports = {
  imports: computed((state) => Object.values(state.files)),
  files: {},
  pollers: {},

  setFile: action((state, payload) => {
    state.files[payload.id] = payload;
  }),

  setPoller: action((state, payload) => {
    state.pollers[payload.id] = payload.poller;
  }),

  deleteFile: action((state, jobId) => {
    if (!isEmpty(state.files) && state.files[jobId]) {
      delete state.files[jobId];
    }
  }),

  deletePoller: action((state, jobId) => {
    if (!isEmpty(state.pollers) && state.pollers[jobId]) {
      clearInterval(state.pollers[jobId]);
      delete state.pollers[jobId];
    }
  }),

  pollImport: thunk(async (actions, payload) => {
    const parts = payload.filename?.split('.') ?? [];
    const fileInfo = { ...payload, extension: parts[parts.length - 1] ?? null, progress: 0 };

    actions.setFile(fileInfo);
    actions.setPoller({
      poller: setInterval(async () => {
        try {
          const importJob = await feathers().service('identity/import-v2').get(payload.id);
          actions.setFile({ ...fileInfo, ...importJob });
          if (importJob.progress !== 100) {
            return;
          }
          actions.deletePoller(payload.id);
        } catch (e) {
          return;
        }
      }, 3000),
      id: payload.id,
    });
  }),
};

export default imports;
