import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useAuth } from '@webfx/web-hooks';

function openUploadURLInNewTap(url) {
  const win = window.open(url, '_blank');
  if (win != null) {
    win.focus();
  }
  return '/dashboard';
}

/**
 * Render public route
 * Only unlogged users can access to public route
 * Otherwise - redirect user to another allowed page route
 */
const PublicRoute = ({ redirectOnAuthorized, passQueryString, ...props }) => {
  if (passQueryString) {
    redirectOnAuthorized = redirectOnAuthorized + props.location.search;
  }
  const authorized = useAuth().user;

  // adding uploads check because page needs to reload to be able to serve uploads page.
  // uploads route is being handled/served by the api.
  if (authorized && redirectOnAuthorized?.includes('/uploads')) {
    //setting dashboard as redirectOnAuthorized because redirect to uploads returns 404 if page doesn't reload
    redirectOnAuthorized = openUploadURLInNewTap(redirectOnAuthorized);
  }

  return authorized && redirectOnAuthorized ? (
    <Redirect to={redirectOnAuthorized} />
  ) : (
    <Route {...props} />
  );
};

export default PublicRoute;
