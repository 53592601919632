import { string, ref } from 'yup';

export {
  createPassword,
  editPassword,
  editPasswordConfirmation,
  confirmPassword,
  password,
} from '../config/formik-validators';

const phoneRegexp = /^[0-9( )-]+$/;
const onlyNumberRegexp = /^[0-9]*$/;
const onlyLettersAndSymbols = /^[ A-Za-z!$%^*()_@./'#&=+-]*$/;

export const errors = {
  required: 'Field is required',
  user: {
    firstName: 'First name must be no longer than 30 characters',
    lastName: 'Last name must be no longer than 50 characters',
    title: 'Title must be no longer than 100 characters',
    office: 'Office number must be no longer than 20 characters',
    ext: 'Extension must be no longer than 10 characters',
  },
  name: 'Title must be no longer than 100 characters',
  address1: 'Address Line must be no longer than 255 characters',
  city: 'City must be no longer than 50 characters',
  state: 'State must be no longer than 50 characters',
  zip: 'ZIP/Postal Code must be no longer than 20 characters',
  webAddress: 'Web address must be no longer than 150 characters',
  phone: 'Phone number must be no longer than 20 characters',
  email: {
    incorrect: 'Email address must be valid (format: john@doe.com)',
    length: 'Email Address must be no longer than 100 characters',
  },
  onlyLettersAndSymbols: 'Can contain only letters and special symbols',
  onlyNumbersAndSymbols: 'Can contain only numbers, spaces and symbols (, ), -',
  onlyNumbers: 'Can contain only numbers',
  file: {
    name: 'You must specify a name for the file.',
  },
  template: {
    title: 'Project template must be no longer than 100 characters',
    required: 'This field cannot be empty',
  },
  projectTemplateTodoList: {
    title: 'List name must be no longer than 100 characters',
    required: 'Name cannot be empty',
  },
};

export const email = string()
  .email(errors.email.incorrect)
  .required(errors.required)
  .max(100, errors.email.length);

export const newEmail = string().email(errors.email.incorrect).max(100, errors.email.length);

export const required = string().trim().required(errors.required);

export const firstName = string()
  .required(errors.required)
  .max(30, errors.user.firstName)
  .matches(onlyLettersAndSymbols, errors.onlyLettersAndSymbols);

export const lastName = string()
  .required(errors.required)
  .max(50, errors.user.lastName)
  .matches(onlyLettersAndSymbols, errors.onlyLettersAndSymbols);

export const title = string().max(100, errors.user.title);

export const ext = string().max(10, errors.user.ext).matches(onlyNumberRegexp, errors.onlyNumbers);

export const phonenumber = string()
  .max(20, errors.user.homeNumber)
  .matches(phoneRegexp, errors.onlyNumbersAndSymbols);

export const name = string().max(100, errors.name).required(errors.required);

export const address = string().max(255, errors.address);

export const city = string()
  .max(50, errors.city)
  .matches(onlyLettersAndSymbols, errors.onlyLettersAndSymbols);

export const state = string()
  .max(50, errors.state)
  .matches(onlyLettersAndSymbols, errors.onlyLettersAndSymbols);

export const zip = string().max(20, errors.zip);
export const webAddress = string().max(150, errors.webAddress);
export const fileName = string().required(errors.file.name);
