import { QueryClient } from 'react-query';
import { defaultQueryFn } from './defaultQueryFn';

export { useQueryClient } from 'react-query';
export { defaultQueryFn } from './defaultQueryFn';
export { useQuery } from './useQuery';
export { useQueries } from './useQueries';
export { useInfiniteQuery } from './useInfiniteQuery';
export { useMutation } from './useMutation';
export { useQueryKey } from './useQueryKey';
export { useQueryCache } from './useQueryCache';
export { normalizeQueryData } from './utils';

export { dataOrDefaults } from './helpers/dataOrDefaults';
export { dataAsOptions } from './helpers/dataAsOptions';
export { invalidateKey } from './helpers/invalidateKey';
export { updateKeyWithResult } from './helpers/updateKeyWithResult';
export { flatInfiniteQueryResult } from './helpers/flatInfiniteQueryResult';
export { updateQueryCacheData } from './helpers/updateQueryCacheData';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // millis
      queryFn: defaultQueryFn,
    },
  },
});
