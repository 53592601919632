import React from 'react';
import classnames from 'classnames';

import Button from '../Button';
import styles from './AddRemove.module.css';

function AddRemove({ onAdd, onRemove, hideAdd = false, hideRemove = false }) {
  return (
    <div className={classnames([styles.container])}>
      {(hideAdd === false || !onAdd) && (
        <Button
          icon="add"
          onClick={onAdd}
          className={classnames([styles.add])}
          variant="primary-light"
        />
      )}
      {(hideRemove === false || !onRemove) && (
        <Button
          icon="remove"
          onClick={onRemove}
          className={classnames([styles.remove])}
          variant="danger-light"
        />
      )}
    </div>
  );
}

export default AddRemove;
