import React from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import Button from '../Button';

import styles from './ActionBar.module.css';

function ActionBar({ className, children, ...passThroughProps }) {
  const history = useHistory();

  return (
    <div
      className={classnames([
        className,
        styles.actionBar,
        'd-flex justify-content-between align-items-center mb-4',
      ])}
      {...passThroughProps}
    >
      <Button
        icon="navigate_before"
        text="Back / Cancel"
        onClick={history.goBack}
        className={styles.backBtn}
        size="sm"
      />
      {children}
    </div>
  );
}

export default ActionBar;
