import styles from './DefaultAvatar.module.css';

const getAvatarUrl = (avatarUrl) => (avatarUrl && avatarUrl.length ? `${avatarUrl}` : '');

// TODO: implement logic to avoid rerender
const Avatar = ({ avatarUrl = '' }) => {
  const logoUrl = getAvatarUrl(avatarUrl);
  return logoUrl ? (
    <div className={styles.avatar} style={{ backgroundImage: `url(${logoUrl})` }} />
  ) : (
    'Upload Picture'
  );
};

export default Avatar;
