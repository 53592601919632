import React from 'react';
import TagList from './TagList';

const TagListFormik = ({ field, form, suggestions, ...props }) => {
  const handleAddition = (item) => {
    const value = field.value || [];

    const map = new Map(
      value.map((i) => {
        return [i.value, i];
      })
    );

    map.set(item.value, item);
    form.setFieldValue(field.name, Array.from(map.values()));
  };

  const handleDelete = (index) => {
    const map = new Map(
      field.value.map((i) => {
        return [i.value, i];
      })
    );

    map.delete(field.value[index].value);

    const newValue = Array.from(map.values());

    // Setting fieldValue to [] will delete the field from the form
    form.setFieldValue(field.name, newValue.length ? newValue : null);
  };

  return (
    <TagList
      tags={field.value || []}
      suggestions={suggestions}
      handleAddition={handleAddition}
      handleDelete={handleDelete}
    />
  );
};

export default TagListFormik;
