import intersection from 'lodash/intersection';
import isPlainObject from 'lodash/isPlainObject';
import cloneDeep from 'lodash/cloneDeep';

import { typeToMethod } from './constants';

/**
 * Extract the mutation type from the key to return a non method key
 * @param {*} key
 */
export const extractTypeFromKey = (key) => {
  let clonedKey = cloneDeep(key);

  if (!Array.isArray(clonedKey)) {
    clonedKey = [clonedKey];
  }
  // clonedKey = clonedKey.map((i) => (typeof i === 'string' ? i.toLowerCase() : i));
  const methodKeys = Object.keys(typeToMethod);
  const typeExtractedKey = clonedKey.filter((tk) => {
    if (typeof tk !== 'string') {
      return true;
    }
    return !methodKeys.includes(tk.toLocaleLowerCase());
  });
  let mutationType = intersection(clonedKey, Object.keys(typeToMethod))[0] || null;

  switch (typeExtractedKey.length) {
    // if there is 2 or 3 keys need to verify mutationType or set default 'update'
    case 3:
    case 2:
      mutationType = mutationType ? mutationType : 'update';

    // 2 items means index 2 can be either an update id or params for feathers
    case 2:
      // index 2 is object so it is params not an update id
      if (isPlainObject(typeExtractedKey[1])) {
        mutationType = mutationType ? mutationType : 'create';
      }
      break;

    // 1 item means it's just service and we need to modify mutationType default to create
    case 1:
      mutationType = mutationType ? mutationType : 'create';
      break;
  }

  return [typeExtractedKey, typeToMethod[mutationType]];
};

/**
 * Helper function to resolve reactQueryOpts, primaryKey and keyToUpdates
 *
 * @param args
 * @returns {{keysToUpdate: null, reactQueryOpts: {}, primaryKey: null}}
 */
export const parseMutationArgs = (args) => {
  let reactQueryOpts = {};
  let keysToUpdate = null;
  let primaryKey = null;

  if (isPlainObject(args[0])) {
    reactQueryOpts = args[0];

    return {
      reactQueryOpts,
      keysToUpdate,
      primaryKey,
    };
  }

  primaryKey = args[0];
  keysToUpdate = args[1];
  reactQueryOpts = args[2] || {};

  if (Array.isArray(primaryKey)) {
    reactQueryOpts = keysToUpdate || {};
    keysToUpdate = primaryKey;
    primaryKey = null;
  }

  if (isPlainObject(keysToUpdate)) {
    reactQueryOpts = keysToUpdate;
    keysToUpdate = null;
  }

  return {
    reactQueryOpts,
    keysToUpdate,
    primaryKey,
  };
};

/**
 * Helper function to resolve service, method, id.
 *
 * @param key
 * @returns {{method: *, service: *, id: (*|null), params: *}}
 */
export const getKeyParts = (key) => {
  const [typeExtractedKey, mutationType] = extractTypeFromKey(key);
  const paramsAbstractedKey = typeExtractedKey.filter((i) => !isPlainObject(i));
  const params = typeExtractedKey.filter((i) => isPlainObject(i))[0] || {};

  return {
    service: paramsAbstractedKey[0],
    method: mutationType,
    id: paramsAbstractedKey[1] || null,
    params,
  };
};

/**
 * Helper function to format the correct query keys when using useQuery and useQueries
 * @param queryKey
 * @returns {*} {(*|string)[]|*[]|*}
 */
export const resolveQueryKey = (queryKey) => {
  if (!Array.isArray(queryKey)) {
    return [queryKey];
  }

  // handles ['service', params]
  if (isPlainObject(queryKey[1])) {
    return [queryKey[0], 'find', queryKey[1]];
  }

  return queryKey;
};

/**
 * Helper function to normalize the 'data' key returned from useQuery
 * @param queryResponse
 * @returns {{data: array, total: integer, limit: integer, skip: integer}}
 */
export const normalizeQueryData = (queryResponse) => {
  return {
    data: queryResponse?.data?.data ?? [],
    total: queryResponse?.data?.total ?? 0,
    limit: queryResponse?.data?.limit ?? 20,
    skip: queryResponse?.data?.skip ?? 0,
  };
};
