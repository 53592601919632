import React from 'react';

import AsyncErrorBoundary from './AsyncErrorBoundary';

const withAsyncErrorBoundary = (Component, boundaryProps) => (props) => {
  return (
    <AsyncErrorBoundary {...boundaryProps}>
      <Component {...props} />
    </AsyncErrorBoundary>
  );
};

export default withAsyncErrorBoundary;
